import React, { useState, useContext } from "react";
import { BiMenu } from "react-icons/bi";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MenuUser } from "./MenuUser";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { AuthContext } from "../../AuthProvider";
import { getUserNotifications } from "../../../Api/api";
import DownloadAppPopup from "../../DownloadAppPopup";

export interface IUserNotification {
    _id: string;
    userId: string;
    email: string;
    title: string;
    imageUrl: string;
    body: string;
    payload: IUserNotificationPayload;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

export interface IUserNotificationPayload {
    type: string;
    id: string;
    email: string;
}

export interface IMenuUserProps {
    userNotifications: IUserNotification[];
    onNotificationIconClick: () => void;
    onNotificationItemClick: (notification: IUserNotification) => void;
    onSeeAllNotificationsInInbox: () => void;
}

export const Navbar: React.FC<{
    isLoggedIn: boolean;
    displayName: string;
}> = ({ isLoggedIn, displayName }) => {
    const [menuDivActive, setMenuDivActive] = useState(false);
    const { t, i18n } = useTranslation();
    const [userNotifications, setUserNotifications] = useState<
        IUserNotification[]
    >([]);

    const [isUserNotificationsLoading, setUserNotificationsLoading] =
        useState(false);

    const [isOpenAppDownloadModal, setIsOpenAppDownloadModal] = useState(false);

    function closeAppDownloadModal() {
        setIsOpenAppDownloadModal(false);
    }

    const currentUser = useContext(AuthContext);

    const toggleMenuClass = () => {
        setMenuDivActive(!menuDivActive);
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const location = useLocation();
    const currentPath = location.pathname;

    const handleOnNotificationItemClick = () => {
        setIsOpenAppDownloadModal(true);
    };

    const handleOnSeeAllNotificationsInInboxClick = () => {
        // console.log("see all in inbox");
    };

    const getNotifications = () => {
        if (currentUser?.email) {
            setUserNotificationsLoading(true);
            getUserNotifications(currentUser.email).then((data) => {
                setUserNotifications(data?.notifications || []);
                setUserNotificationsLoading(false);
            });
        }
    };

    return (
        <>
            <header className="fixed w-full top-0 z-40 bg-secondary">
                <div className=" relative flex lg:px-16 px-4 justify-between items-center py-5 ">
                    <div className="lg:flex lg:items-center gap-14">
                        <NavLink to="/">
                            <img
                                src="/padelmates.svg"
                                alt="Padelmates logo"
                                className="h-10 w-20 hover:scale-110 transition duration-200"
                            />
                        </NavLink>
                        <div className="hidden lg:flex lg:items-center gap-8 lg:text-white text-[17px]">
                            <NavLink to="/">
                                <p
                                    className={
                                        currentPath === "/"
                                            ? "text-primary underline-none"
                                            : "text-white underline-none hover:scale-110 transition duration-200"
                                    }
                                >
                                    {t("Home")}
                                </p>
                            </NavLink>
                            <NavLink to="/booking">
                                <p
                                    className={
                                        currentPath === "/booking"
                                            ? "text-primary underline-none"
                                            : "text-white underline-none hover:scale-110 transition duration-200"
                                    }
                                >
                                    {t("Booking")}
                                </p>
                            </NavLink>
                            <NavLink to="/find-centres">
                                <p
                                    className={
                                        currentPath === "/find-centres"
                                            ? "text-primary underline-none"
                                            : "text-white underline-none hover:scale-110 transition duration-200"
                                    }
                                >
                                    {t("Find centres")}
                                </p>
                            </NavLink>
                            <NavLink to="/activities">
                                <p
                                    className={
                                        currentPath === "/activities"
                                            ? "text-primary underline-none"
                                            : "text-white underline-none hover:scale-110 transition duration-200"
                                    }
                                >
                                    {t("Activities.Activities")}
                                </p>
                            </NavLink>
                            <NavLink
                                to="/club"
                                className={`border px-3 rounded-3xl py-1 ${
                                    currentPath === "/club"
                                        ? "border-primary"
                                        : "border-white"
                                }`}
                            >
                                <p
                                    className={
                                        currentPath === "/club"
                                            ? "text-primary underline-none"
                                            : "text-white underline-none hover:scale-110 transition duration-200"
                                    }
                                >
                                    {t("BookADemo")}
                                </p>
                            </NavLink>
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="flex gap-1 lg:gap-4 items-center">
                            <div className="flex gap-0 lg:gap-2 items-center text-white">
                                <form
                                    action="#"
                                    className="items-center gap-1 lg:flex flex text-sm lg:text-base mr-2 hover:scale-110 transition duration-200"
                                >
                                    <div className="w-4 h-3 lg:w-6 lg:h-4 rounded-[50px] overflow-hidden">
                                        {i18n.language.split("-")[0] ===
                                        "en" ? (
                                            <img
                                                src="/usa-flag.png"
                                                alt="usa"
                                            />
                                        ) : (
                                            <img
                                                className="w-full h-full"
                                                src="/swe-flag.png"
                                                alt="swe"
                                            />
                                        )}
                                    </div>

                                    <select
                                        name="Menu-Lang"
                                        className="text-white bg-transparent outline-none"
                                        value={i18n.language.split("-")[0]}
                                        onChange={(e) =>
                                            changeLanguage(e.target.value)
                                        }
                                    >
                                        <option
                                            className="text-secondary"
                                            value="en"
                                        >
                                            EN
                                        </option>
                                        <option
                                            className="text-secondary"
                                            value="sv"
                                        >
                                            SV
                                        </option>
                                    </select>
                                </form>

                                {currentUser && (
                                    <MenuUser
                                        userNotifications={userNotifications}
                                        onNotificationIconClick={
                                            getNotifications
                                        }
                                        isLoading={isUserNotificationsLoading}
                                        isForDesktopMenu={true}
                                        onNotificationItemClick={
                                            handleOnNotificationItemClick
                                        }
                                        onSeeAllNotificationsInInbox={
                                            handleOnSeeAllNotificationsInInboxClick
                                        }
                                    />
                                )}
                            </div>
                            <div className="lg:flex justify-between w-max text-white">
                                {!currentUser && (
                                    <div className="hidden lg:flex items-center gap-7 font-medium">
                                        <NavLink to="/sign-in">
                                            <p
                                                className={
                                                    currentPath === "/sign-in"
                                                        ? "text-primary underline-none"
                                                        : "text-white underline-none hover:scale-110 transition duration-200"
                                                }
                                            >
                                                {t("MenuUser.signIn")}
                                            </p>
                                        </NavLink>
                                        <NavLink to="/sign-up">
                                            <button
                                                className={
                                                    currentPath === "/sign-up"
                                                        ? "bg-primary text-secondary rounded-[50px] px-7 py-2"
                                                        : "bg-white text-secondary rounded-[50px] px-7 py-2 hover:scale-110 transform duration-200"
                                                }
                                            >
                                                {t("MenuUser.signUp")}
                                            </button>
                                        </NavLink>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="lg:hidden flex items-center gap-2 lg:gap-4 w-fit text-right">
                            <Menu
                                as="div"
                                className="flex items-center relative text-left"
                            >
                                <div className="flex items-center">
                                    <Menu.Button className="inline-flex w-full justify-center font-medium text-white outline-none">
                                        <BiMenu className="w-5 h-5" />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute top-2 -right-4 mt-5 w-48 origin-top-right divide-y divide-gray-100 bg-secondary text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="px-2 py-4">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <NavLink
                                                        to="/"
                                                        style={({
                                                            isActive
                                                        }) => {
                                                            return {
                                                                color: isActive
                                                                    ? "#43EC9B"
                                                                    : "white"
                                                            };
                                                        }}
                                                    >
                                                        <button
                                                            className={`${
                                                                active
                                                                    ? "bg-primary text-secondary"
                                                                    : ""
                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                                        >
                                                            {t("Home")}
                                                        </button>
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <NavLink
                                                        to="/booking"
                                                        style={({
                                                            isActive
                                                        }) => {
                                                            return {
                                                                color: isActive
                                                                    ? "#43EC9B"
                                                                    : "white"
                                                            };
                                                        }}
                                                    >
                                                        <button
                                                            className={`${
                                                                active
                                                                    ? "bg-primary text-secondary"
                                                                    : ""
                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                                        >
                                                            {t("Booking")}
                                                        </button>
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <NavLink
                                                        to="/find-centres"
                                                        style={({
                                                            isActive
                                                        }) => {
                                                            return {
                                                                color: isActive
                                                                    ? "#43EC9B"
                                                                    : "white"
                                                            };
                                                        }}
                                                    >
                                                        <button
                                                            className={`${
                                                                active
                                                                    ? "bg-primary text-secondary"
                                                                    : ""
                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                                        >
                                                            {t("Find centres")}
                                                        </button>
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <NavLink
                                                        to="/activities"
                                                        style={({
                                                            isActive
                                                        }) => {
                                                            return {
                                                                color: isActive
                                                                    ? "#43EC9B"
                                                                    : "white"
                                                            };
                                                        }}
                                                    >
                                                        <button
                                                            className={`${
                                                                active
                                                                    ? "bg-primary text-secondary"
                                                                    : ""
                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                                        >
                                                            {t(
                                                                "Activities.Activities"
                                                            )}
                                                        </button>
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <NavLink
                                                        to="/club"
                                                        style={({
                                                            isActive
                                                        }) => {
                                                            return {
                                                                color: isActive
                                                                    ? "#43EC9B"
                                                                    : "white"
                                                            };
                                                        }}
                                                    >
                                                        {({ isActive }) => (
                                                            <button
                                                                className={`${
                                                                    active
                                                                        ? "bg-primary text-secondary"
                                                                        : ""
                                                                } ${
                                                                    isActive ||
                                                                    active
                                                                        ? "border-primary"
                                                                        : "border-white"
                                                                } border group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                                            >
                                                                {t("BookADemo")}
                                                            </button>
                                                        )}
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                            {!currentUser && (
                                                <>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <NavLink
                                                                to="/sign-in"
                                                                style={({
                                                                    isActive
                                                                }) => {
                                                                    return {
                                                                        color: isActive
                                                                            ? "#43EC9B"
                                                                            : "white"
                                                                    };
                                                                }}
                                                            >
                                                                <button
                                                                    className={`${
                                                                        active
                                                                            ? "bg-primary text-secondary"
                                                                            : ""
                                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                                                >
                                                                    {t(
                                                                        "MenuUser.signIn"
                                                                    )}
                                                                </button>
                                                            </NavLink>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <NavLink to="/sign-up">
                                                            <button
                                                                className={
                                                                    currentPath ===
                                                                    "/sign-up"
                                                                        ? "bg-primary text-secondary rounded-[50px] px-7 py-2 font-semibold"
                                                                        : "bg-white text-secondary rounded-[50px] px-7 py-2 font-semibold hover:scale-105 transform duration-200"
                                                                }
                                                            >
                                                                {t(
                                                                    "MenuUser.signUp"
                                                                )}
                                                            </button>
                                                        </NavLink>
                                                    </Menu.Item>
                                                </>
                                            )}
                                            {currentUser && (
                                                <Menu.Item>
                                                    <MenuUser
                                                        userNotifications={
                                                            userNotifications
                                                        }
                                                        onNotificationIconClick={
                                                            getNotifications
                                                        }
                                                        isLoading={
                                                            isUserNotificationsLoading
                                                        }
                                                        isForDesktopMenu={false}
                                                        onNotificationItemClick={
                                                            handleOnNotificationItemClick
                                                        }
                                                        onSeeAllNotificationsInInbox={
                                                            handleOnSeeAllNotificationsInInboxClick
                                                        }
                                                    />
                                                </Menu.Item>
                                            )}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
            </header>

            <DownloadAppPopup
                isOpenAppDownloadModal={isOpenAppDownloadModal}
                closeAppDownloadModal={closeAppDownloadModal}
            />
        </>
    );
};
