import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BiLogoFacebook, BiLogoInstagram, BiLogoTiktok } from "react-icons/bi";
import { AiOutlineLinkedin } from "react-icons/ai";

export const Footer: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <footer className="bg-secondary text-white w-full py-16 px-4 md:px-16">
                <div className="w-full mx-auto flex flex-col gap-5">
                    <div className="flex flex-col gap-8">
                        <div className="flex justify-between flex-wrap gap-5">
                            <img
                                className="w-[130px] h-auto"
                                src="/images/padelmates.png"
                                alt="PadelMates Logo"
                            />
                            <div className="flex flex-wrap items-center gap-5">
                                <p className="text-xl">{t("download")}:</p>
                                <div className="flex gap-[30px] flex-wrap items-center">
                                    <Link
                                        to="https://play.google.com/store/apps/details?id=com.padelmates"
                                        target="_blank"
                                        data-stop-propagation="true"
                                    >
                                        <img
                                            src="/images/googleplay.png"
                                            className="h-12 w-18 hover:opacity-90 transform hover:scale-105 ease-in duration-200"
                                            alt="Google Play"
                                        />
                                    </Link>
                                    <Link
                                        to="https://apps.apple.com/app/id1531797995?fbclid=IwAR3ywH0UF_AFtXYXTD2UaWNfAcOHZX8YRmgW1qV3RMC1w8RXo6EFkPVb0a0"
                                        target="_blank"
                                        data-stop-propagation="true"
                                    >
                                        <img
                                            src="/images/appstore.png"
                                            className="h-12 w-18 hover:opacity-90 transform hover:scale-105 ease-in duration-200"
                                            alt="App Store"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="w-full bg-gray-600 h-[1px]" />
                    </div>
                    <div className="flex flex-col sm:flex-row justify-between gap-4 flex-wrap items-start mt-16">
                        {/* <div className="flex flex-col gap-10 max-w-fit">
                            <p className="text-[22px]">
                                {t("subscribeNewsletter")}:
                            </p>
                            <div className="h-full flex items-end w-full sm:min-w-[270px]">
                                <input
                                    type="email"
                                    placeholder={t("Auth.emailAddress")}
                                    className="bg-secondary text-white py-3 border-b border-b-gray-600 w-[80%] outline-none pr-2 h-14"
                                />
                                <input
                                    type="submit"
                                    value=">"
                                    className="bg-white text-secondary font-bold text-[22px] pt-2.5 pb-3 px-[22px] flex justify-center items-center h-full rounded-t-md w-[20%] outline-none cursor-pointer"
                                    onClick={() => {}}
                                />
                            </div>
                        </div> */}
                        <div className="flex flex-col gap-3">
                            <p className="text-[18px] font-medium">
                                {t("services")}
                            </p>
                            <div className="flex flex-col gap-3 text-[#bebebe] text-base">
                                <Link to="booking"> {t("Booking")}</Link>
                                <Link to="activities"> {t("activities")}</Link>
                                <Link to="find-centres">
                                    {t("Find centres")}
                                </Link>
                                <Link to="club">
                                    {t("HomeClubProfit.demo")}
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <p className="text-[18px] font-medium">
                                {t("company")}
                            </p>
                            <div className="flex flex-col gap-3 text-[#bebebe] text-base">
                                <Link to="/"> {t("Home")}</Link>
                                <Link
                                    style={{
                                        color: "rgb(190, 190, 190)",
                                        textDecoration: "none"
                                    }}
                                    to="/en/privacy-policy-1"
                                >
                                    {t("Privacy")}
                                </Link>
                                <a
                                    href="mailto:support@padelmates.se"
                                    target="_blank"
                                >
                                    {t("HomeClubProfit.crm")}
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <p className="text-[18px] font-medium">
                                {t("help")}
                            </p>
                            <div className="flex flex-col gap-3 text-[#bebebe] text-base">
                                <Link to="help-center"> {t("faqs")}</Link>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <p className="text-[18px] font-medium">
                                {t("socialLinks")}
                            </p>
                            <div className="flex flex-col gap-3 text-[#bebebe] text-base">
                                <div className="inline-flex gap-2 items-center">
                                    <BiLogoFacebook className="w-6 h-6" />
                                    <a
                                        style={{
                                            color: "rgb(190, 190, 190)",
                                            textDecoration: "none"
                                        }}
                                        href="https://www.facebook.com/Padelmates"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Facebook
                                    </a>
                                </div>
                                <div className="inline-flex gap-2 items-center">
                                    <BiLogoInstagram className="w-6 h-6" />
                                    <a
                                        style={{
                                            color: "rgb(190, 190, 190)",
                                            textDecoration: "none"
                                        }}
                                        href="https://www.instagram.com/padelmates"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Instagram
                                    </a>
                                </div>
                                <div className="inline-flex gap-2 items-center">
                                    <AiOutlineLinkedin className="w-6 h-6" />
                                    <a
                                        style={{
                                            color: "rgb(190, 190, 190)",
                                            textDecoration: "none"
                                        }}
                                        href="https://www.linkedin.com/company/padelmates"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        LinkedIn
                                    </a>
                                </div>
                                <div className="inline-flex gap-2 items-center">
                                    <BiLogoTiktok className="w-6 h-6" />
                                    <a
                                        style={{
                                            color: "rgb(190, 190, 190)",
                                            textDecoration: "none"
                                        }}
                                        href="https://www.tiktok.com/@padelmates"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Tiktok
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-sm text-gray-600 text-center mt-16">
                            ©2023 padelmates.se
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
};
