import moment from "moment";
import { t } from "i18next";

const CourtComponent = ({
    bookingData,
    minutes,
    endTime,
    isBooked,
    selectedCourt,
    startTime,
    accessCode
}) => {
    const sports = selectedCourt
        ? Object.keys(selectedCourt?.sport)?.filter(
              (key) => selectedCourt["sport"][key]
          )
        : [];
    const renderedSports = sports.join(" | ");
    const storedDate = localStorage.getItem("selectedDate");
    const date =
        (storedDate &&
            moment(storedDate.replace(/-/g, "/").replace(/["\\]/g, "")).format(
                "MMMM Do, YYYY"
            )) ??
        moment().format("MMMM Do, YYYY");

    if (!isBooked) {
        return (
            <div className="W-full grid grid-cols-1 sm:grid-cols-2 gap-4 py-5 items-center">
                <div className="sm:flex sm:justify-between items-center h-full">
                    <div className="flex items-center gap-4 flex-wrap">
                        <img
                            className="w-[95px] h-[95px] rounded-[8px] shadow shadow-gray-500"
                            src={
                                bookingData.photo_url === null ||
                                bookingData.photo_url === ""
                                    ? `/images/club-icon.png`
                                    : bookingData.photo_url
                            }
                            alt="Logo"
                        />
                        <div className="flex flex-col text-secondary">
                            <p className="text-[15px]">{bookingData?.name}</p>
                            <h3 className="font-semibold text-2xl">
                                {selectedCourt?.name}
                            </h3>
                            <div className="text-[15px] text-[#666B78]">
                                <p>
                                    {renderedSports} |{" "}
                                    {selectedCourt?.indoor
                                        ? t("indoor")
                                        : t("outdoor")}{" "}
                                    +{" "}
                                    {`${
                                        selectedCourt?.double
                                            ? t("double")
                                            : t("single")
                                    }`}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hidden sm:block ml-4 w-[1px] h-full bg-gray-300"></div>
                </div>

                <div className="flex flex-col gap-3 text-lg">
                    <div className="flex justify-between gap-3 flex-wrap">
                        <p>{t("CourtComponent.date")}</p>
                        <h4 className="font-semibold">{date}</h4>
                    </div>
                    <div className="flex justify-between gap-3 flex-wrap">
                        <p>{t("CourtComponent.time")}</p>
                        <div className="text-end">
                            <h4 className="font-semibold">
                                {minutes} {t("CourtComponent.min")}
                            </h4>
                            <p className="text-[15px]">
                                {startTime} - {endTime}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (isBooked) {
        return (
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 py-5 items-center">
                <div className="sm:flex sm:justify-between items-center h-full">
                    <div className="flex items-center gap-4 flex-wrap">
                        <img
                            className="w-[95px] h-[95px] rounded-[8px] shadow shadow-gray-500"
                            src={
                                bookingData.photo_url === null ||
                                bookingData.photo_url === ""
                                    ? `/images/club-icon.png`
                                    : bookingData.photo_url
                            }
                            alt="Logo"
                        />
                        <div className="flex flex-col text-secondary">
                            <p className="text-[15px]">{bookingData?.name}</p>
                            <h3 className="font-semibold text-2xl">
                                {selectedCourt?.name}
                            </h3>
                            <div className="text-[15px] text-[#666B78]">
                                <p>
                                    {renderedSports} |{" "}
                                    {selectedCourt?.double
                                        ? " Double"
                                        : "Single"}{" "}
                                    +{" "}
                                    {`${
                                        selectedCourt?.indoor
                                            ? "Indoor"
                                            : "Outdoor"
                                    }`}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hidden sm:block w-[1px] h-full bg-gray-300"></div>
                </div>

                <div className="flex flex-col gap-3 text-lg">
                    <div className="flex justify-between gap-3 flex-wrap">
                        <p>{t("CourtComponent.code")}</p>
                        <h4 className="font-semibold">
                            {accessCode ? accessCode : `No code available`}
                        </h4>
                    </div>
                    <div className="flex justify-between gap-3 flex-wrap">
                        <p>{t("CourtComponent.date")}</p>
                        <h4 className="font-semibold">{date}</h4>
                    </div>
                    <div className="flex justify-between gap-3 flex-wrap">
                        <p>{t("CourtComponent.time")}</p>
                        <div className="text-end">
                            <h4 className="font-semibold">
                                {minutes} {t("CourtComponent.min")}
                            </h4>
                            <p className="text-[15px]">
                                {startTime} - {endTime}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default CourtComponent;
