import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateClubInfo } from "../../Api/api";
import { AuthContext } from "../AuthProvider";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { generateGameType } from "../../Utilities/utils";

const FollowedClub = ({
    followedClub,
    key,
    setGetFollowedClubs
}: {
    followedClub: any;
    key: string;
    setGetFollowedClubs: any;
}) => {
    const { t } = useTranslation();
    const currentUser = useContext(AuthContext);
    const [loading, setLoading] = useState<any>(null);
    const navigate = useNavigate();

    const handleUnfollowClub = (id: string) => {
        setLoading(id);
        const body = {
            following: false
        };
        updateClubInfo(id, currentUser?.uid, body).then(
            (followClubResp: any) => {
                if (followClubResp?.data.success) {
                    setGetFollowedClubs(true);
                    setLoading(null);
                }
            }
        );
    };

    return (
        <div
            className="flex items-center flex-wrap gap-4 pb-6 border-b border-b-[#000b2926]"
            key={key}
        >
            <div
                className="border border-[#000b2966] rounded-[6px] h-fit w-fit flex justify-center items-center bg-transparent hover:cursor-pointer"
                onClick={() => navigate(`/club/${followedClub._id}`)}
            >
                <img
                    src={
                        followedClub.photo_url ||
                        generateGameType(
                            followedClub?.gameType || followedClub?.name
                        )
                    }
                    className="w-[85px] h-[85px] rounded-[6px]"
                    alt={followedClub.name}
                />
            </div>
            <div className="flex flex-col gap-1.5">
                <h3
                    className="text-xl font-semibold hover:cursor-pointer"
                    onClick={() => navigate(`/club/${followedClub._id}`)}
                >
                    {followedClub.name}
                </h3>
                <p
                    className="text-[#666B78] text-[15px] hover:cursor-pointer"
                    onClick={() => navigate(`/club/${followedClub._id}`)}
                >
                    {followedClub.city}, {followedClub.country}
                </p>
                <button
                    className="border border-[#00081E] rounded-sm max-w-fit px-1 h-[27px] hover:bg-secondary hover:text-white"
                    onClick={() => handleUnfollowClub(followedClub._id)}
                >
                    <span className="flex justify-between items-center gap-1">
                        {t("unfollow")} -
                        {loading === followedClub?._id ? (
                            <CgSpinner className="animate-spin" />
                        ) : null}
                    </span>
                </button>
            </div>
        </div>
    );
};

export default FollowedClub;
