import React, { Dispatch, SetStateAction } from "react";

const NewPassword = ({
    setShowComponent
}: {
    setShowComponent: Dispatch<SetStateAction<string>>;
}) => {
    const handleSubmit = () => {
        setShowComponent("SuccessMessage");
    };
    return (
        <div className="text-center py-5 px-2 sm:px-[22px] bg-[#FBFBFB] rounded-3xl shadow">
            <h2 className="text-2xl font-semibold text-center">New password</h2>
            <div className="my-4 bg-[#000b2926] w-full h-[1px]" />
            <form className="grid grid-cols-1 gap-[30px] mt-11 min-w-[200px] w-full max-w-[481px] mx-auto">
                <div className="relative h-[60px] sm:h-[67px]">
                    <label className="absolute bg-[#FBFBFB] text-[#666B78] -top-2.5 left-4 text-sm px-1">
                        New password
                    </label>
                    <input
                        className="w-full h-[60px] sm:h-[67px] bg-white border-[1px] border-[#666B78] rounded-[60px] text-secondary font-normal p-4 outline-primary"
                        type="password"
                        //   placeholder="Old password"
                        name="newPassword"
                        required
                    />
                </div>
                <div className="relative h-[60px] sm:h-[67px]">
                    <label className="absolute bg-[#FBFBFB] text-[#666B78] -top-2.5 left-4 text-sm px-1">
                        Repeat new password
                    </label>
                    <input
                        className="w-full h-[60px] sm:h-[67px] bg-white border-[1px] border-[#666B78] rounded-[60px] text-secondary font-normal p-4 outline-primary"
                        type="password"
                        //   placeholder="Old password"
                        name="repeatPassword"
                        required
                    />
                </div>
                <p className="text-sm text-center -mt-[15px]">
                    Password should contain a minimum of 7 characters.
                </p>
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-secondary text-white hover:bg-primary hover:text-secondary transform duration-200 rounded-3xl w-full h-[60px] font-semibold mt-5 sm:mt-7 mb-12 sm:mb-28"
                >
                    Change Password
                </button>
            </form>
        </div>
    );
};

export default NewPassword;
