import React from "react";
import { useTranslation } from "react-i18next";


const InitialInfo = () => {
    const { t } = useTranslation();

    return (
        <div className="lg:flex lg:flex-row lg:items-center flex flex-col bg-secondary min-h-screen py-8 px-4">
            <div className="flex flex-col lg:py-20 py-10 gap-20 lg:px-16 flex-1">
                <div className="flex flex-col gap-6 text-white ">
                    <h2 className="lg:text-[45px] text-[28px] lg:leading-[50px] font-semibold">
                        {t("HomepagePlayer.app-text.title")}
                    </h2>
                    {/* <br /> */}
                    <p className="lg:text-[16px] text-[14px] text-[#FFFFFFCC]">
                        {t("HomepagePlayer.app-text.p-1")}
                    </p>
                    {/* <br /> */}
                    <p className="lg:text-[16px] text-[16px] text-[#FFFFFFCC]">
                        {t("HomepagePlayer.app-text.p-2")}
                    </p>
                </div>
                <div className="flex flex-col gap-6 ">
                    <h3 className="lg:text-[24px] text-[20px] text-[#FFFFFFCC] font-medium">
                        {t("download")}
                    </h3>
                    <div className="flex gap-4">
                        <a
                            href="https://play.google.com/store/apps/details?id=com.padelmates"
                            target="_blank"
                            data-stop-propagation="true"
                            rel="noreferrer"
                        >
                            <img
                                src="./images/home/googleplay.png"
                                className="lg:h-16 lg:w-52 h-12 w-34 hover:opacity-90 transform hover:scale-105 ease-in duration-200"
                                alt="Google Play"
                            />
                        </a>
                        <a
                            href="https://apps.apple.com/app/id1531797995?fbclid=IwAR3ywH0UF_AFtXYXTD2UaWNfAcOHZX8YRmgW1qV3RMC1w8RXo6EFkPVb0a0"
                            target="_blank"
                            data-stop-propagation="true"
                            rel="noreferrer"
                        >
                            <img
                                src="./images/home/appstore.png"
                                className="lg:h-16 lg:w-52 h-12 w-34 hover:opacity-90 transform hover:scale-105 ease-in duration-200"
                                alt="App Store"
                            />
                        </a>
                    </div>
                    <br />
                </div>
            </div>
            <div className="flex-1 h-screen flex justify-center">
                <img src="./images/home/phones.png" className="h-screen" alt="video screen" />
            </div>
        </div>
    );
};

export default InitialInfo;
