import React, { Dispatch, SetStateAction, useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { useTranslation } from "react-i18next";
import SuccessMessage from "./SuccessMessage";
const SubmitEmail = ({
    setShowComponent
}: {
    setShowComponent: Dispatch<SetStateAction<string>>;
}) => {
    const [email, setEmail] = useState("");
    const [isResetSent, setIsResetSent] = useState(false);
    const [error, setError] = useState("");
    const [emailError, setEmailError] = useState("");

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!email) {
            setEmailError(`${t("Auth.emailRequired")}`);
        } else if (!emailPattern.test(email)) {
            setEmailError(`${t("Auth.invalidEmail")}`);
        } else {
            setEmailError("");
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setIsResetSent(true);
            setError("");
        } catch (error: any) {
            setIsResetSent(false);
            setError(error.message);
        }
    };

    const { t } = useTranslation();
    return (
        <div>
            <div className="max-w-[711px] mx-auto bg-[#FBFBFB] w-full flex flex-col justify-center items-center px-4 sm:px-5 py-10">
                {isResetSent ? (
                    // <SuccessMessage />
                    <div className="mt-10 text-center">
                        <p>
                            {t("Auth.emailSent")} {email}.
                        </p>
                        <p>{t("Auth.checkEmail")}</p>
                    </div>
                ) : (
                    <>
                        <div className="flex flex-col justify-center items-center w-full">
                            <h1 className="text-[24px] text-center font-semibold">
                                {t("Auth.submitEmailTitle")}
                            </h1>
                        </div>
                        <hr className="w-full bg-slate-300 mt-2" />
                        <form className="flex flex-col mt-10 w-full max-w-[460px] mx-auto gap-6">
                            <div className="relative h-[60px] sm:h-[67px]">
                                <label className="absolute bg-[#FBFBFB] text-[#666B78] -top-2.5 left-3 text-sm px-1">
                                    {t("Auth.emailAddress")}
                                </label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        const newEmail = e.currentTarget.value;
                                        setEmail(newEmail);
                                        if (newEmail) {
                                            if (!emailPattern.test(newEmail)) {
                                                setEmailError(
                                                    `${t("Auth.invalidEmail")}`
                                                );
                                            } else {
                                                setEmailError("");
                                            }
                                        } else {
                                            setEmailError(
                                                `${t("Auth.emailRequired")}`
                                            );
                                        }
                                    }}
                                    required
                                    placeholder={t("Auth.emailAddress")}
                                    className={`${
                                        emailError
                                            ? "border-red-500 outline-red-500"
                                            : "border-[#000b2966] outline-primary"
                                    } w-full max-w-[500px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                                />
                                {emailError && (
                                    <p className="text-red-500 mt-1 ml-2 text-xs">
                                        {emailError}
                                    </p>
                                )}
                            </div>
                            <div>
                                <p className="text-sm text-center -mt-[15px]">
                                    {t("Auth.provideEmail")}
                                </p>
                            </div>
                            <div className="flex flex-wrap gap-5 mt-8 sm:mt-16 mb-5">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowComponent("ProfileManagement");
                                    }}
                                    className="border text-secondary border-secondary hover:bg-red-600 hover:border-red-600 rounded-3xl flex-1 h-[60px] font-semibold hover:text-white"
                                >
                                    {t("PaymentComponent.cancel")}
                                </button>
                                <input
                                    type="submit"
                                    onClick={handleResetPassword}
                                    value={t("send")}
                                    className="bg-secondary cursor-pointer text-white hover:bg-primary hover:text-secondary transform duration-200 rounded-3xl flex-1 h-[60px] font-semibold"
                                />
                            </div>
                            {error && (
                                <p className="text-red-500 text-center mt-5">
                                    {error}
                                </p>
                            )}
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default SubmitEmail;
