import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import { ProfileFormData } from "./Types.types";
import { useTranslation } from "react-i18next";
// import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Modal } from "../ui/Modal";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";

const ProfileManagement = ({
    setShowComponent,
    userData,
    saveProfile
}: {
    setShowComponent: Dispatch<SetStateAction<string>>;
    userData: ProfileFormData | undefined;
    saveProfile: (data: ProfileFormData) => void;
}) => {
    const { t } = useTranslation();
    // const [showPassword, setShowPassword] = useState(false);
    const [openChangePasswordModal, setOpenChangePasswordModal] =
        useState(false);
    const [isResetSent, setIsResetSent] = useState(false);
    const [error, setError] = useState("");

    const [formData, setFormData] = useState<ProfileFormData>({
        name: userData?.name ?? "",
        phone: userData?.phone ?? ""
    });

    useEffect(() => {
        if (userData) {
            const { email = "", phone = "", name } = userData;
            setFormData({
                email,
                phone,
                name
            });
        }
    }, [userData]);

    const handleFormInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let isFormValid = true;
        if (!formData.name.trim()) {
            isFormValid = false;
        }

        if (isFormValid) {
            saveProfile(formData);
        } else {
        }
    };

    // const togglePasswordVisibility = () => {
    //     setShowPassword((prevShowPassword) => !prevShowPassword);
    // };

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            await sendPasswordResetEmail(auth, userData?.email as string);
            setIsResetSent(true);
            setError("");
        } catch (error: any) {
            setIsResetSent(false);
            setError(error.message);
        }
    };

    return (
        <div className="text-center py-5 px-2 sm:px-[22px] bg-[#FBFBFB] rounded-3xl shadow">
            <h2 className="text-2xl font-semibold text-center">
                {t("AccountSettings.profileTitle")}
            </h2>
            <div className="my-4 bg-[#000b2926] w-full h-[1px]" />
            <form onSubmit={handleFormSubmit} noValidate>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-10">
                    <div className="relative py-2">
                        <label className="absolute bg-[#FBFBFB] text-[#000b2999] -top-1.5 left-[8px] text-sm px-1">
                            {t("AccountSettings.labelUserName")}{" "}
                            <span className="text-red-400 text-xl align-middle">
                                *
                            </span>
                        </label>
                        <input
                            className={`min-w-[200px] w-full h-[50px] bg-white border-[1px] ${
                                formData.name.trim()
                                    ? "border-[#000b2999]"
                                    : "border-red-400"
                            }  rounded-[50px] text-secondary font-normal p-4 outline-primary`}
                            type="text"
                            //   placeholder="User name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInputChange}
                        />
                        <p
                            className={`ml-2 text-start mt-2 text-sm text-red-500  ${
                                formData.name.trim() ? " hidden " : "block"
                            }`}
                        >
                            {t("AccountSettings.labelNameErrorMessage")}
                        </p>
                    </div>
                    <div className="relative py-2">
                        <label className="absolute bg-[#FBFBFB] text-[#000b2999] -top-1.5 left-[8px] text-sm px-1">
                            {t("AccountSettings.labelEmail")}
                        </label>
                        <input
                            className="min-w-[200px] w-full h-[50px] bg-gray-100 border-[1px] border-[#000b2999] rounded-[50px] text-secondary font-normal p-4 outline-primary"
                            type="email"
                            // placeholder="User email"
                            name="email"
                            value={userData?.email ?? ""}
                            disabled
                        />
                    </div>
                    <div className="relative py-2">
                        <label className="absolute bg-[#FBFBFB] text-[#000b2999] -top-1.5 left-[8px] text-sm px-1">
                            {t("AccountSettings.labelPhone")}
                        </label>
                        <input
                            className={`min-w-[200px] w-full h-[50px] bg-white border-[1px] border-[#000b2999] rounded-[50px] text-secondary font-normal p-4 outline-primary`}
                            type="text"
                            // placeholder="Phone number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleFormInputChange}
                        />
                    </div>
                    <div className="relative flex flex-col py-2">
                        {/* <label className="absolute bg-[#FBFBFB] text-[#000b2999] -top-1.5 left-[8px] text-sm px-1">
                            Password
                        </label>
                        <div className="flex items-center">
                            <input
                                className="min-w-[200px] w-full h-[50px] bg-white border-[1px] border-[#000b2999] focus:bg-white rounded-[50px] text-secondary font-normal pl-4 pr-10 outline-none"
                                type={showPassword ? "text" : "password"}
                                // placeholder="Password"
                                name="password"
                            />
                            {showPassword ? (
                                <BsEyeSlashFill
                                    className="w-4 h-4 -ml-8 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                />
                            ) : (
                                <BsEyeFill
                                    className="w-4 h-4 -ml-8 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                />
                            )}
                        </div> */}
                        <button
                            type="button"
                            onClick={() => {
                                setOpenChangePasswordModal(true);
                            }}
                            className="text-sm text-primary underline text-start mt-4"
                        >
                            {t("AccountSettings.changePasswordByClicking")}
                        </button>
                    </div>
                </div>
                <div className="flex justify-end flex-wrap gap-4 items-center mt-10 mb-6">
                    {/* <button className="rounded-full border border-secondary w-full max-w-[148px] min-h-[48px] py-1 px-3 font-semibold">
            Cancel
          </button> */}
                    <button
                        type="submit"
                        className="rounded-full w-full max-w-[148px] min-h-[48px] py-1 px-3 font-semibold bg-secondary text-white hover:bg-primary hover:text-secondary transform duration-200"
                    >
                        {t("AccountSettings.btnSave")}
                    </button>
                </div>
            </form>

            <Modal
                open={openChangePasswordModal}
                setOpen={setOpenChangePasswordModal}
            >
                <div className="bg-white p-5 lg:py-8 lg:px-16 font-semibold rounded-2xl w-full mx-auto">
                    {isResetSent ? (
                        <div className="mt-8 mb-5 text-center">
                            <p>
                                {t("Auth.emailSent")} {userData?.email}.
                            </p>
                            <p>{t("Auth.checkEmail")}</p>
                            <button
                                type="button"
                                onClick={() => {
                                    setOpenChangePasswordModal(false);
                                }}
                                className="border text-secondary border-secondary hover:bg-red-600 hover:border-red-600 rounded-3xl flex-1 h-[60px] w-[100px] mt-6 font-semibold hover:text-white"
                            >
                                {t("close")}
                            </button>
                        </div>
                    ) : (
                        <div>
                            <p className="mt-8 mb-5">
                                {t("AccountSettings.areYouSure")}
                            </p>
                            <div className="flex flex-wrap gap-5 mt-8 mb-5">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setOpenChangePasswordModal(false);
                                    }}
                                    className="border min-w-max px-4 text-secondary border-secondary hover:bg-red-600 hover:border-red-600 rounded-3xl flex-1 h-[60px] font-semibold hover:text-white"
                                >
                                    {t("PaymentComponent.cancel")}
                                </button>
                                <button
                                    type="submit"
                                    onClick={handleResetPassword}
                                    value={t("send")}
                                    className="bg-secondary min-w-max px-6 cursor-pointer
                                    text-white hover:bg-primary
                                    hover:text-secondary transform duration-200
                                    rounded-3xl flex-1 h-[60px] font-semibold"
                                >
                                    {t("MenuUser.reset")}
                                </button>
                            </div>
                            {error && (
                                <p className="text-red-500 text-center mt-6">
                                    {error}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default ProfileManagement;
