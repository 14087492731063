import React, { useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { Navigate } from "react-router-dom";
import AxiosFastAPI from "../Api/AxiosFastAPI";
import Axios from "../Api/Axios";
export const WithAuth = (WrappedComponent) => {
    const WithAuth = (props) => {
        const currentUser = useContext(AuthContext);

        if (!currentUser) {
            return <Navigate to="/sign-in" />;
        } else {
            localStorage.setItem("token", currentUser.accessToken);
            localStorage.setItem("uesrId", JSON.stringify(currentUser.uid));
            AxiosFastAPI.defaults.headers.authorization = `Bearer ${currentUser.accessToken}`;
            Axios.defaults.headers.authorization = `PadelMates ${currentUser.accessToken}`;
        }
        return <WrappedComponent currentUser={currentUser} {...props} />;
    };

    return WithAuth;
};
