import moment from "moment";
import React from "react";
import { BiChevronUp } from "react-icons/bi";
import { t } from "i18next";
import { useBookingAtom } from "../../Utilities/clubStore";

const ExtraTimeManager = ({
    isShowing = null,
    onPress = null,
    currentPrice = null,
    priceSetter,
    currentMinutes = null,
    minutesSetter,
    changeTime,
    selectedCourt,
    availableSlotState,
    minutes,
    time,
    price,
    currency,
    setMins,
    setEndTime,
    basePrice
}) => {
    const [bookingAtom, setBookingAtom] = useBookingAtom();

    return (
        <div className="w-full flex flex-col">
            <div className="flex gap-3 flex-wrap justify-between items-center w-full text-lg text-secondary mb-[15px]">
                <h4 className="font-medium">{t("ExtraTimeManager.addTime")}</h4>
                <button onClick={onPress} className="outline-none">
                    <BiChevronUp className="w-8 h-8 font-semibold" />
                </button>
            </div>
            {availableSlotState &&
            Object.keys(availableSlotState)?.find(
                (key) => availableSlotState[key]
            )
                ? Object.keys(availableSlotState)?.map((key) =>
                      availableSlotState[key] ? (
                          <React.Fragment key={key}>
                              <div className="text-secondary flex gap-3 flex-wrap items-center justify-between mb-[10px]">
                                  <div>
                                      <h5 className="text-lg font-semibold">
                                          {key} {t("ExtraTimeManager.min")}
                                      </h5>
                                      <p className="text-[15px]">
                                          {time} -{" "}
                                          {moment(time, "HH:mm")
                                              .add(key, "minutes")
                                              .format("HH:mm")}
                                      </p>
                                  </div>
                                  <button
                                      className={`${
                                          minutes === key
                                              ? "bg-primary text-secondary"
                                              : "bg-secondary text-white hover:opacity-80 transform duration-200"
                                      } px-4 py-1 min-h-[44px] w-[126px] rounded-[15px] text-base flex justify-center items-center font-semibold`}
                                      onClick={() => {
                                          if (minutes === key) return;
                                          minutesSetter(Number(key));
                                          priceSetter(
                                              (Number(key) / 60) * basePrice
                                          );
                                          changeTime();
                                          setBookingAtom({
                                              ...bookingAtom,
                                              price:
                                                  (Number(key) / 60) *
                                                  basePrice,
                                              mins: key,
                                              endTime: moment(time, "HH:mm")
                                                  .add(key, "minutes")
                                                  .format("HH:mm")
                                          });
                                      }}
                                  >
                                      {" "}
                                      {minutes === key
                                          ? t("selected")
                                          : `${(
                                                (Number(key) / 60) *
                                                basePrice
                                            ).toFixed(2)} ${currency}`}
                                  </button>
                              </div>
                          </React.Fragment>
                      ) : null
                  )
                : null}
        </div>
    );
};

export default ExtraTimeManager;
