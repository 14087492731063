
import { useTranslation } from "react-i18next";
import { HiBadgeCheck } from "react-icons/hi";


const AppInfo = () => {
    const { t } = useTranslation();

    return (
        <div className="">
            <div className="lg:flex-row lg:items-center px-4 flex flex-col gap-16    bg-primary h-auto md:justify-center bg-cover bg-center bg-[url('/public/images/Background2.png')]">
                <img
                    src="./images/home/phones.png"
                    alt="phones"
                    className="w-[500px]"
                />
                <div className="flex-col gap-8 max-w-[600px]  md:mt-5 text-white pb-16">
                    <h1 className="lg:text-[45px] text-[24px] font-semibold lg:leading-[50px] mb-8">
                        {t("HomepagePlayer.Footer-content-text.title")}
                    </h1>

                    <p className="lg:text-[20px] text-[16px] text-[#fafafabf]">
                        {t("HomepagePlayer.Footer-content-text.p-1")}
                    </p>

                    <div className="flex items-start gap-2 mt-10">
                    <HiBadgeCheck size="28px" className="min-w-[28px]"/> <p className="lg:text-[20px] text-[16px]">
                        {t("HomepagePlayer.Footer-content-text.steps.step1")}
                    </p>
                    </div>
                    <div className="flex items-start gap-2 mt-4">
                    <HiBadgeCheck size="28px" className="min-w-[28px]"/> <p className="lg:text-[20px] text-[16px]">
                        {t("HomepagePlayer.Footer-content-text.steps.step2")}
                    </p>
                    </div>
                    <div className="flex items-start gap-2 mt-4">
                    <HiBadgeCheck size="28px" className="min-w-[28px]"/> <p className="lg:text-[20px] text-[16px]">
                        {t("HomepagePlayer.Footer-content-text.steps.step3")}
                    </p>
                    </div>

                   
                </div>
            </div>
        </div>
    );
};

export default AppInfo;
