import axios from "axios";
import { getAuth } from "firebase/auth";

const AxiosFastAPI = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_FAST_API,
    headers: {
        "Content-Type": "application/json"
    }
});
// Add request interceptor
AxiosFastAPI.interceptors.request.use(
    async (config) => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const token = await user.getIdToken();
            config.headers.authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default AxiosFastAPI;
