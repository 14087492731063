import React, { useEffect, useState } from "react";

// import "../../styles/FindCentre.css";
import { ComponentDiscover } from "../booking/ComponentDiscover.tsx";
import { getNearbyClubs, getSearchNearbyClubs } from "../../Api/api.ts";
import moment from "moment";
import AxiosFastAPI from "../../Api/AxiosFastAPI.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ComponentBookingCard } from "../booking/ComponentBookingCard.tsx";
import BookingModal from "../booking/BookingModal.tsx";

// const publishableKey = process.env.REACT_APP_STRIPE_TEST_KEY as string;
const publishableKey = process.env.REACT_APP_STRIPE_KEY as string;
const stripePromise = loadStripe(publishableKey);

interface ICourt {
    _id: string;
    name: string;
    // club_id: string;
    sport: {
        padel: boolean;
        tennis: boolean;
        badminton: boolean;
        squash: boolean;
        pickleball: boolean;
    };
    indoor: boolean;
    double: boolean;
}
interface ISlot {
    slot_id: string;
    price: number;
    start_datetime: number;
    end_datetime: number;
}

interface FindCentreProps {
    currentUser?: any;
}

export type CourtType = "indoor_courts" | "outdoor_courts";
export type CourtDimension = "single_courts" | "double_courts";
export type SportType =
    | "padel"
    | "tennis"
    | "badminton"
    | "squash"
    | "pickleball";

// const FindCentre = ({currentUser:FindCentre}) => {
const FindCentre: React.FC<FindCentreProps> = ({ currentUser }) => {
    const [data, setData] = useState([]);
    const [modalActive, setModalActive] = useState(false);
    const [modalPage, setModalPage] = useState(1);

    const [sports, setSports] = useState<SportType[]>([
        "padel",
        "tennis",
        "squash",
        "badminton",
        "pickleball"
    ]);
    const [radius, setRadius] = useState<number>(50);
    const [courtType, setCourtType] = useState<CourtType[]>(["indoor_courts"]);
    const [courtDimension, setCourtDimension] = useState<CourtDimension[]>([
        "single_courts"
    ]);
    const currentDate = new Date();
    const [applyFilter, setApplyFilter] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState<string>(
        moment().format("HH:mm")
    );

    /* const startTime = convertUTC(selectedDate, '00:00', timezone);
   const endTime = convertUTC(selectedDate, '23:59', timezone);*/

    const openOrCloseModal = () => {
        setModalActive(!modalActive);
    };

    const getAvailableSlots = async (
        club_id: string,
        start_datetime: number,
        end_datetime: number
    ) => {
        try {
            const { data } = await AxiosFastAPI.get(
                `/player/player_booking/all_courts_slot_prices_v2`,
                {
                    params: {
                        club_id,
                        start_datetime,
                        end_datetime
                    }
                }
            );
            const slotsResponse: any = data;

            return slotsResponse || [];
        } catch (error: any) {
            /*  throw new Error(
            error?.details?.message || 'Failed to fetch available slots'
        );*/
            console.log("Error retrieving slots");
        }
    };

    const searchNearByClubs = async (
        clubName: string,
        sport: string,
        date: any
    ) => {
        setData([]);
        const selectedSport =
            sport === "all" || sport == undefined || sport === ""
                ? []
                : [sport];
        setSelectedStartDate(date);
        let data = {
            radius,
            latitude: 78.9012,
            longitude: 12.3456,
            currentUser,
            sports,
            courtDimension,
            clubName,
            selectedSport,
            date
        };

        const defaultResult = await getSearchNearbyClubs(data);
        if (
            defaultResult?.data?.searched_clubs &&
            defaultResult.data.searched_clubs.length > 0
        ) {
            setData(defaultResult.data.searched_clubs);
            // localStorage.setItem("clubs", JSON.stringify(defaultResult.data.searched_clubs));
        } else {
            setData([]);
        }
    };

    useEffect(() => {
        setSelectedStartDate(null);
        const fetchData = async () => {
            let latitude;
            let longitude;
            let data = {
                radius,
                latitude,
                longitude,
                // currentUser,
                sports,
                courtType,
                courtDimension
            };

            try {
                if (navigator.geolocation) {
                    const position: any = await new Promise(
                        (resolve, reject) => {
                            navigator.geolocation.getCurrentPosition(
                                resolve,
                                reject
                            );
                        }
                    );
                    latitude = position.coords.latitude;
                    longitude = position.coords.longitude;
                } else {
                    console.error(
                        "Geolocation is not supported by this browser."
                    );
                    // Provide default coordinates if geolocation is not supported
                    latitude = 78.9012;
                    longitude = 12.3456;
                }

                const result = await getNearbyClubs(data);
                if (
                    result?.data?.filtered_clubs &&
                    result.data.filtered_clubs.length > 0
                ) {
                    setData(result.data.filtered_clubs);
                } else {
                    setData([]);
                }
            } catch (error) {
                console.error("Error fetching geolocation or clubs:", error);
                const defaultData = {
                    ...data,
                    latitude: 78.9012,
                    longitude: 12.3456
                };
                const defaultResult = await getNearbyClubs(defaultData);
                if (
                    defaultResult?.data?.filtered_clubs &&
                    defaultResult.data.filtered_clubs.length > 0
                ) {
                    setData(defaultResult.data.filtered_clubs);
                } else {
                    setData([]);
                }
            }
        };
        // fetchData();
    }, [sports, radius, courtType, courtDimension, applyFilter]);

    const [price, setPrice] = useState<number>(0);
    const [basePrice, setBasePrice] = useState<number>(0);
    const [isShown, setIsShown] = useState<boolean>(false);
    const [mins, setMins] = useState<number>(0);
    const [endTime, setEndTime] = useState<string>("");
    const [startTime, setStartTime] = useState<string>("");
    const [selectedCourt, setSelectedCourt] = useState<any>(null);
    const [availableSlotState, setAvailableSlotState] = useState(null);
    const [selectedBookingData, setSelectedBookingData] = useState(null);

    const handleFilterApply = (filterValues: any) => {
        setSports(filterValues.sports);
        setRadius(filterValues.radius);
        setCourtType(filterValues.courtType);
        setCourtDimension(filterValues.courtDimension);
        setApplyFilter(!applyFilter);
    };

    return (
        <div className="w-full min-h-[550px] bg-cover bg-center bg-[url('/public/images/Background.png')] flex flex-col justify-center py-4">
            <ComponentDiscover isHome={true} searchClubs={searchNearByClubs} />
            {/* <hr /> */}
            <Elements stripe={stripePromise}>
                {data.length > 0
                    ? data.map((court: any) => (
                          <div key={court._id}>
                              <ComponentBookingCard
                                  setPrice={setPrice}
                                  setMins={setMins}
                                  pageSetter={setModalPage}
                                  jsonData={court}
                                  selectedStartDate={selectedStartDate}
                                  setSelectedBookingData={
                                      setSelectedBookingData
                                  }
                                  endTime={endTime}
                                  setEndTime={setEndTime}
                                  startTime={startTime}
                                  setStartTime={setStartTime}
                                  onClose={openOrCloseModal}
                                  selectedSlot={selectedSlot}
                                  setSelectedSlot={setSelectedSlot}
                                  setSelectedCourt={setSelectedCourt}
                                  setAvailableSlotState={setAvailableSlotState}
                                  basePrice={basePrice}
                                  setBasePrice={setBasePrice}
                                  // currentUser={currentUser}
                              />
                              <BookingModal
                                  setPrice={setPrice}
                                  startTime={startTime}
                                  setIsShown={setIsShown}
                                  setMins={setMins}
                                  mins={mins}
                                  setEndTime={setEndTime}
                                  endTime={endTime}
                                  setStartTime={setStartTime}
                                  bookingData={selectedBookingData}
                                  page={modalPage}
                                  pageSetter={setModalPage}
                                  show={modalActive}
                                  onClose={openOrCloseModal}
                                  price={price}
                                  currency={court?.currency}
                                  selectedCourt={selectedCourt}
                                  availableSlotState={availableSlotState}
                                  selectedSlot={selectedSlot}
                                  basePrice={basePrice}
                                  setBasePrice={setBasePrice}
                              />
                          </div>
                      ))
                    : null}
            </Elements>
        </div>
    );
};

export default FindCentre;
