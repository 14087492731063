import { useEffect, useRef, useState } from "react";
import { AiOutlineLock } from "react-icons/ai";
import { FaCreditCard, FaEuroSign, FaInfoCircle } from "react-icons/fa";
import {
    getAllCards,
    handleRemoveCard,
    handleSaveCard,
    getCredits
} from "../../Api/api";
import { t } from "i18next";
import {
    BsCheckCircle,
    BsCheckCircleFill,
    BsFillDatabaseFill
} from "react-icons/bs";
import Popup from "./Popup";
import PopupInfo from "./PopupInfo";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CgSpinner } from "react-icons/cg";

const PaymentComponent = ({
    price,
    setSelectedCard,
    selectedCard,
    hidePaymentIcons = false,
    setCard = (card) => {},
    clubId,
    currency = "",
    isSelected,
    setIsSelected,
    userCredit,
    setUserCredit,
    setErrorMessage,
    saveCardForFutureUse,
    setSaveCardForFutureUse,
    cardDetails,
    setCardDetails,
    handleCardElementSelect
}) => {
    const [showCardPayment, setShowCardPayment] = useState(false);
    const [isLoadingCards, setIsLoadingCards] = useState(true);
    const [infoPopupTitle, setInfoPopupTitle] = useState("");
    const [infoPopupSubtitle, setInfoPopupSubtitle] = useState("");
    const [showInfo, setShowInfo] = useState(false);
    const [cardNumber, setCardNumber] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [securityCode, setSecurityCode] = useState("");
    const [savedCards, setSavedCards] = useState([]);
    const [showSavedCards, setShowSavedCards] = useState(false);
    const [getCards, setGetCards] = useState(false);
    const [cardsFetching, setCardsFetching] = useState(true);
    const [cardError, setcardError] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [deleted, setDeleted] = useState(false);

    const stripe = useStripe();
    // const elements = useElements();

    const cardElementRef = useRef(null);

    const clearFields = () => {
        setCardNumber("");
        setExpiryDate("");
        setSecurityCode("");
        setCardDetails(null);
        setSaveCardForFutureUse(false);
    };

    useEffect(() => {
        if (
            (cardsFetching && !savedCards.length) ||
            (cardsFetching && deleted)
        ) {
            const delay = 3000; // Delay in milliseconds (3 seconds)
            const timeoutId = setTimeout(() => {
                getAllCards(setCardsFetching)
                    .then((resp) => {
                        setCardsFetching(false);
                        const _activeCard = resp?.paymentMethods?.find(
                            (card) => card.defaultPaymentMethod
                        );
                        if (_activeCard) {
                            setSelectedCard(_activeCard.stripePaymentMethodId);
                        }

                        if (resp?.paymentMethods.length) {
                            setSavedCards(resp?.paymentMethods);
                            localStorage.setItem(
                                "savedCards",
                                JSON.stringify(resp?.paymentMethods)
                            );
                        } else {
                            setSavedCards([]);
                            localStorage.removeItem("savedCards");
                        }
                        setShowSavedCards(true);
                        setIsLoadingCards(false);
                        setDeleted(false);
                    })
                    .catch(() => setIsLoadingCards(false));
            }, delay);

            return () => clearTimeout(timeoutId);
        } else {
            setIsLoadingCards(false);
            setShowSavedCards(true);
        }
    }, [cardsFetching, savedCards, deleted]);

    useEffect(() => {
        if (isSelected === "credit") {
            getCredits(clubId)
                .then((resp) => {
                    setUserCredit(resp);
                })
                .catch(() => {});
        }
    }, [clubId, isSelected, setUserCredit]);

    useEffect(() => {
        if (showCardPayment) {
            setSelectedCard(undefined);
        } else {
            clearFields();
        }
    }, [showCardPayment]);

    useEffect(() => {
        if (selectedCard) {
            setShowCardPayment(false);
        }
    }, [selectedCard]);

    useEffect(() => {
        if (isSelected === "card" && showCardPayment && stripe) {
            // const cardElement = elements.getElement(CardElement);
            // cardElementRef.current = cardElement;
        } else {
            cardElementRef.current = null;
        }
        handleCardElementSelect(cardElementRef.current);
        return () => {
            handleCardElementSelect(null);
        };
    }, [isSelected, showCardPayment]);

    const handleCardElementChange = (e) => {
        setCardDetails(e);
    };
    const handleSaveCardForFutureUseChange = (e) => {
        setSaveCardForFutureUse(!saveCardForFutureUse);
    };

    /* const handleSubmit = () => {
        if (!cardNumber || !expiryDate || !securityCode) {
            Store.addNotification({
                title: null,
                message: "All the fields are required",
                type: "danger",
                insert: "center",
                container: "bottom-center",
                color: "primary",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000
                }
            });
            return false;
        }
        showPopupInfoBox(
            "Card addition",
            "Please wait while we add your card..."
        );
        handleSaveCard(cardNumber, expiryDate, securityCode)
            .then((resp) => {
                if (resp?.success) {
                    Store.addNotification({
                        title: null,
                        message: "Card Added Successfully!",
                        type: "success",
                        insert: "center",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000
                        }
                    });
                    setCardNumber("");
                    setSecurityCode("");
                    setExpiryDate("");
                    setGetCards(!getCards);
                    setCardsFetching(true);
                    setDeleted(true);
                } else {
                    setIsLoadingCards(false);
                    Store.addNotification({
                        title: null,
                        message: "card not exist or wrong information",
                        type: "danger",
                        insert: "center",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000
                        }
                    });
                }
            })
            .catch(() => {
                setcardError("Error occured!");
            });
    }; */

    const toggleSelected = (newSelected) => {
        if (isSelected === newSelected) {
            setIsSelected("");
            setErrorMessage("");
        } else {
            setIsSelected(newSelected);
            setErrorMessage("");
        }
    };
    const toggleInfo = () => {
        setShowInfo(!showInfo);
    };

    const showPopupInfoBox = (title, subTitle) => {
        setInfoPopupTitle(title);
        setInfoPopupSubtitle(subTitle);
        setIsLoadingCards(true);
    };

    const handleDeleteCard = () => {
        const selectedPaymentCard = savedCards.find(
            (c) => c.stripePaymentMethodId === selectedCard
        );
        showPopupInfoBox(
            "Card removal",
            "Please wait while we remove your card..."
        );
        handleRemoveCard(selectedPaymentCard, savedCards).then((response) => {
            if (response?.data.success) {
                setIsOpen(!isOpen);
                Store.addNotification({
                    title: null,
                    message: "Card Removed Successfully!",
                    type: "success",
                    insert: "center",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000
                    }
                });
                setCardsFetching(true);
                setDeleted(true);
            } else {
                setIsLoadingCards(false);
                Store.addNotification({
                    title: null,
                    message: "error in deleting card!",
                    type: "danger",
                    insert: "center",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000
                    }
                });
            }
        });
    };

    return (
        <div className="py-5">
            <Popup
                onDelete={handleDeleteCard}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={"Delete Card"}
                message={"Are you sure you want to delete the selected card?"}
            />
            <PopupInfo
                title={infoPopupTitle}
                subTitle={infoPopupSubtitle}
                isOpen={isLoadingCards}
                setIsOpen={setIsLoadingCards}
                isLoading={true}
            />
            {!hidePaymentIcons ? (
                <>
                    <h6 className="text-lg font-medium">
                        {t("PaymentComponent.methods")}
                    </h6>
                    <div className="flex flex-wrap items-start gap-8 mt-5">
                        <div className="flex flex-col justify-center items-center gap-2 w-full max-w-fit">
                            <button
                                onClick={() => {
                                    toggleSelected("card");
                                    setShowCardPayment(true);
                                    setShowSavedCards(true);
                                }}
                                className={
                                    isSelected === "card"
                                        ? "bg-secondary text-white border-dashed border-2 border-[#6990fd] flex items-center justify-center rounded-full py-[14px] px-[14px] hover:opacity-80 transform duration-200"
                                        : "bg-[#ededed] text-secondary flex items-center justify-center rounded-full py-4 px-4 hover:bg-primary transform duration-200"
                                }
                            >
                                <FaCreditCard className="w-9 h-9" />
                            </button>
                            <p className="text-lg text-center">
                                {t("PaymentComponent.cards")}
                            </p>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-2 w-full max-w-fit">
                            <button
                                onClick={() => {
                                    toggleSelected("credit");
                                    setShowCardPayment(false);
                                    setShowSavedCards(false);
                                }}
                                className={
                                    isSelected === "credit"
                                        ? "bg-secondary text-white border-dashed border-2 border-[#6990fd] flex items-center justify-center rounded-full py-[14px] px-[14px] hover:opacity-80 transform duration-200"
                                        : "bg-[#ededed] text-secondary flex items-center justify-center rounded-full py-4 px-4 hover:bg-primary transform duration-200"
                                }
                            >
                                <BsFillDatabaseFill className="w-9 h-9" />
                            </button>
                            <p className="text-lg text-center">
                                {t("PaymentComponent.credits")}
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}

            {isSelected === "card" && (
                <div>
                    {isSelected === "card" && showCardPayment && stripe && (
                        <div className="flex flex-col mt-6 w-full">
                            <div className="w-full md:max-w-[585px] h-full flex items-center mb-6">
                                <div className="border-2 border-[#00000033] rounded-[8px] p-3 text-lg w-full md:max-w-[585px] outline-primary h-[50px] flex items-center">
                                    <CardElement
                                        className="w-full"
                                        onChange={handleCardElementChange}
                                        options={{ hidePostalCode: true }}
                                        onReady={(element) => {
                                            cardElementRef.current = element;
                                            handleCardElementSelect(
                                                cardElementRef.current
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex align-middle gap-1.5 mt-2">
                                <input
                                    type="checkbox"
                                    checked={saveCardForFutureUse}
                                    name="saveCardDetails"
                                    id="saveCardDetails"
                                    onChange={handleSaveCardForFutureUseChange}
                                />
                                <label htmlFor="saveCardDetails">
                                    Save card details for future use.
                                </label>
                            </div>
                            <br />
                            <div className="flex md:justify-start justify-center gap-2 items-center">
                                {/* <button
                                    className="text-base outline-none bg-white border border-secondary py-1 text-secondary px-2 rounded-lg w-[150px] hover:bg-primary hover:border-none transform duration-200"
                                    onClick={() => handleSubmit()}
                                >
                                    {t("PaymentComponent.save")}
                                </button> */}
                                <button
                                    className="text-base outline-none bg-white border border-secondary py-1 text-secondary px-2 rounded-lg w-[150px] hover:bg-red-500 hover:text-white hover:border-none transform duration-200"
                                    onClick={() => setShowCardPayment(false)}
                                >
                                    {t("PaymentComponent.cancel")}
                                </button>
                            </div>
                        </div>
                    )}
                    {isSelected === "card" &&
                    showSavedCards &&
                    savedCards &&
                    savedCards.length ? (
                        <div className="flex flex-col mt-5 mb-3">
                            <h6 className="text-lg">
                                {t("PaymentComponent.select")}
                            </h6>
                            <div className="flex flex-col mt-3">
                                {savedCards?.map((card) => (
                                    <div
                                        className={`border-y flex gap-3 flex-wrap justify-between items-center py-1 px-5 hover:cursor-pointer hover:transform hover:scale-[1.02] duration-200 ease-in-out ${
                                            selectedCard ===
                                            card.stripePaymentMethodId
                                                ? "border-black"
                                                : "border-gray-300"
                                        }`}
                                        key={card.stripePaymentMethodId}
                                        onClick={() => {
                                            setSelectedCard(
                                                card.stripePaymentMethodId
                                            );
                                        }}
                                    >
                                        <div className="flex items-center gap-3">
                                            <div>
                                                {selectedCard ===
                                                card.stripePaymentMethodId ? (
                                                    <BsCheckCircleFill />
                                                ) : (
                                                    <BsCheckCircle />
                                                )}
                                            </div>
                                            <div className="flex items-center gap-1 flex-wrap">
                                                <h6>{card.brand}</h6>
                                                <p>
                                                    ( . . . . {card.last4Digit}{" "}
                                                    )
                                                </p>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                className="border border-black bg-white rounded-full text-sm py-1 px-4 hover:bg-secondary hover:text-white transform duration-200 ease-in-out"
                                                onClick={() =>
                                                    setIsOpen(!isOpen)
                                                }
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : isLoadingCards ? (
                        <h1 className="text-center h-full flex justify-center items-center mt-5">
                            {t("PaymentComponent.fetch")}...
                        </h1>
                    ) : (
                        <h1 className="text-center h-full flex justify-center items-center mt-5">
                            {t("PaymentComponent.notFound")}
                        </h1>
                    )}
                    {isSelected === "card" && !showCardPayment && (
                        <div className="flex justify-center items-center">
                            <button
                                className="text-base outline-none bg-secondary border border-secondary py-1 text-white px-2 rounded-lg w-[150px] mt-5 hover:bg-primary hover:border-primary hover:text-secondary transform duration-200"
                                onClick={() => setShowCardPayment(true)}
                            >
                                {t("PaymentComponent.addCard")}
                            </button>
                        </div>
                    )}
                </div>
            )}
            {isSelected === "credit" && (
                <div className="bg-[#FBFBFB] shadow rounded-2xl px-3 py-6 mt-4">
                    <div className="flex justify-between items-center font-semibold">
                        {t("PaymentComponent.available")}
                        <span className="flex items-center gap-2 font-bold text-xl">
                            {userCredit}{" "}
                            {currency.length > 0 ? (
                                currency
                            ) : (
                                <FaEuroSign className="w-5 h-5" />
                            )}
                        </span>
                    </div>
                    <div className="mt-4 border-2 border-gray-300 rounded-2xl px-3 py-4 text-gray-500 text-center">
                        {userCredit < price ? (
                            <div>{t("PaymentComponent.isEnough")}</div>
                        ) : (
                            <div>
                                {t("PaymentComponent.amount")}: {price}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PaymentComponent;
