import React, { Dispatch, SetStateAction } from "react";
import { AiFillPlusCircle } from "react-icons/ai";

const AddCardDetails = ({
    setShowComponent
}: {
    setShowComponent: Dispatch<SetStateAction<string>>;
}) => {
    return (
        <div>
            <h2 className="text-2xl font-semibold text-center">
                Add Credit Card
            </h2>
            <div className="my-4 bg-[#000b2926] w-full h-[1px]" />
            <div className="max-w-[752px] mx-auto">
                <p>
                    If you haven't saved any credit cards, you'll need to enter
                    your credit card number each time you make a payment. But,
                    with our secure payment service, you can save your reference
                    ID, allowing for quick and simple payments in the future
                    without having to store your credit card number anywhere.
                </p>
                <p className="mt-8">
                    To get started with our easy payment process, simply click
                    "Save my information" at your next booking. Alternatively,
                    you can add a credit card now to be used for your next
                    purchase.
                </p>
            </div>
            <div className="flex justify-center mt-[70px] mb-14">
                <button
                    onClick={() => {
                        setShowComponent("NewCard");
                    }}
                    className="h-12 w-full max-w-[240px] bg-secondary text-white font-semibold rounded-full flex items-center justify-center gap-2"
                >
                    Add New Credit Card
                    <AiFillPlusCircle className="w-5 h-5 text-white" />
                </button>
            </div>
        </div>
    );
};

export default AddCardDetails;
