import React from "react";
import { useTranslation } from "react-i18next";

export const ComponentHomeClubStatistics = () => {
    const { t } = useTranslation();

    return (
        <div className="text-center px-4 md:px-16 py-20 bg-white">
            <h1 className="text-center mx-auto text-3xl lg:text-[45px] lg:leading-[55px] font-semibold max-w-[850px]">
                {t("HomeClubStats.title")}
            </h1>
            <div className="flex flex-col lg:flex-row gap-10 lg:gap-0 justify-center items-center mt-20 w-full max-w-fit mx-auto">
                <div className="w-full lg:w-[calc(50%-20px)] max-w-fit mx-auto lg:mx-0">
                    <div className="flex flex-col-reverse sm:flex-row gap-5 sm:gap-0 w-full max-w-fit items-center justify-end">
                        <div className="flex flex-col justify-center items-center">
                            <img
                                src="/images/club/Pointyline.svg"
                                className="z-10 -mt-14 sm:-mt-0 lg:mt-20 xl:mt-0 sm:ml-auto sm:-mr-20 max-w-[100px] xl:max-w-fit"
                                alt="left-pointer"
                            />
                            <div className="sm:max-w-[275px]">
                                <h3 className="text-[22px] font-medium text-secondary">
                                    {t("HomeClubStats.FirstPoint.title")}
                                </h3>
                                <p className="text-[15px] mt-1 font-light text-[#000b29cc]">
                                    {t("HomeClubStats.FirstPoint.p-1")}
                                </p>
                            </div>
                        </div>
                        <img
                            className="w-full max-w-fit sm:w-[50%] lg:w-[40%] xl:w-[50%]"
                            src="/images/club/profitphone1.png"
                            alt="phone"
                        />
                    </div>
                </div>
                <div className="w-full lg:w-[calc(50%-20px)] max-w-fit mx-auto lg:mx-0">
                    <div className="flex flex-col sm:flex-row gap-5 sm:gap-0 w-full max-w-fit items-center justify-start">
                        <img
                            className="w-full max-w-fit sm:w-[50%] lg:w-[40%] xl:w-[50%]"
                            src="/images/club/profitphone2.png"
                            alt="phone"
                        />
                        <div className="flex flex-col justify-center items-center">
                            <img
                                src="/images/club/Pointyline.svg"
                                className="z-10 -mt-14 sm:-mt-0 lg:mt-20 xl:mt-0 sm:mr-auto sm:-ml-20 max-w-[100px] xl:max-w-fit scale-x-[-1]"
                                alt="left-pointer"
                            />
                            <div className="sm:max-w-[281px]">
                                <h3 className="text-[22px] font-medium text-secondary">
                                    {t("HomeClubStats.SecondPoint.title")}
                                </h3>
                                <p className="text-[15px] mt-1 font-light text-[#000B29CC]">
                                    {t("HomeClubStats.SecondPoint.p-1")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
