import { forwardRef } from "react";
import ComponentCentreCard from "./ComponentCentreCard";
import { ICentre } from "./interface";
import ComponentPagination from "../ComponentPagination";
import { useTranslation } from "react-i18next";

const ComponentCentresList = forwardRef(
    (
        {
            data = [],
            currentUser,
            totalItems = 0,
            itemsPerPage = 10,
            onPageChange
        }: {
            data: ICentre[];
            currentUser?: any;
            totalItems?: number;
            itemsPerPage?: number;
            onPageChange: (pageNumber: number) => void;
        },
        ref
    ) => {
        const { t } = useTranslation();

        return (
            <div className="w-full">
                <div className="">
                    {data.length ? (
                        data.map((centre) => (
                            <ComponentCentreCard
                                data={centre}
                                key={centre["_id"]}
                                currentUser={currentUser}
                            />
                        ))
                    ) : (
                        <div className="">
                            <div className="text-center text-slate-950 text-opacity-80 text-[22px] font-normal leading-tight">
                                {t("FindCentres.not_found")}!
                            </div>
                        </div>
                    )}
                </div>
                {totalItems > itemsPerPage ? (
                    <div className="mt-16 hidden">
                        <ComponentPagination
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            onPageChange={onPageChange}
                            ref={ref}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
);
export default ComponentCentresList;
