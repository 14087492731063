import React from "react";
import { ComponentHomeClubConnectForm } from "./ComponentHomeClubConnectForm";
import { useTranslation } from "react-i18next";

export const ComponentHomeClubConnect = () => {
    const { t } = useTranslation();

    return (
        <div className="py-20 px-4 md:px-16 flex flex-wrap w-full max-w-[1412px] mx-auto gap-8 justify-between text-secondary">
            <div className="w-full lg:w-[calc(50%-16px)] lg:max-w-[600px] mt-6">
                <h3 className="text-3xl xl:text-4xl 2xl:text-[45px] 2xl:leading-[55px] font-semibold">
                    {t("HomeClubConnect.title")}
                </h3>
                <p className="mt-6 text-[22px] text-[#000b29cc]">
                    {t("HomeClubConnect.p-1")}
                </p>
            </div>
            <div className="w-full lg:w-[calc(50%-16px)]">
                <ComponentHomeClubConnectForm />
            </div>
        </div>
    );
};
