import moment, { Moment } from "moment-timezone";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";

const SelectDate = ({
    dayCount,
    selectedDate,
    timezone,
    setSelectedDate,
    onDateClick,
    loading,
}: {
    dayCount: number;
    selectedDate: any;
    timezone?: any;
    setSelectedDate: Dispatch<SetStateAction<any>>;
    onDateClick: () => void;
    loading?: boolean;
}) => {
    const currentDate = timezone ? moment.tz(moment(), timezone) : moment();
    const maxDate = currentDate.clone().add(11, "days");

    const [dateArray, setDateArray] = useState<Date[]>([]);

    useEffect(() => {
        const generateDateArray = () => {
            const tempDateArray: Date[] = [];
            for (
                let date = currentDate.clone();
                date.isBefore(currentDate.clone().add(dayCount, "days"));
                date.add(1, "day")
            ) {
                tempDateArray.push(date.toDate());
            }
            setDateArray(tempDateArray);
        };

        generateDateArray();
    }, []);

    const handleNextDayClick = () => {
        if (loading) {
            return;
        }
        const nextDate = selectedDate.clone().add(1, "days");
        if (nextDate.isBefore(maxDate)) {
            setSelectedDate(nextDate);
            if (
                nextDate.isBefore(currentDate.clone().add(dayCount - 2, "days"))
            ) {
                const tempDateArray: Date[] = [];
                for (
                    let date = nextDate.clone();
                    date.isBefore(nextDate.clone().add(dayCount, "days"));
                    date.add(1, "day")
                ) {
                    tempDateArray.push(date.toDate());
                }
                setDateArray(tempDateArray);
            } else if (
                nextDate.isSameOrAfter(
                    currentDate.clone().add(dayCount - 2, "days")
                )
            ) {
                const tempDateArray: Date[] = [];
                for (
                    let date = currentDate.clone().add(dayCount - 2, "days");
                    date.isBefore(maxDate.clone().add(1, "days"));
                    date.add(1, "day")
                ) {
                    tempDateArray.push(date.toDate());
                }
                setDateArray(tempDateArray);
            }
        }
    };

    const handlePreviousDayClick = () => {
        if (loading) {
            return;
        }
        const previousDate = selectedDate.clone().subtract(1, "days");
        if (previousDate.isBefore(currentDate, "day")) {
            setSelectedDate(currentDate); // Set selectedDate to today's date
        } else if (
            previousDate.isSameOrAfter(
                currentDate.clone().subtract(1, "days")
            ) &&
            moment(previousDate).isBefore(
                maxDate.clone().subtract(dayCount, "days")
            )
        ) {
            setSelectedDate(previousDate);
            const tempDateArray: Date[] = [];
            for (
                let date = previousDate.clone();
                date.isBefore(previousDate.clone().add(dayCount, "days"));
                date.add(1, "day")
            ) {
                tempDateArray.push(date.toDate());
            }
            setDateArray(tempDateArray);
        } else {
            setSelectedDate(previousDate);
        }
    };

    const isDateMatched = (date: Moment) => {
        return date.isSame(selectedDate, "day");
    };

    return (
        <>
            <div className="flex flex-wrap gap-1 items-center justify-between w-full max-w-[350px] mx-auto mt-9 xl:px-4">
                <div
                    onClick={handlePreviousDayClick}
                    className={`${
                        selectedDate?.format("D MMMM, YYYY") ===
                        currentDate?.format("D MMMM, YYYY")
                            ? "bg-gray-400"
                            : "bg-secondary hover:bg-primary hover:text-secondary transform duration-200 group"
                    } rounded-full p-1 sm:p-1.5 cursor-pointer`}
                >
                    <BiSolidChevronLeft className="w-6 h-6 text-white group-hover:text-secondary" />
                </div>
                <p className="text-lg sm:text-xl underline">
                    {selectedDate?.format("D MMMM, YYYY")}
                </p>
                <div
                    onClick={handleNextDayClick}
                    className={`${
                        selectedDate?.format("D MMMM, YYYY") ===
                        currentDate
                            ?.clone()
                            .add(13, "days")
                            .format("D MMMM, YYYY")
                            ? "bg-gray-400"
                            : "bg-secondary hover:bg-primary hover:text-secondary transform duration-200 group"
                    } rounded-full p-1 sm:p-1.5 cursor-pointer`}
                >
                    <BiSolidChevronRight className="w-6 h-6 text-white group-hover:text-secondary" />
                </div>
            </div>
            <div className="flex flex-wrap gap-[10px] mt-8 max-w-fit mx-auto">
                {dateArray.map((date, index) => {
                    return (
                        <button
                            key={index}
                            onClick={() => {
                                if (
                                    moment(date).isBefore(
                                        maxDate.clone().subtract(1, "days")
                                    ) &&
                                    index === dayCount - 1
                                ) {
                                    const newDateArray = dateArray.slice(1); // Remove the first date
                                    const nextDate = moment(
                                        dateArray[dateArray.length - 1]
                                    )
                                        .clone()
                                        .add(1, "days"); // Calculate the next date
                                    newDateArray.push(nextDate.toDate()); // Add the next date at the end
                                    setDateArray(newDateArray);
                                    setSelectedDate(timezone ? moment.tz(date, timezone): moment(date));
                                } else if (
                                    moment(date).isBefore(maxDate) &&
                                    index === 0 &&
                                    moment(date).isAfter(currentDate)
                                ) {
                                    const newDateArray = dateArray.slice(
                                        0,
                                        dateArray.length - 1
                                    ); // Remove the last date
                                    const prevDate = moment(dateArray[0])
                                        .clone()
                                        .subtract(1, "days"); // Calculate the previous date
                                    newDateArray.unshift(prevDate.toDate()); // Add the previous date at the beginning
                                    setDateArray(newDateArray);
                                    setSelectedDate(timezone ? moment.tz(date, timezone): moment(date));
                                } else setSelectedDate(timezone ? moment.tz(date, timezone): moment(date));
                            }}
                            className={
                                isDateMatched(moment(date))
                                    ? "bg-secondary text-white border-dashed border-[3px] border-[#6990fd] flex flex-col items-center justify-center rounded-full w-[50px] h-[50px] p-1 hover:opacity-80 transform duration-200"
                                    : "bg-[#ededed] text-secondary border-dashed border-[3px] border-transparent flex flex-col items-center justify-center rounded-full w-[50px] h-[50px] p-1 hover:bg-primary hover:text-secondary transform duration-200"
                            }
                        >
                            <span className="text-[13px] uppercase">
                                {moment(date).format("ddd")}
                            </span>
                            <span className="text-[9px] uppercase">
                                {moment(date).format("DD MMM")}
                            </span>
                        </button>
                    );
                })}
            </div>
        </>
    );
};

export default SelectDate;
