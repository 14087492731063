import { useEffect, useState } from "react";
import { AiOutlineCreditCard, AiOutlineLock } from "react-icons/ai";
import { BsCheckCircle, BsCircle, BsInfoCircle } from "react-icons/bs";
import { Store } from "react-notifications-component";
import {
    ICard,
    getAllCards,
    handleRemoveCard,
    handleSaveCard
} from "../../Api/api";
import Popup from "../booking/Popup";
import PopupInfo from "../booking/PopupInfo";
import AddCardDetails from "./AddCardDetails";

const cardsIcons: any = {
    mastercard: "/images/Mastercard.svg",
    unionpay: "/images/unionpay.svg",
    visa: "/images/visa.svg",
    amex: "/images/amex.svg"
};

const NewCard = () => {
    const [selectedCard, setSelectedCard] = useState<string | any>("");
    const [showComponent, setShowComponent] = useState("addCard");
    const [showInfo, setShowInfo] = useState(false);
    const [cardNumber, setCardNumber] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [securityCode, setSecurityCode] = useState("");
    const [savedCards, setSavedCards] = useState([]);
    const [isLoadingCards, setIsLoadingCards] = useState(true);
    const [infoPopupTitle, setInfoPopupTitle] = useState("");
    const [infoPopupSubtitle, setInfoPopupSubtitle] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [cardsFetching, setCardsFetching] = useState(false);

    const toggleInfo = () => {
        setShowInfo(!showInfo);
    };

    useEffect(() => {
        if (!cardsFetching) {
            setTimeout(() => {
                handleGetSavedCards();
            }, 2000);
        }
    }, [cardsFetching]);

    const handleGetSavedCards = () => {
        setIsLoadingCards(true);
        getAllCards(setCardsFetching)
            .then((resp) => {
                const _activeCard = resp?.paymentMethods?.find(
                    (card: ICard) => card.defaultPaymentMethod
                );
                if (_activeCard) {
                    setSelectedCard(_activeCard.stripePaymentMethodId);
                }

                if (resp?.paymentMethods.length) {
                    setSavedCards(resp?.paymentMethods);

                    setShowComponent("NewCard");
                } else {
                    setSavedCards([]);
                    setShowComponent("AddCard");
                }
                setIsLoadingCards(false);
            })
            .catch(() => setIsLoadingCards(false));
    };

    const handleCardNumberChange = (e: any) => {
        setCardNumber(e.target.value);
    };

    const handleExpiryDateChange = (e: any) => {
        setExpiryDate(e.target.value);
    };

    const handleSecurityCodeChange = (e: any) => {
        if (e.target.value.length < 4) {
            setSecurityCode(e.target.value);
        } else {
            Store.addNotification({
                title: null,
                message: "not allowed",
                type: "danger",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000
                }
            });
        }
    };

    const handleSubmit = () => {
        if (!cardNumber || !expiryDate || !securityCode) {
            Store.addNotification({
                title: null,
                message: "All the fields are required",
                type: "danger",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000
                }
            });
            return false;
        }
        showPopupInfoBox(
            "Card addition",
            "Please wait while we add your card..."
        );
        handleSaveCard(cardNumber, expiryDate, securityCode)
            .then((resp) => {
                if (resp?.success) {
                    Store.addNotification({
                        title: null,
                        message: "Card Added Successfully!",
                        type: "success",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000
                        }
                    });
                    setCardNumber("");
                    setSecurityCode("");
                    setExpiryDate("");
                    setIsLoadingCards(false);
                    handleGetSavedCards();
                } else {
                    Store.addNotification({
                        title: null,
                        message: "card not exist or wrong information",
                        type: "danger",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000
                        }
                    });
                    setIsLoadingCards(false);
                }
            })
            .catch(() => {});
    };

    const showPopupInfoBox = (title: string, subTitle: string) => {
        setInfoPopupTitle(title);
        setInfoPopupSubtitle(subTitle);
        setIsLoadingCards(true);
    };

    const handleDeleteCard = () => {
        const selectedPaymentCard: ICard | any = savedCards.find(
            (c: any) => c.stripePaymentMethodId === selectedCard
        );
        showPopupInfoBox(
            "Card removal",
            "Please wait while we remove your card..."
        );
        handleRemoveCard(selectedPaymentCard, savedCards).then((response) => {
            if (response?.data.success) {
                Store.addNotification({
                    title: "Success",
                    message: "Card Deleted Successfully!",
                    type: "success",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000
                    }
                });
                setTimeout(() => {
                    handleGetSavedCards();
                    setIsOpen(!isOpen);
                    setIsLoadingCards(false);
                }, 2000);
            } else {
                setIsLoadingCards(false);
                Store.addNotification({
                    title: null,
                    message: "error in deleting card!",
                    type: "danger",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000
                    }
                });
            }
        });
    };

    return (
        <>
            <Popup
                onDelete={handleDeleteCard}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={"Delete Card"}
                message={"Are you sure you want to delete the selected card?"}
            />
            <PopupInfo
                title={infoPopupTitle}
                subTitle={infoPopupSubtitle}
                isOpen={isLoadingCards}
                setIsOpen={setIsLoadingCards}
                isLoading={true}
            />
            {showComponent === "AddCard" ? (
                <AddCardDetails setShowComponent={setShowComponent} />
            ) : (
                <div className="sm:px-3 pb-[7px]">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
                        <div className="flex flex-col w-full max-w-[350px]">
                            <h2 className="text-2xl font-semibold text-left mb-[30px]">
                                Saved credit cards
                            </h2>
                            {/* <div className="h-[.5px] w-full bg-[#00000021]" /> */}
                            {savedCards.length ? (
                                savedCards?.map((card: ICard) => (
                                    <div
                                        key={card.stripePaymentMethodId}
                                        onClick={() => {
                                            setSelectedCard(
                                                card.stripePaymentMethodId
                                            );
                                        }}
                                        className="flex items-center flex-wrap gap-5 justify-between py-3 border-y border-[#00000021] hover:cursor-pointer hover:transform hover:scale-[1.02] duration-200 ease-in-out"
                                    >
                                        <div className="flex items-center flex-wrap gap-5">
                                            <div className="flex items-center flex-wrap gap-[10px]">
                                                {selectedCard ===
                                                card.stripePaymentMethodId ? (
                                                    <BsCheckCircle className="w-7 h-7" />
                                                ) : (
                                                    <BsCircle className="w-7 h-7" />
                                                )}
                                                {card.brand &&
                                                cardsIcons[card.brand] ? (
                                                    <img
                                                        src={
                                                            cardsIcons[
                                                                card.brand
                                                            ]
                                                        }
                                                        className="w-10 h-[26px]"
                                                        alt="card icon"
                                                    />
                                                ) : (
                                                    <AiOutlineCreditCard className="w-10 h-[26px]" />
                                                )}
                                            </div>
                                            <p className="text-xs text-left uppercase">
                                                {card.brand}
                                                <br />
                                                (•••• {card.last4Digit})
                                            </p>
                                        </div>
                                        <button
                                            className="min-h-[30px] min-w-[69px] rounded-full font-medium border border-secondary hover:bg-secondary hover:text-white transform text-[10px] duration-200 ease-in-out"
                                            onClick={() => setIsOpen(!isOpen)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <div className="text-left">
                                    No saved cards found
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-full max-w-[350px]">
                            <h2 className="text-2xl font-semibold text-left mb-[30px]">
                                Add new credit card
                            </h2>
                            <div className="flex flex-col w-full">
                                <div className="w-full h-full flex items-center mb-2">
                                    <input
                                        className="border border-[#00000033] rounded-[8px] p-3 text-xs w-full md:max-w-[350px] outline-primary h-[44px] flex items-center"
                                        type="tel"
                                        inputMode="numeric"
                                        pattern="[0-9\s]{13,16}"
                                        autoComplete="cc-number"
                                        maxLength={16}
                                        placeholder={"Card number"}
                                        value={cardNumber}
                                        onChange={handleCardNumberChange}
                                    />
                                    <span className="">
                                        <AiOutlineLock className="text-secondary w-3.5 h-3.5 -ml-6" />
                                    </span>
                                </div>
                                <div className="flex gap-2 flex-col sm:flex-row">
                                    <input
                                        className="sm:max-w-[171px] w-full border border-[#00000033] p-3 text-xs h-[44px] rounded-[8px] outline-primary"
                                        type="month"
                                        placeholder="Expiry date"
                                        value={expiryDate}
                                        onChange={handleExpiryDateChange}
                                    />
                                    <div className="flex items-center w-full sm:max-w-[171px] h-full z-10">
                                        <input
                                            className="sm:max-w-[171px] w-full border border-[#00000033] pl-3 pr-7 text-xs h-[44px] rounded-[8px] outline-primary"
                                            type="tel"
                                            pattern="[0-9\s]{3,4}"
                                            placeholder="Security code"
                                            minLength={3}
                                            maxLength={3}
                                            value={securityCode}
                                            onChange={handleSecurityCodeChange}
                                        />
                                        <div className="relative isolate">
                                            <button
                                                className="outline-none -ml-9"
                                                onClick={() => {
                                                    toggleInfo();
                                                }}
                                            >
                                                <BsInfoCircle className="w-3.5 h-3.5" />
                                            </button>
                                            {showInfo && (
                                                <div className="absolute z-10 bg-[#c4d0ef] rounded text-secondary text-xs 2xl:-left-9 right-3 top-10 p-2 w-[180px] after:absolute after:-top-2 after:left-[calc(92%-10px)] 2xl:after:left-[calc(8%-10px)] after:-z-10 after:h-5 after:w-5 after:-rotate-45 after:bg-[#c4d0ef] after:content-['']">
                                                    <p
                                                        onClick={() =>
                                                            setShowInfo(
                                                                !showInfo
                                                            )
                                                        }
                                                    >
                                                        The security code, or
                                                        CVV, refers to the extra
                                                        3 numbers on the back or
                                                        front of your card.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-[21px]">
                                    <button
                                        className="text-sm font-semibold outline-none rounded-full bg-secondary text-white hover:bg-primary hover:text-secondary transform duration-200 h-[49px] w-full max-w-[350px]"
                                        onClick={() => handleSubmit()}
                                    >
                                        Save credit card
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default NewCard;
