import React, { useState, useEffect, useRef } from "react";
import Axios from "../Api/Axios";
import { useTranslation } from "react-i18next";
import { ComponentPaymentHistoryCardRender } from "./ComponentPaymentHistoryCardRender";
import ComponentPagination from "./ComponentPagination";
import Loader from "./ui/Loader";

export const ComponentPaymentHistoryCard = () => {
    const [payHistory, setPayHistory] = useState([]);
    const [filteredPayHistory, setFilteredPayHistory] = useState(
        payHistory.slice()
    );
    const [currentPage, setCurrentPage] = useState(0);
    const [currentCards, setCurrentCards] = useState([]);
    const [selectValue, setSelectValue] = useState("All");
    const [loading, setLoading] = useState(false);
    const cardsPerPage = 10;
    const currentDate = new Date();
    const { t } = useTranslation();
    const paginationRef = useRef(null);

    function filterReceiptsByDate(receipt) {
        const timestamp = Date.parse(receipt.dateOfPayment.replace(/at/g, ""));
        switch (selectValue) {
            case "All":
                return true;
            case "Today":
                return isSameDay(timestamp);
            case "Week":
                return isSameWeek(timestamp);
            case "Month":
                return isSameMonth(timestamp);
            case "Year":
                return isSameYear(timestamp);
            default:
                return false;
        }
    }

    function isSameDay(timestamp) {
        const receiptDate = new Date(timestamp);
        return receiptDate.toDateString() === currentDate.toDateString();
    }

    function isSameWeek(timestamp) {
        const receiptDate = new Date(timestamp);
        const currentWeek = getWeekNumber(currentDate);
        const receiptWeek = getWeekNumber(receiptDate);
        return (
            receiptWeek[0] === currentWeek[0] &&
            receiptWeek[1] === currentWeek[1]
        );
    }

    function getWeekNumber(date) {
        const d = new Date(date);
        const dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        return [
            d.getUTCFullYear(),
            Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
        ];
    }

    function isSameMonth(timestamp) {
        const receiptDate = new Date(timestamp);
        return (
            receiptDate.getMonth() === currentDate.getMonth() &&
            receiptDate.getFullYear() === currentDate.getFullYear()
        );
    }

    function isSameYear(timestamp) {
        const receiptDate = new Date(timestamp);
        return receiptDate.getFullYear() === currentDate.getFullYear();
    }

    useEffect(() => {
        setLoading(true);
        Axios.get("payment/receipts").then((res) => {
            setLoading(false);
            setPayHistory(res?.data?.receipts?.reverse());
        });
    }, []);

    useEffect(() => {
        const filterdHistory = payHistory.filter(filterReceiptsByDate);
        setFilteredPayHistory(filterdHistory);
    }, [payHistory, selectValue]);

    useEffect(() => {
        if (filteredPayHistory.length) {
            const currentCardsLength = currentPage * cardsPerPage,
                sliceUpto = currentCardsLength + cardsPerPage;
            setCurrentCards(
                filteredPayHistory.slice(currentCardsLength, sliceUpto)
            );
        } else {
            setCurrentCards([]);
        }
    }, [filteredPayHistory, currentPage]);

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSelect = (valueOfSelected) => {
        setSelectValue(valueOfSelected);
        setCurrentPage(0);
        paginationRef.current?.resetPagination();
    };

    return (
        <div>
            <div className="flex justify-end sm:flex-row flex-col sm:flex">
                <form action="#">
                    <div className="border-2 min-w-[131px] cursor-pointer mt-2 border-secondary overflow-hidden rounded-[50px] hover:border-primary focus:border-primary">
                        <select
                            name="Payment-Date-Select"
                            id="Payment-Date-Select"
                            value={selectValue}
                            onChange={(e) => handleSelect(e.target.value)}
                            className="w-full outline-none bg-white appearance-none cursor-pointer px-4 py-2"
                            style={{
                                background:
                                    "url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%"
                            }}
                        >
                            <option default value="All">
                                {t("PaymentHistoryCard.all")}
                            </option>
                            <option value="Today">
                                {t("PaymentHistoryCard.today")}
                            </option>
                            <option value="Week">
                                {t("PaymentHistoryCard.week")}
                            </option>
                            <option value="Month">
                                {t("PaymentHistoryCard.month")}
                            </option>
                            <option value="Year">
                                {t("PaymentHistoryCard.year")}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
            <div className="flex w-full">
                <div className="w-full overflow-x-auto mt-8">
                    <div className="inline-block min-w-full">
                        <div className="overflow-hidden">
                            <div className="flex gap-[15%] justify-evenly md:text-[20px] text-[#000B2980]">
                                <div className="w-[400px]">
                                    <h3>{t("PaymentHistoryCard.desc")}</h3>
                                </div>
                                <div className="w-[400px]">
                                    <h3>{t("PaymentHistoryCard.date")}</h3>
                                </div>
                                <div className="w-[400px]">
                                    <h3>{t("PaymentHistoryCard.download")}</h3>
                                </div>
                            </div>
                            {loading ? (
                                <Loader />
                            ) : (
                                <div className="overflow-x-auto">
                                    <ComponentPaymentHistoryCardRender
                                        currentCards={currentCards}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-6">
                <ComponentPagination
                    onPageChange={onPageChange}
                    totalItems={filteredPayHistory.length}
                    itemsPerPage={cardsPerPage}
                    ref={paginationRef}
                />
            </div>
        </div>
    );
};
