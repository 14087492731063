import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from "react";
import { useTranslation } from "react-i18next";
import TournamentActivityCard from "../clubProfile/TournamentActivityCard";
import DownloadAppPopup from "../DownloadAppPopup";
import MatchActivityCard from "../clubProfile/MatchActivityCard";
import SelectDate from "../clubProfile/SelectDate";
import moment from "moment-timezone";
import ActivityTab from "../clubProfile/ActivityTab";
import ComponentPagination from "../ComponentPagination";

const ParticipateInActivities = ({
    allClubActivities,
    clubActivities,
    loading,
    setClubActivities,
    date,
    setDate
}: {
    allClubActivities: any;
    clubActivities: any;
    loading: boolean;
    setClubActivities: Dispatch<SetStateAction<any>>;
    date: any;
    setDate: Dispatch<SetStateAction<any>>;
}) => {
    // const [selectedDate, setSelectedDate] = useState<Moment>(moment());
    const { t } = useTranslation();
    const [activityType, setActivityType] = useState(`${t("Profile.all")}`);

    const [isOpenAppDownloadModal, setIsOpenAppDownloadModal] = useState(false);

    const itemsPerPage = 10;
    const paginationRef = useRef<any>(null);

    const [currentPage, setCurrentPage] = useState(0);

    const [currentClubActivities, setCurrentClubActivities] = useState([]);

    function closeAppDownloadModal() {
        setIsOpenAppDownloadModal(false);
    }

    const onDateClick = () => {
        if (activityType === t("Profile.tournaments")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { start_datetime: number; activity: string }) =>
                        moment(item.start_datetime).isSame(date, "day") &&
                        item.activity === "Tournament"
                )
            );
        } else if (activityType === t("Profile.training")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { start_datetime: number; activity: string }) =>
                        moment(item.start_datetime).isSame(date, "day") &&
                        item.activity === "Training"
                )
            );
        } else if (activityType === t("Profile.matches")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { start_datetime: number; activity: string }) =>
                        moment(item.start_datetime).isSame(date, "day") &&
                        item.activity === "Match"
                )
            );
        } else if (activityType === t("Profile.all")) {
            setClubActivities(
                allClubActivities?.filter((item: { start_datetime: number }) =>
                    moment(item.start_datetime).isSame(date, "day")
                )
            );
        } else {
            console.log("No matching activityType found"); // Debugging statement
        }
        setCurrentPage(0);
        paginationRef.current?.resetPagination();
    };

    useEffect(() => {
        if(allClubActivities !== null && allClubActivities.length > 0) {
            setClubActivities(
                allClubActivities?.filter((item: { start_datetime: number }) =>
                    moment(item.start_datetime).isSame(date, "day")
                )
            );
        }
    }, [allClubActivities]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onDateClick();
    }, [date]);

    const filterActivity = (activity: string) => {
        if (activity === t("Profile.tournaments")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string; start_datetime: number }) =>
                        item.activity === "Tournament" &&
                        moment(item.start_datetime).isSame(date, "day")
                )
            );
        } else if (activity === t("Profile.training")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string; start_datetime: number }) =>
                        item.activity === "Training" &&
                        moment(item.start_datetime).isSame(date, "day")
                )
            );
        } else if (activity === t("Profile.matches")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string; start_datetime: number }) =>
                        item.activity === "Match" &&
                        moment(item.start_datetime).isSame(date, "day")
                )
            );
        } else {
            setClubActivities(
                allClubActivities?.filter((item: { start_datetime: number }) =>
                    moment(item.start_datetime).isSame(date, "day")
                )
            );
        }
    };

    useEffect(() => {
        if (clubActivities?.length) {
            const currentCards = currentPage * itemsPerPage,
                sliceUpto = currentCards + itemsPerPage;
            setCurrentClubActivities(
                clubActivities.slice(currentCards, sliceUpto)
            );
        } else {
            setCurrentClubActivities([]);
        }
    }, [currentPage, clubActivities]);

    const onPageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div className="pt-9 px-4 md:px-16">
            <h2 className="text-3xl text-center">
                Participate in the many activities our club has to offer.
            </h2>
            <div className="flex justify-center">
            <ActivityTab
                activityType={activityType}
                setActivityType={setActivityType}
                allClubActivities={allClubActivities}
                setClubActivities={setClubActivities}
                onActivityClick={filterActivity}
            />
            </div>
            <SelectDate
                selectedDate={date}
                setSelectedDate={setDate}
                dayCount={8}
                onDateClick={onDateClick}
            />

            {loading ? (
                <div className="w-full h-[100px] flex justify-center">
                    <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-secondary"></div>
                    </div>
                </div>
            ) : (
                <div className="mt-[60px] max-w-[1000px] mx-auto">
                    {currentClubActivities?.length > 0 &&
                        currentClubActivities?.map(
                            (clubActivity: any, index: any) => (
                                <div
                                    key={index}
                                    className="my-6 bg-[#FAFAFA] cursor-pointer"
                                    onClick={() =>
                                        setIsOpenAppDownloadModal(true)
                                    }
                                >
                                    {clubActivity?.activity === "Match" ? (
                                        <MatchActivityCard
                                            clubActivity={clubActivity}
                                            page="activities"
                                        />
                                    ) : (
                                        <TournamentActivityCard
                                            clubActivity={clubActivity}
                                            page="activities"
                                        />
                                    )}
                                </div>
                            )
                        )}
                </div>
            )}
            <div className="w-full h-[100px] flex justify-center">
                {clubActivities?.length === 0 && (
                    <p>{t("Activities.noActivities")}</p>
                )}
            </div>

            <div className="mb-10">
                <ComponentPagination
                    totalItems={clubActivities?.length}
                    itemsPerPage={itemsPerPage}
                    onPageChange={onPageChange}
                    ref={paginationRef}
                />
            </div>
            <DownloadAppPopup
                isOpenAppDownloadModal={isOpenAppDownloadModal}
                closeAppDownloadModal={closeAppDownloadModal}
            />
        </div>
    );
};

export default ParticipateInActivities;
