import { useContext, useEffect, useState } from "react";
import AddCard from "./AddCard";
import ProfileManagement from "./ProfileManagement";
import ChangePassword from "./ChangePassword";
import SubmitEmail from "./SubmitEmail";
import SuccessMessage from "./SuccessMessage";
import NewPassword from "./NewPassword";
import { ProfileData, getUserInfo, updateUserInfo } from "../../Api/api";
import { AuthContext } from "../AuthProvider";
import { ProfileFormData } from "./Types.types";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OnboardingForm from "./OnboardingForm";
import { Store, NOTIFICATION_TYPE } from "react-notifications-component";

const AccountSettingsMain = () => {
    const currentUser = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [showComponent, setShowComponent] = useState("ProfileManagement");
    const [profileData, setProfileData] = useState<ProfileData | undefined>();
    const [isProfileDataLoading, setIsProfileDataLoading] = useState(true);

    const saveProfile = (formData: ProfileFormData) => {
        setIsProfileDataLoading(true);
        updateUserInfo(formData).then((data) => {
            setIsProfileDataLoading(false);
            let type = "success",
                message = t("AccountSettings.profileSuccessMessage");
            if (!data?.success) {
                type = "danger";
                message = t("AccountSettings.profileErrorMessage");
            }
            Store.addNotification({
                title: null,
                message: message,
                type: type as NOTIFICATION_TYPE,
                insert: "bottom",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000
                }
            });
        });
    };
    useEffect(() => {
        if (currentUser) {
            if (!profileData) {
                getUserInfo().then((res) => {
                    setIsProfileDataLoading(false);
                    setProfileData(res.user);
                });
            }
        } else {
            const queryParams = new URLSearchParams();
            queryParams.set("page", `/account-settings`);
            const queryString = queryParams.toString();
            const url = `/sign-in?${queryString}`;
            navigate(url);
        }
    }, [currentUser]);

    return (
        <div className="text-secondary w-full">
            <div className="bg-[#FBFBFB] px-4 sm:px-0">
                <h1 className="lg:text-[45px] text-3xl font-semibold py-14 text-center">
                    {t("AccountSettings.pageHeading")}
                </h1>
            </div>
            <div className="flex flex-col gap-10 mt-10 mb-32 w-full max-w-[944px] mx-auto px-4 lg:px-0">
                {showComponent === "ProfileManagement" && (
                    <>
                        <div className="relative overflow-hidden">
                            {isProfileDataLoading ? (
                                <div
                                    className={
                                        "absolute z-10 top-0 left-0 h-full w-full flex items-center justify-center bg-white bg-opacity-95 "
                                    }
                                >
                                    <CgSpinner className="animate-spin text-[64px]" />
                                </div>
                            ) : null}
                            <ProfileManagement
                                userData={profileData}
                                setShowComponent={setShowComponent}
                                saveProfile={saveProfile}
                            />
                        </div>
                        <AddCard />
                        {/* <OnboardingForm /> */}
                    </>
                )}
                {showComponent === "ChangePassword" && (
                    <ChangePassword setShowComponent={setShowComponent} />
                )}
                {showComponent === "SubmitEmail" && (
                    <SubmitEmail setShowComponent={setShowComponent} />
                )}
                {showComponent === "NewPassword" && (
                    <NewPassword setShowComponent={setShowComponent} />
                )}
                {showComponent === "SuccessMessage" && <SuccessMessage />}
            </div>
        </div>
    );
};

export default AccountSettingsMain;
