import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../AuthProvider";
import { FaInfoCircle } from "react-icons/fa";

type UpdateUserInfoProps = {
    userName: string;
    userPhone: string;
    onBack: () => void;
    onSubmit: ({ name, phone }: { name: string; phone: string }) => void;
};

export default function UpdateUserInfo({
    userName,
    userPhone,
    onBack,
    onSubmit
}: UpdateUserInfoProps) {
    const { t } = useTranslation();

    const currentUser = useContext(AuthContext);

    const [name, setName] = useState(userName ?? "");
    const [phone, setPhoneNumber] = useState(userPhone ?? "");

    const [nameError, setNameError] = useState("");
    const [phoneError, setPhoneNumberError] = useState("");

    useEffect(() => {
        if (!phone.length) {
            setPhoneNumberError(`${t("BookingModal.phoneRequired")}!`);
        } else if (phone.trim().length < 10) {
            setPhoneNumberError(`${t("BookingModal.invalidPhoneNumber")}!`);
        } else {
            setPhoneNumberError('');
        }
    }, [phone]);

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        let isNameEmpty = !!!name.trim().length,
            isPhoneNumberEmpty = !!!phone.trim().length;

        if (isNameEmpty || isPhoneNumberEmpty) {
            if (isNameEmpty) {
                setNameError(`${t("BookingModal.nameRequired")}!`);
            }
            if (isPhoneNumberEmpty) {
                setPhoneNumberError(`${t("BookingModal.phoneRequired")}!`);
            }
            return false;
        } else {
            if (phone.trim().length < 10) {
                setPhoneNumberError(`${t("BookingModal.invalidPhoneNumber")}!`);
            } else {
                onSubmit({ name, phone });
            }
        }
    };
    return (
        <div>
            <h3 className="text-2xl font-semibold text-center mb-2">
                {t("BookingModal.updateNamePhoneHeading")}
            </h3>
            <p className="text-center">
                <FaInfoCircle className="mr-2 text-info inline-block" />
                {t("BookingModal.userUpdateNoticeInfo")}
            </p>

            <div className="flex flex-col mt-10 w-full mx-auto gap-6">
                <div className="w-full max-w-[400px] mx-auto">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                            setName(e.currentTarget.value);
                        }}
                        placeholder={`Name`}
                        className={`${
                            nameError && !name.length
                                ? "border-red-500 outline-red-500"
                                : "border-[#000b2966] outline-primary"
                        } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                    />
                    {nameError && !name.length && (
                        <p className="text-red-500 mt-1 ml-2 text-xs">
                            {nameError}
                        </p>
                    )}
                </div>
                <div className="w-full max-w-[400px] mx-auto">
                    <div className="relative">
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => {
                                setPhoneNumber(e.currentTarget.value);
                            }}
                            placeholder={`Phone number`}
                            className={`${
                                phoneError && !phone.length
                                    ? "border-red-500 outline-red-500"
                                    : "border-[#000b2966] outline-primary"
                            } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg appearance-none`}
                        />
                    </div>
                    {phoneError && (
                        <p className="text-red-500 mt-1 ml-2 text-xs">
                            {phoneError}
                        </p>
                    )}
                </div>
                <div className="flex items-center justify-center gap-5 mb-10 mt-20">
                    <button
                        type="button"
                        onClick={onBack}
                        className="bg-white text-secondary border border-secondary px-4 py-1 h-[64px] flex items-center justify-center w-[calc(50%-20px)] rounded-[24px] text-[19px] font-semibold hover:bg-[#FF3A3A] hover:border-[#FF3A3A] hover:text-white transform duration-200"
                    >
                        {t("back")}
                    </button>
                    <button
                        type="submit"
                        onClick={handleFormSubmit}
                        className="bg-secondary text-white border border-secondary px-4 py-1 h-[64px] flex items-center justify-center w-[calc(50%-20px)] rounded-[24px] text-[19px] font-semibold hover:bg-primary hover:border-primary hover:text-secondary transform duration-200"
                    >
                        {t("next")}
                    </button>
                </div>
            </div>
        </div>
    );
}
