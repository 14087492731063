import React from "react";
import { useTranslation } from "react-i18next";

export const ComponentHomeClubPartner = ({ formLink }) => {
    const { t } = useTranslation();

    const scrollToSection = () => {
        const section = document.getElementById(formLink);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <div
            className={`bg-black w-full bg-img bg-cover bg-no-repeat flex flex-col lg:flex-row justify-between items-center pb-48 lg:pb-0 bg-[url("../public/images/club/partnerbg.png")]`}
        >
            <div className="text-white w-full lg:w-[50%] mx-auto lg:mx-0 lg:mr-auto mt-20 lg:mb-10 xl:mb-20 xl:mt-36 2xl:mt-40 px-4 md:px-16">
                <div className="max-w-[663px] mx-auto lg:mx-0 lg:mr-auto">
                    <h1 className="text-3xl lg:text-[56px] lg:leading-[67px] font-semibold">
                        {t("HomeClubPartner.title")}
                    </h1>
                    <p className="text-xl mt-5">{t("HomeClubPartner.p-1")}</p>
                    <div className="mt-8 xl:mt-14 flex flex-wrap gap-4">
                        <button
                            onClick={scrollToSection}
                            className="w-[200px] xl:w-[237px] bg-primary text-lg lg:text-xl font-medium text-[#373737] h-[55px] xl:h-[71px] rounded-full flex items-center justify-center hover:bg-secondary hover:text-white transform duration-200"
                        >
                            {t("Anchor")}
                        </button>
                        <button
                            onClick={scrollToSection}
                            className="w-[200px] h-[55px] xl:w-[237px] bg-white text-lg lg:text-xl font-medium text-secondary xl:h-[71px] rounded-full flex items-center justify-center hover:bg-secondary hover:text-white transform duration-200"
                        >
                            {t("Demo")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative h-full text-white w-full max-w-[300px] sm:max-w-[400px] lg:min-w-[512px] lg:max-w-[512] xl:max-w-[50%] ml-auto">
                <img
                    src="/images/club/partnerlaptop.png"
                    className="absolute top-10 lg:-top-36 xl:-top-48 2xl:-top-[200px] right-0"
                    alt="laptop with mobile"
                />
            </div>
        </div>
    );
};
