import { Disclosure, Transition } from "@headlessui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

interface faqInterface {
    title: string;
    subtitle: string;
    data: {
        id: number;
        question: string;
        answer: string;
    }[];
}

export const Faq = ({ title, subtitle, data }: faqInterface) => {
    return (
        <section className="flex flex-col gap-4 text-secondary items-center justify-center max-w-[1500px] mx-auto md:px-8 px-4 mb-[120px] mt-16">
            <h3 className="md:text-[30px] text-xl font-semibold text-center">
                {title}
            </h3>
            <p className="md:text-[18px] text-[14px] text-center max-w-[900px]">
                {subtitle}
            </p>
            <hr className="w-full" />
            <div>
                <div className="lg:w-[800px] w-full py-4">
                    {data.map((faq) => (
                        <Disclosure key={faq.id} as="div" className="mt-2">
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex w-full justify-between items-center p-2 text-left hover:bg-[#000b2919] focus:outline-none focus-visible:ring focus-visible:ring-[#000B29BF] focus-visible:ring-opacity-75">
                                        <span className="md:text-[22px] text-[14px] font-medium ">
                                            {faq.question}
                                        </span>
                                        {open ? (
                                            <RxCross2 />
                                        ) : (
                                            <AiOutlinePlus />
                                        )}
                                    </Disclosure.Button>
                                    <Transition
                                        show={open}
                                        enter="transition duration-300 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                    >
                                        <Disclosure.Panel className="md:px-8 px-4 md:py-8 py-4 text-lg text-[#000B29BF]">
                                            <span className="text-left lg:text-lg text-[14px]">
                                                {faq.answer}
                                            </span>
                                        </Disclosure.Panel>
                                    </Transition>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </div>
            </div>
        </section>
    );
};
