import { t } from "i18next";

const SubTotalComponent = ({
    currentPrice,
    bookingData,
    discountAmount,
    voucher
}) => {
    return (
        <div className="flex flex-col gap-3">
            <div className="flex justify-between gap-3 flex-wrap pt-[5px]">
                <p className="text-[22px]">{t("SubTotalComponent.subtotal")}</p>
                <h3 className="font-semibold text-[22px]">
                    {discountAmount
                        ? (discountAmount + currentPrice).toFixed(2)
                        : currentPrice.toFixed(2)}{" "}
                    {bookingData?.currency}
                </h3>
            </div>
            {discountAmount ? (
                <>
                    <div className="flex justify-between gap-3 flex-wrap pt-[5px]">
                        <p className="text-[22px]">
                            {t("SubTotalComponent.discount")}{" "}
                            {voucher?.amount_in_percentage}%
                        </p>
                        <h3 className="font-semibold text-[22px]">
                            ({discountAmount.toFixed(2)}){" "}
                            {bookingData?.currency}
                        </h3>
                    </div>
                    <div className="flex justify-between gap-3 flex-wrap pt-[5px]">
                        <p className="text-[22px]">
                            {t("SubTotalComponent.total")}
                        </p>
                        <h3 className="font-semibold text-[22px]">
                            {currentPrice.toFixed(2)} {bookingData?.currency}
                        </h3>
                    </div>
                </>
            ) : null}
            <hr className="w-full" />
            <div className="flex justify-between gap-3 flex-wrap text-[15px]">
                <p>{t("SubTotalComponent.cancelPolicy")}</p>
                <p className="text-[#FF3A3A] font-medium">
                    {t("SubTotalComponent.cancelUnder")}{" "}
                    <a
                        style={{
                            color: "rgb(0, 11, 41)",
                            textDecoration: "underline"
                        }}
                        href="/en/privacy-policy-1"
                        target="_blank"
                    >
                        {t("SubTotalComponent.readMore")}
                    </a>
                </p>
            </div>
            <div className="flex justify-between gap-3 flex-wrap text-[15px]">
                <p>{t("SubTotalComponent.cancellationFee")}:</p>
                <div className="text-end">
                    {bookingData?.currency === "SEK" ? (
                        <p className="font-medium text-end">
                            {`${bookingData?.cancellation_fee} ${bookingData?.currency}`}
                        </p>
                    ) : (
                        <p className="font-medium text-end">
                            {`${bookingData?.currency} ${bookingData?.cancellation_fee}`}
                        </p>
                    )}
                    <a
                        style={{
                            color: "rgb(0, 11, 41)",
                            textDecoration: "underline"
                        }}
                        href="/en/privacy-policy-1"
                        target="_blank"
                    >
                        {t("SubTotalComponent.cancellationPolicy")}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SubTotalComponent;
