import React from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { Store } from "react-notifications-component";

const OnboardingAge = ({
    setCurrentStep,
    selectedAge,
    setSelectedAge
}: {
    setCurrentStep: any;
    setSelectedAge: any;
    selectedAge: string;
}) => {
    const ages = [
        { id: 1, name: "18 - 24" },
        { id: 2, name: "25 - 34" },
        { id: 3, name: "35 - 44" },
        { id: 4, name: "45 - 54" },
        { id: 5, name: "55 - 64" },
        { id: 6, name: "65+" }
    ];
    return (
        <div className="text-center p-10 w-[90%] md:w-[40%] bg-[#FBFBFB] rounded-3xl shadow">
            <h3 className="mb-10 font-bold text-xl text-secondary text-center">
                Select your age range
            </h3>

            <ul className="grid w-full gap-6 md:grid-cols-3">
                {ages.map((age: { id: number; name: string }) => (
                    <div className="flex flex-col" key={age.id}>
                        <li onClick={() => setSelectedAge(age.name)}>
                            <label
                                className={
                                    selectedAge === age.name
                                        ? "flex items-center justify-center w-full p-2 text-secondary bg-primary rounded-full cursor-pointer"
                                        : "flex items-center justify-center w-full p-2 text-secondary bg-white border border-secondary rounded-full cursor-pointer"
                                }
                            >
                                <div className="w-full text-lg">{age.name}</div>
                            </label>
                        </li>
                    </div>
                ))}
            </ul>
            <div className="mt-20 flex justify-between items-center">
                <button
                    className="font-semibold outline-none rounded-full bg-white text-secondary border border-secondary w-auto px-10 py-3"
                    onClick={() => {
                        setCurrentStep((prev: number) => prev - 1);
                    }}
                >
                    <GrLinkPrevious />
                </button>
                <button
                    className="font-semibold outline-none rounded-full bg-white text-secondary border border-secondary w-auto px-10 py-3"
                    onClick={() => {
                        if (selectedAge) {
                            setCurrentStep(3);
                        } else {
                            Store.addNotification({
                                title: "Failed",
                                message: "Please select your age first",
                                type: "danger",
                                container: "center",
                                animationIn: [
                                    "animate__animated",
                                    "animate__fadeIn"
                                ],
                                animationOut: [
                                    "animate__animated",
                                    "animate__fadeOut"
                                ],
                                dismiss: {
                                    duration: 3000
                                }
                            });
                        }
                    }}
                >
                    <GrLinkNext />
                </button>
            </div>
        </div>
    );
};

export default OnboardingAge;
