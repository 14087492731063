// import "../styles/TermsPrivacy.css"

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.privacy")} | Padel Mates</title>
            </Helmet>
            <div className="px-4 py-8 sm:px-8">
                <div>
                    <p className="text-primary font-semibold text-center text-2xl mb-4">
                        PRIVACY POLICY
                        <br />
                        PADEL MATES
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            This privacy policy (the "Privacy Policy") aims to
                            provide you (the "User" or "you"), with information
                            on how we, Padel Mates International AB ("PADEL
                            MATES", "we" or "us") process your personal data in
                            different situations.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            This Privacy Policy applies when you are using our
                            services via our application "PADEL MATES" (the
                            "App"). Via the App, we provide individuals with a
                            platform and a community where our members can
                            contact and engage with other persons and groups of
                            persons interested in padel and other racket sports,
                            set up matches, organize tournaments, settle
                            payments and make bookings at sports facilities
                            around the world (the "Services"). This Privacy
                            Policy also applies when you are visiting and/or
                            make bookings via our website www.padelmates.se (the
                            "Website").
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            We are the data controller, which means that we are
                            responsible for the processing of your personal data
                            and to provide you with clear, concise and correct
                            information regarding the personal data processing
                            we carry out.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Although we have taken great care in ensuring that
                            this Privacy Policy includes all information you
                            need, please contact us at support@padelmates.se if
                            you have any questions.
                        </span>
                        <span lang="EN-US"> </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">
                                Personal data and other key terms
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            It is important to us that the information in this
                            Privacy Policy is easy to understand. Below we
                            attempt to clarify certain terms and concepts used
                            in the Privacy Policy.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Personal data</b>" is any kind of information
                            that can be directly or indirectly attributed to
                            you. Common examples of personal data are name,
                            address, e-mail address and telephone number. User
                            ratings (skill level or sportsmanship level), win
                            rates, points, descriptions of playing styles,
                            strengths and weaknesses, Usage history, member id
                            are also examples of personal data, as well as
                            information you provide to us when contacting
                            customer support.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Legal basis</b>" is the basis we rely on to
                            process your personal data for different purposes.
                            Any processing activity we carry out needs to be
                            supported by such a legal basis, and we need to
                            inform you of which basis apply. For example, in
                            some cases our processing is justified as it is
                            necessary to perform our contract with you, and
                            other times we have a legal obligation to process
                            the personal data.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Purpose</b>" is why we need to process your
                            personal data in the first place. All processing of
                            personal data must be carried out for explicit and
                            legitimate purposes.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Retention time</b>" refers to the time period
                            during which we need to process your personal data
                            for a particular purpose. A lot of the personal data
                            will be processed by us for as long as you have an
                            account.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Active account</b>" means an account that you
                            have not requested be deleted or removed or that has
                            not been banned by us.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <b>
                        <span
                            lang="EN-US"
                            style={{
                                fontSize: "14.0pt",
                                lineHeight: "107%",
                                fontFamily: '"Calibri",sans-serif'
                            }}
                        >
                            <br style={{ pageBreakBefore: "always" }} />
                        </span>
                    </b>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span
                                lang="EN-US"
                                style={{
                                    fontSize: "14.0pt",
                                    lineHeight: "107%"
                                }}
                            >
                                &nbsp;
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">
                                The source of your personal data
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            The majority of the personal data is collected
                            directly from you when you register your account or
                            otherwise use the Services.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            In some situations, we have gathered your personal
                            data from other sources. For example, if you are a
                            member of one of our partnered sports facilities, we
                            may receive personal data related to you from such
                            partners.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">
                                How and why we process your personal data
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            In the tables below we have attempted to clarify how
                            we process your personal data in different
                            situations or different parts of our services.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <span lang="EN-US">
                                When using the Application (Community and
                                Matchmaking Services)
                            </span>
                        </i>
                    </p>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium bg-gray-200 dark:border-neutral-500">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Purpose
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Categories of personal data
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Legal basis
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Retention time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Creating and managing your
                                                    account
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, email,
                                                    phone number, postal address
                                                    (optional), image
                                                    (optional), gender, age,
                                                    credit card information or
                                                    other payment information,
                                                    match history and form,
                                                    match time preferences
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Organizing public matches or
                                                    tournaments in the App
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, image
                                                    (if available), gender, age,
                                                    ratings (skill and
                                                    sportsmanship), phone number
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Providing chat services
                                                    between users
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, image,
                                                    email, phone number, ratings
                                                    (skill and sportsmanship)
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Calculating, publishing and
                                                    updating the ratings (skill
                                                    and sportsmanship) of the
                                                    user
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, match
                                                    history and form, ratings
                                                    (skill and sportsmanship)
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Purchases of premium
                                                    subscriptions or other in
                                                    app purchases
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, email,
                                                    phone number, postal
                                                    address, credit/debit card
                                                    information or other payment
                                                    information
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of a contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    Payment information stored
                                                    for +7 years from date of
                                                    payment
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    When suspecting violations
                                                    of our terms and conditions
                                                    and for monitoring and
                                                    analyzing your use of the
                                                    Services
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, email,
                                                    phone number, chat history,
                                                    booking history and user
                                                    generated data (e.g.
                                                    interactions with other
                                                    users in the App)
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Customer support via email
                                                    or telephone
                                                </td>
                                                <td className="px-6 py-4">
                                                    Name, email, phone number
                                                    and any other personal data
                                                    provided by you as part of
                                                    the support matter
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as the support
                                                    matter is ongoing
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <span lang="EN-US">
                                When using the Application or Website (Booking
                                Services)
                            </span>
                        </i>
                    </p>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium bg-gray-200 dark:border-neutral-500">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Purpose
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Categories of personal data
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Legal basis
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Retention time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Making and confirming
                                                    bookings made with relevant
                                                    sports facilities
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, image
                                                    (if available), credit/debit
                                                    card information or other
                                                    payment information, email
                                                    and phone number.
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                    Payment information stored
                                                    for +7 years from date of
                                                    payment
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Provide you with information
                                                    on sports facilities
                                                    locations near you
                                                </td>
                                                <td className="px-6 py-4">
                                                    Location data
                                                </td>
                                                <td className="px-6 py-4">
                                                    Consent
                                                </td>
                                                <td className="px-6 py-4">
                                                    Location data is only
                                                    processed when you are using
                                                    our location services and
                                                    only if you have consented.
                                                    See also "Your right to
                                                    withdraw consent" below
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Place relevant booking
                                                    information in the calendar
                                                    of your device
                                                </td>
                                                <td className="px-6 py-4">
                                                    Limited calendar access
                                                </td>
                                                <td className="px-6 py-4">
                                                    Consent
                                                </td>
                                                <td className="px-6 py-4">
                                                    The access is contingent on
                                                    your consent, which can be
                                                    withdrawn at anytime. See
                                                    "Your right to withdraw
                                                    consent" below
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <span lang="EN-US">
                                Marketing and similar purposes
                            </span>
                        </i>
                    </p>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium bg-gray-200 dark:border-neutral-500">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Purpose
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Categories of personal data
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Legal basis
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Retention time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Sending marketing
                                                    material/newsletters and
                                                    similar
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name and
                                                    email
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    Until you opt-out (see "Your
                                                    right to object" below)
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Sending questionnaires about
                                                    our Services
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name and
                                                    email
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    Until you opt-out (see "Your
                                                    right to object" below)
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    If you respond to voluntary
                                                    questionnaires; processing
                                                    and analyzing your responses
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name and
                                                    email
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    The access is contingent on
                                                    your consent, which can be
                                                    withdrawn at anytime. See
                                                    "Your right to withdraw
                                                    consent" below
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Sharing your personal data
                                                    with marketing partners
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name and
                                                    email
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    The access is contingent on
                                                    your consent, which can be
                                                    withdrawn at anytime. See
                                                    "Your right to withdraw
                                                    consent" below
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <span lang="EN-US">
                                To improve the Services and similar
                            </span>
                        </i>
                    </p>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium bg-gray-200 dark:border-neutral-500">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Purpose
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Categories of personal data
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Legal basis
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Retention time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Developing new features in
                                                    the App or on the Website,
                                                    or improving our Services
                                                </td>
                                                <td className="px-6 py-4">
                                                    Name, email, user patterns
                                                    and behaviors, information
                                                    provided in questionnaires
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Maintenance, operation and
                                                    security of our IT systems,
                                                    the Website and the App
                                                </td>
                                                <td className="px-6 py-4">
                                                    May vary, but generally
                                                    limited to aggregated user
                                                    information
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span
                                lang="EN-US"
                                style={{
                                    fontSize: "14.0pt",
                                    lineHeight: "107%"
                                }}
                            >
                                <br />
                            </span>
                        </b>
                        <b>
                            <span lang="EN-US">
                                Sharing and transfers of your personal data
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            In providing our Services, we will need to share
                            your personal data with different third parties:{" "}
                        </span>
                    </p>
                    <p
                        className="leading-6 text-sm"
                        style={{ textIndent: "-18.0pt" }}
                    >
                        <span lang="EN-US">
                            -
                            <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                        <span lang="EN-US">
                            We share necessary sets of your personal data with
                            certain suppliers, such as providers of hosting
                            services and software solutions, and are often
                            referred to as "data processors". These recipients
                            will only process your personal data in accordance
                            with our instructions. If you want more information
                            on the identity of such data processors, contact us
                            at support@padelmates.se.
                            <br />
                            <br />
                        </span>
                    </p>
                    <p
                        className="leading-6 text-sm"
                        style={{ textIndent: "-18.0pt" }}
                    >
                        <span lang="EN-US">
                            -
                            <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                        <span lang="EN-US">
                            When using the booking services, we will need to
                            transfer your personal data to the relevant sports
                            facility where you have made a booking, which will
                            use your personal data for administering the booking
                            but also for other purposes (such as marketing).
                            Please refer to the privacy policies of each sports
                            facility for more information on its processing
                            activities (and links to each sports facility's
                            website are typically available in the App). We may
                            also transfer your information to payment service
                            providers (such as Stripe), who facilitate your
                            payment of booking fees and similar.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">Cookies</span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            On our Website, we use cookies to gather data for
                            different purposes. Cookies that are not "strictly
                            necessary" for the operation of our website need to
                            be approved by you before we can start using them.{" "}
                            <br />
                            <br />
                            Cookies are small text files that are placed on your
                            device when you visit the Website and use our
                            Services. They can be used to improve the Website
                            and make it easier for you to use or to help us
                            analyse and improve our Services.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            To provide you with information on which cookies we
                            use, and to handle your approval or consent to such
                            use, we use the tool react-cookie-consent. Via this
                            tool you can find detailed information on all
                            cookies we use, their purposes and manage if you
                            want to provide or withdraw your consent to any
                            specific cookie.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            It is important to note that some cookies are
                            strictly necessary for the Website to operate
                            properly, and such cookies will be set without your
                            prior approval or consent. For all other cookies you
                            are free to change your approvals at any time via
                            the tool react-cookie-consent available here.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">
                                Where we process your personal data
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Personal data that we collect from you in accordance
                            with this policy is always stored by us within a
                            country of the European Union or the European
                            Economic Area ("EU/EEA"). If, however, we need to
                            transfer your personal data to a country outside of
                            the EU/EEA, any such transfer will always be carried
                            out in compliance with applicable laws and we will
                            take appropriate actions to ensure that the
                            protection of your personal data is not undermined.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            For example, if the sports facility that you wish to
                            book a time slot with is located outside the EU/EEA,
                            relevant personal data will need to be transferred
                            to such sports facility. In these situations, we
                            have ensured an appropriate level of protection of
                            your personal data by entering into standard data
                            protection clauses with such sports facilities. For
                            more information, please contact
                            support@padelmates.se
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">Your rights</span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            The protection of your personal data is a
                            fundamental right, and according to the GDPR you
                            have a number of explicit rights that you may
                            exercise in different situations.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to access
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-GB">
                            You have the right to demand and receive
                            confirmation on whether or not we process personal
                            data which concerns you. If such personal data is
                            being processed, you have the right to receive
                            information regarding the processing and a copy,
                            free of charge, of the personal data being
                            processed. If you request additional copies, we are
                            entitled to charge you a reasonable administrative
                            fee.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-GB">
                                    Your right to rectification
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-GB">
                            You have the right to request rectification of your
                            personal data if the information is incorrect,
                            including the right to have incomplete personal data
                            completed.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">Your right to erasure</span>
                            </u>
                        </i>
                        <u>
                            <span lang="EN-US">
                                <br />
                            </span>
                        </u>
                        <span lang="EN-GB">
                            You have the right to have your personal data erased
                            without undue delay and we are obligated to erase
                            personal data in the following situations:
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (i)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the personal data are no longer necessary in
                            relation to the purposes for which they were
                            collected or otherwise processed;
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (ii)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the processing is based on your consent and you
                            withdraw your consent, unless there are any other
                            legal grounds for the processing;
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (iii)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            you object to processing based on a "legitimate
                            interest" and there are no overriding legitimate
                            grounds for the processing (note however that you
                            always have the right to erasure of your personal
                            data used for direct marketing purposes);
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (iv)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the personal data have been unlawfully processed; or
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (v)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the personal data have to be erased for compliance
                            with a legal obligation in Union or Member State law
                            to which we are subject.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Typically speaking, if you delete your account in
                            the Application, we will erase a majority of the
                            personal data we process about you - as the personal
                            data will no longer be necessary for us to process.
                            However, in certain situations we will need to
                            process your personal data, even if you request its
                            erasure, for example to comply with legal
                            obligations under EU or Swedish law or when
                            necessary for us to establish, exercise or defend
                            legal claims. In these situations, your right to
                            erasure is limited.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to restriction
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-GB">
                            You have the right to restrict our processing of
                            your personal data in the following situations:
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (i)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the accuracy of the personal data is contested by
                            you, for a period enabling us to verify the accuracy
                            of the data;
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (ii)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the processing is unlawful but you do not want to
                            erase the personal data and instead request that the
                            use of the data is restricted;
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (iii)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            we no longer need the personal data for the purposes
                            of the processing, but you need them for the
                            establishment, exercise or defence of legal claims;
                            or
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (iv)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            you have objected to processing based on "legitimate
                            interest", pending the verification whether our
                            legitimate grounds to process the data override
                            yours.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-GB">
                            To clarify " when you request restriction, the
                            personal data will continue to be stored, but PADEL
                            MATES will not process the relevant personal data
                            for any other purpose without your consent. Again,
                            if we need to process the personal data in order to
                            establish, exercise or defend against legal claims,
                            the restriction on processing will not apply in
                            relation to such processing. Lastly, if processing
                            is necessary for the protection of the rights of
                            another natural or legal person, the restrictions
                            may not apply.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-GB">
                                    Your right to data portability
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-US">
                            Whenever we process your personal data, by automated
                            means based on your <u>consent</u>
                            or based on a <u>contract</u>, you have the right to
                            get a copy of your data transferred to you or to
                            another party. This only includes the personal data
                            that you yourself have provided us with.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to object
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-GB">You have the right to object</span>
                        <span lang="EN-GB"></span>
                        <span lang="EN-GB">
                            to our processing of personal data which is based on
                            a "legitimate interest." We will not continue to
                            process the personal data unless we can show
                            compelling legitimate grounds for the processing
                            which override your interests, rights and freedoms{" "}
                            <u>or</u> for the establishment, exercise or defence
                            of legal claims.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Importantly, however, you always have the right to
                            object to our processing of personal data for direct
                            marketing purposes, and if you do (often referred to
                            as "opt-out") we will stop processing the personal
                            data for such marketing purposes. In relation to
                            newsletters or marketing materials sent by email,
                            the easiest way to opt-out is to use the
                            "unsubscribe"-button in the email.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to withdraw consent
                                </span>
                            </u>
                        </i>
                        <u>
                            <span lang="EN-US">
                                {" "}
                                <br />
                            </span>
                        </u>
                        <span lang="EN-GB">
                            If you have provided us with your consent for any
                            processing, you have the right to at any time
                            withdraw it. If you do, we will no longer process
                            your personal data for these purposes.
                        </span>
                    </p>
                    <p
                        className="leading-6 mt-4 text-sm"
                        style={{ lineHeight: "normal" }}
                    >
                        <span lang="EN-GB">
                            PADEL MATES currently primarily relies on your
                            consent to process location information. The consent
                            is gathered from you in the application and if you
                            wish to withdraw the consent, you can do so by
                            following the instructions below.{" "}
                        </span>
                        <span lang="EN-GB">
                            Please note that if you choose to withdraw the
                            consent by disabling the location services feature
                            on your mobile device, this will limit the
                            performance of the App in the sense that the App
                            will no longer be able to provide information on
                            nearby sports courts.{" "}
                        </span>
                        <span lang="EN-GB">
                            Please feel free to contact us if you have any
                            issues.
                        </span>
                    </p>
                    <p
                        className="leading-6 mt-4 text-sm"
                        style={{ lineHeight: "normal" }}
                    >
                        <span lang="EN-GB">
                            For iOS: You should be able to adjust the settings
                            on your iOS mobile device to prevent our collection
                            of location information by disabling the location
                            services feature on your device. For more detailed
                            information on how to choose which applications that
                            use your iOS device's location, please click{" "}
                        </span>
                        <span lang="SV">
                            <a href="https://support.apple.com/en-gb/guide/personal-safety/ips3dbc70436/web">
                                <span lang="EN-GB">here</span>
                            </a>
                        </span>
                        <span lang="EN-GB">. </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-GB">
                            For Android: You should be able to adjust your
                            settings on your Android mobile device to prevent
                            our collection of location information by disabling
                            the location services feature on your device. For
                            more detailed information on how to choose which
                            applications use your Android device's location,
                            please click{" "}
                        </span>
                        <span lang="SV">
                            <a href="https://support.google.com/accounts/answer/3467281?hl=en&ref_topic=7189122">
                                <span lang="EN-GB">here</span>
                            </a>
                        </span>
                        <span lang="EN-GB">.</span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-GB"
                            style={{
                                fontSize: "10.0pt",
                                lineHeight: "107%",
                                fontFamily: '"Times New Roman",serif'
                            }}
                        >
                            <br />
                        </span>
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to file complaints with
                                    authorities{" "}
                                </span>
                            </u>
                        </i>
                        <u>
                            <span lang="EN-US">
                                <br />
                            </span>
                        </u>
                        <span lang="EN-US">
                            For any request relating to this Privacy Policy, or
                            if you wish to exercise any of your right or have
                            any complaints, you are always free to contact us
                            (see details below). You also always have the right
                            to file a complaint with{" "}
                        </span>
                        <span lang="EN-GB">the </span>
                        <span lang="SV">
                            <a href="https://www.imy.se/">
                                <span lang="EN-GB">
                                    Swedish Authority for Privacy Protection
                                </span>
                            </a>
                        </span>
                        <span lang="EN-GB"> (Sw. </span>
                        <i>
                            <span lang="EN-US">
                                Integritetsskyddsmyndigheten
                            </span>
                        </i>
                        <span lang="EN-GB">
                            ) or the corresponding authority in your country of
                            residence.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <u>
                            <span lang="EN-US">
                                <br />
                            </span>
                        </u>
                        <b>
                            <span lang="EN-US">Contact information</span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Feel free to contact us by email at
                            support@padelmates.se or the address below.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="SV">
                            Padel Mates International AB, org. nr. 559273-1359,
                            Fågelviksvägen 9, 145 53 NORSBORG, Sweden.
                        </span>
                    </p>
                </div>
                {/* <footer className="TermsPrivacy">
        <div className="TermsPrivacyDiv">
          <NavLink className="TermsPrivacyNav" to="/TC">
            Terms of Use
          </NavLink>
          <NavLink className="TermsPrivacyNav" to="/Privacy-Policy">
            Privacy Policy
          </NavLink>
          <NavLink className="TermsPrivacyNav" to="/">
            Home - Player
          </NavLink>
          <NavLink className="TermsPrivacyNav" to="/club">
            Home - Club
          </NavLink>
        </div>
        <h3 className="TermsPrivacyCopy">Copyright © All Rights Reserved</h3>
      </footer> */}
            </div>
        </>
    );
};
