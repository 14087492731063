import React, { useEffect, useState } from "react";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
import moment from "moment";
import "moment-timezone";
import { useBookingAtom, useClubAtom } from "../../Utilities/clubStore";
import { useTranslation } from "react-i18next";
import {
    calculateAvailability,
    getAllAvailableHours,
    getSelectableHours
} from "../../Api/api";
import DownloadAppPopup from "../DownloadAppPopup";

interface ComponentBookingInfoProps {
    menuDivActive: boolean;
    pageSetter: any;
    day: string;
    date: string;
    time: string;
    onClose: () => void;
    setSelectedBookingData: any;
    jsonData: any;
    setPrice: any;
    setMins: any;
    endTime: any;
    startTime: any;
    key?: string;
    slotId?: string;
    slots?: any;
    setSelectedCourt: any;
    setAvailableSlotState: any;
    timezone: string;
    setStartTime: any;
    setEndTime: any;
    setBasePrice: any;
    basePrice: number;
    allSlots: any;
    originalCourts: any;
}

export const ComponentBookingInfo: React.FC<ComponentBookingInfoProps> = ({
    menuDivActive,
    pageSetter,
    day,
    date,
    time,
    onClose,
    jsonData,
    setPrice,
    setMins,
    key,
    slots,
    setSelectedBookingData,
    setSelectedCourt,
    setAvailableSlotState,
    timezone,
    setStartTime,
    setEndTime,
    basePrice,
    setBasePrice,
    allSlots,
    originalCourts
}) => {
    const [courtDivActive, setCourtDivActive] = useState<boolean[]>();
    const [slotsLength, setSlotsLength] = useState(0);
    const [club, setClub] = useClubAtom();
    const [bookingAtom, setBookingAtom] = useBookingAtom();
    const [availableSlot, setAvailableSlot] = useState<any>({});
    const [updateSelectedSlot, setUpdateSelectedSlot] = useState<string>();
    const [isShowDownloadModal, showDownloadModal] = useState<boolean>(false);
    useEffect(() => {
        // const hours = getSelectableHours(getAllAvailableHours([slots]));
        // const _availableSlot = calculateAvailability(
        //     slots.available_slots,
        //     time,
        //     hours
        // );
        setAvailableSlot({});
        let newSlot = "";
        if (time) {
            const myArray = time.split(",");
            newSlot = myArray[1];
            setUpdateSelectedSlot(myArray[0]);
        }

        setTimeout(() => {
            const _availableSlot = getObjectsWithStartTime(
                allSlots,
                newSlot ? newSlot : time
            );

            if (_availableSlot) {
                setSlotsLength(getJsonKeyCount(_availableSlot));
                setAvailableSlot(_availableSlot);
                setCourtDivActive(
                    generateBooleanArray(getJsonKeyCount(_availableSlot))
                );
            } else {
                setCourtDivActive(generateBooleanArray(slotsLength));
            }
        }, 500);

        // setAvailableSlot(_availableSlot);

        // const isAvailable = Object.values(_availableSlot).some(
        //     (slot) => slot.isAvailable
        // );

        // if (isAvailable) {
        //     setCourtDivActive(false);
        // } else {
        //     setCourtDivActive(true);
        // }
    }, [time]);

    function generateBooleanArray(length: number) {
        const booleanArray = [];
        for (let i = 0; i < length; i++) {
            booleanArray.push(true);
        }
        return booleanArray;
    }

    function getJsonKeyCount(_availableSlot: any) {
        // Initialize count
        let count = 0;

        // Loop through the object
        for (const key in _availableSlot) {
            count++;
        }
        return count;
    }

    function getObjectsWithStartTime(data: any, startTime: string) {
        const result: any = {};

        for (const obj of data) {
            if (obj.startTime === startTime) {
                if (!result[obj.courtName]) {
                    result[obj.courtName] = [];
                }
                result[obj.courtName].push(obj);
            }
        }

        return result;
    }

    const selectedDateTime = moment.tz(
        `${date} ${updateSelectedSlot}`,
        "DD/MM/YYYY HH:mm",
        timezone
    );

    const daySlotsExpired = selectedDateTime
        .tz(timezone)
        .isBefore(moment().tz(timezone));

    const foundSlot = slots?.available_slots?.find(
        (slot: any) => slot.hours?.includes(updateSelectedSlot)
    );

    const end = moment.tz(foundSlot?.end_datetime, timezone);
    const diff = end.diff(selectedDateTime, "minutes");

    const availableSlotOld: { [key: string]: boolean } = {
        "60": !daySlotsExpired && diff - 60 >= 0 && diff - 60 !== 30,
        "90": !daySlotsExpired && diff - 90 >= 0 && diff - 90 !== 30,
        "120": !daySlotsExpired && diff - 120 >= 0 && diff - 120 !== 30,
        "180": !daySlotsExpired && diff - 180 >= 0 && diff - 180 !== 30
    };
    const price = foundSlot?.price || 0;

    const toggleCourtClass = (index: number, value: boolean) => {
        if (!!courtDivActive) {
            const updatedState = [...courtDivActive]; // Create a copy of the state array
            updatedState[index] = value;
            setCourtDivActive(updatedState);
        }
    };

    const { t } = useTranslation();

    const getFacilities = (court: any) => {
        const facilities = [
            court.indoor ? t("indoor") : t("outdoor"),
            court.double ? t("double") : t("single"),
            court.sport.badminton ? t("Badminton") : "",
            court.sport.squash ? t("Squash") : "",
            court.sport.tennis ? t("Tennis") : "",
            court.sport.padel ? t("Padel") : "",
            court.sport.pickleball ? t("Pickleball") : ""
        ].filter((item) => item !== "");

        return facilities;
    };

    return (
        <>
            <DownloadAppPopup
                isOpenAppDownloadModal={isShowDownloadModal}
                closeAppDownloadModal={() => showDownloadModal(false)}
            />
            <div className={menuDivActive ? "block w-full" : "hidden"}>
            {Object.keys(availableSlot).map((key, index) => {
                let courtData: any = {};
                let courtItem: any = {};
                originalCourts?.map((val: any) => {
                    if (val.name === key) {
                        courtData = getFacilities(val);
                        courtItem = val;
                    }
                });

                return (
                    <>
                        <hr className="w-full" />
                        <div className="flex justify-between gap-2 items-center mb-3 mt-[18px]">
                            <div className="flex flex-col">
                                <h3 className="text-[19px] font-semibold">
                                    {" "}
                                    {key}{" "}
                                </h3>
                                <p className="text-[15px]">
                                    {courtData?.join(" | ")}
                                </p>
                            </div>
                            <button className="mr-5">
                                {!!courtDivActive && !courtDivActive[index] ? (
                                    <SlArrowDown
                                        className="w-[22px] h-[22px] font-semibold"
                                        onClick={() =>
                                            toggleCourtClass(index, true)
                                        }
                                    />
                                ) : (
                                    <SlArrowUp
                                        className="w-[22px] h-[22px] font-semibold"
                                        onClick={() =>
                                            toggleCourtClass(index, false)
                                        }
                                    />
                                )}
                            </button>
                        </div>
                        {/* Find your ideal {day} Time Slot on {date} at {time} */}
                        {!!courtDivActive && courtDivActive[index] && (
                            <div
                                className={` ${
                                    !courtDivActive[index] ? "hidden" : "flex"
                                } flex-col gap-2 mt-3 mb-[18px]`}
                            >
                                {availableSlot[key]?.length ? (
                                    availableSlot[key]?.map((item: any) => (
                                        <div className="flex justify-between items-center gap-2 flex-wrap">
                                            <div className="flex flex-col">
                                                <h5 className="font-medium text-lg">
                                                    {item?.duration} min
                                                </h5>
                                                <p className="text-[15px]">
                                                    {updateSelectedSlot} -{" "}
                                                    {moment(
                                                        updateSelectedSlot,
                                                        "HH:mm"
                                                    )
                                                        .add(
                                                            item?.duration,
                                                            "minutes"
                                                        )
                                                        .format("HH:mm")}
                                                </p>
                                            </div>
                                            <div className="flex flex-col gap-3">
                                                <button
                                                    className="bg-secondary text-white text-[17px] px-4 py-2 min-w-[164px] min-h-[40px] rounded-2xl flex items-center justify-center font-semibold hover:bg-primary hover:text-secondary transform duration-200"
                                                    onClick={() => {
                                                        // debugger
                                                        showDownloadModal(true);
                                                        return;

                                                        setSelectedBookingData(
                                                            jsonData
                                                        );
                                                        setClub({
                                                            ...club,
                                                            startTime:
                                                                availableSlotOld.start_datetime,
                                                            endTime:
                                                                availableSlotOld.end_datetime
                                                        });
                                                        setBookingAtom({
                                                            ...bookingAtom,
                                                            page: 1,
                                                            availableSlotState:
                                                                availableSlotOld,
                                                            selectedCourt:
                                                                courtItem,
                                                            endTime: moment(
                                                                updateSelectedSlot,
                                                                "HH:mm"
                                                            )
                                                                .add(
                                                                    item?.duration,
                                                                    "minutes"
                                                                )
                                                                .format(
                                                                    "HH:mm"
                                                                ),
                                                            show: !bookingAtom.show,
                                                            basePrice:
                                                                item?.price,
                                                            price: Number(
                                                                item?.price
                                                            ),
                                                            mins: item?.duration
                                                        });
                                                    }}
                                                >
                                                    Book{" "}
                                                    {item?.price?.toFixed(2)}{" "}
                                                    {jsonData?.currency}
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <>
                                        <p>
                                            {t("noAvailableSlotFor")}{" "}
                                            <b>{updateSelectedSlot}</b>{" "}
                                        </p>
                                        <p>{t("selectAnotherDate")}</p>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                );
            })}
        </div>
        </>
        
    );
};
