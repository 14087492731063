import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SuccessMessage = () => {
    const { t } = useTranslation();

    return (
        <div className="text-center px-[22px] rounded-3xl ">
            <div className="flex flex-col justify-center items-center min-w-[200px] w-full max-w-[435px] mx-auto">
                <img
                    src="/images/verified.svg"
                    className="w-[70px] h-[70px]"
                    alt="verified icon"
                />
                <h2 className="text-3xl font-semibold text-center mt-[25px] mb-[10px]">
                    {t('Auth.successfullyChanged')}
                </h2>
                <p className="text-sm text-center">
                {t('Auth.passwordUpdated')}
                </p>
                <Link className="w-full" to="/">
                    <button className="min-h-[60px] bg-secondary w-full text-white rounded-3xl font-semibold mt-[30px] ">
                    {t('Auth.backToHome')}
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default SuccessMessage;
