import { ComponentHomeClubPartner } from "../Components/club/ComponentHomeClubPartner";
import { ComponentHomeClubProfitability } from "../Components/club/ComponentHomeClubProfitability";
import { ComponentHomeClubStatistics } from "../Components/club/ComponentHomeClubStatistics";
import { ComponentHomeClubPlatform } from "../Components/club/ComponentHomeClubPlatform";
import { ComponentHomeClubCustomers } from "../Components/club/ComponentHomeClubCustomers";
import { ComponentHomeClubConnect } from "../Components/club/ComponentHomeClubConnect";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const HomepageClub = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.club")} | Padel Mates</title>
            </Helmet>
            <div className="HomepageClub">
                <ComponentHomeClubPartner formLink="formLink" />
                <ComponentHomeClubProfitability formLink="formLink" />
                <ComponentHomeClubStatistics />
                <ComponentHomeClubPlatform />
                <ComponentHomeClubCustomers />
                <ComponentHomeClubConnect />
            </div>
        </>
    );
};

export default HomepageClub;
