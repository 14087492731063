import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

const libraries: any = ["places"];

interface ClubProfileMapProps {
    clubData: any;
}

const ClubProfileMap: React.FC<ClubProfileMapProps> = ({ clubData }) => {
    const { t } = useTranslation();
    const [mapLoaded, setMapLoaded] = useState(false);
    const [selectedCoords, setSelectedCoords] = useState<any>(null);

    useEffect(() => {
        if (clubData) {
            let coords = {
                lat: clubData?.loc?.coordinates[1],
                lng: clubData?.loc?.coordinates[0]
            };
            setSelectedCoords(coords);
        }
    }, [clubData]);

    const googleMapsApiKey = process.env
        .REACT_APP_GOOGLEAPI_MAP_API_KEY as string;

    const { isLoaded } = useLoadScript({
        googleMapsApiKey,
        libraries
    });

    const mapContainerStyle = {
        width: "100%",
        height: "280px"
    };

    const options = {
        disableDefaultUI: true,
        clickableIcons: false
    };

    const handleLoadMap = (map: any) => {
        map.addListener("click", handleMapClick);
        setTimeout(() => {
            setMapLoaded(true);
        }, 100);
    };

    const handleMapClick = () => {
        console.log("loading => google map loaded successfully");
    };

    if (!isLoaded) {
        return (
            <div className="p-5 rounded-2xl">
                <p>loading...</p>
            </div>
        );
    }

    return (
        <div className="p-4 rounded-2xl">
            <h2 className="font-semibold text-xl text-left mb-4">{t("Profile.findUs")}</h2>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={selectedCoords}
                zoom={16}
                options={options}
                onLoad={handleLoadMap}
            >
                {selectedCoords && mapLoaded && (
                    <Marker
                        position={{
                            lat: selectedCoords.lat,
                            lng: selectedCoords.lng
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    );
};

export default ClubProfileMap;
