import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [isResetSent, setIsResetSent] = useState(false);
    const [error, setError] = useState("");
    const [emailError, setEmailError] = useState("");

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!email) {
            setEmailError(`${t("Auth.emailRequired")}`);
        } else if (!emailPattern.test(email)) {
            setEmailError(`${t("Auth.invalidEmail")}`);
        } else {
            setEmailError("");
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setIsResetSent(true);
            setError("");
        } catch (error: any) {
            setIsResetSent(false);
            setError(error.message);
        }
    };

    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.forgotPassword")} | Padel Mates</title>
            </Helmet>
            <div
                className={`bg-[url("../public/images/signin-bg.png")] bg-no-repeat bg-left-bottom min-h-screen`}
            >
                <div className="max-w-[511px] mx-auto w-full flex flex-col justify-center items-center px-4 sm:px-5 py-10 lg:py-24 2xl:py-32">
                    <div className="flex flex-col justify-center items-center w-full lg:mt-6 2xl:mt-12">
                        <h1 className="text-2xl md:text-3xl xl:text-4xl 2xl:text-[42px] text-center font-bold">
                            {t("Auth.resetTitle")}
                        </h1>
                    </div>

                    {isResetSent ? (
                        <div className="mt-10 text-center">
                            <p>
                                {t("Auth.emailSent")} {email}.
                            </p>
                            <p>{t("Auth.checkEmail")}</p>
                        </div>
                    ) : (
                        <>
                            <form className="flex flex-col mt-10 w-full max-w-[460px] mx-auto gap-6">
                                <div className="w-full max-w-[400px] mx-auto">
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            const newEmail =
                                                e.currentTarget.value;
                                            setEmail(newEmail);
                                            if (newEmail) {
                                                if (
                                                    !emailPattern.test(newEmail)
                                                ) {
                                                    setEmailError(
                                                        `${t(
                                                            "Auth.invalidEmail"
                                                        )}`
                                                    );
                                                } else {
                                                    setEmailError("");
                                                }
                                            } else {
                                                setEmailError(
                                                    `${t("Auth.emailRequired")}`
                                                );
                                            }
                                        }}
                                        required
                                        placeholder={t("Auth.emailAddress")}
                                        className={`${
                                            emailError
                                                ? "border-red-500 outline-red-500"
                                                : "border-[#000b2966] outline-primary"
                                        } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                                    />
                                    {emailError && (
                                        <p className="text-red-500 mt-1 ml-2 text-xs">
                                            {emailError}
                                        </p>
                                    )}
                                </div>
                                <input
                                    type="submit"
                                    onClick={handleResetPassword}
                                    value={t("MenuUser.reset")}
                                    className="bg-secondary text-white rounded-full w-full max-w-[400px] mx-auto shadow-md h-[56px] flex items-center justify-center py-1 px-4 mt-5 cursor-pointer hover:bg-green-600 xl:hover:bg-primary hover:text-secondary transform duration-200 hover:scale-105"
                                />
                                {error && (
                                    <p className="text-red-500 text-center mt-5">
                                        {error}
                                    </p>
                                )}
                            </form>
                            <p className="flex text-lg justify-between items-center flex-wrap gap-2 mt-[35px]">
                                {t("Auth.goBack")}
                                <Link
                                    className="md:text-primary text-green-600 font-semibold hover:scale-105 transform duration-200"
                                    to="/sign-in"
                                >
                                    {t("login")}
                                </Link>
                            </p>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
