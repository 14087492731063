import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

const ActivityTab = ({
    activityType,
    setActivityType,
    allClubActivities,
    setClubActivities,
    onActivityClick
}: {
    activityType: any;
    setActivityType: Dispatch<SetStateAction<any>>;
    allClubActivities: any;
    setClubActivities: Dispatch<SetStateAction<any>>;
    onActivityClick: (activity: string) => void;
}) => {
    const { t } = useTranslation();
    // const [activityType, setActivityType] = useState(`${t("Profile.all")}`);

    const activityTypes = [
        `${t("Profile.all")}`,
        `${t("Profile.tournaments")}`,
        `${t("Profile.training")}`,
        `${t("Profile.matches")}`
    ];

    return (
        <div>
            <div className="mt-5 flex flex-wrap gap-3 justify-left max-w-fit mx-right px-2 sm:px-0">
                {activityTypes?.map((activity) => (
                    <button
                        key={activity}
                        onClick={() => {
                            setActivityType(activity);
                            onActivityClick(activity);
                        }}
                        className={`${
                            activityType === activity
                                ? "bg-secondary text-white"
                                : "bg-white text-secondary hover:bg-primary hover:text-secondary transform duration-200"
                        } min-h-[31px] min-w-[106px] text-[13px] flex justify-center items-center border border-secondary rounded-full font-medium`}
                    >
                        {activity}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ActivityTab;
