import moment from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSolidBadge } from "react-icons/bi";
import { BsPlayFill } from "react-icons/bs";

const MatchActivityCard = ({
    clubActivity,
    page
}: {
    clubActivity: any;
    page?: string;
}) => {
    const { t } = useTranslation();

    return (
        <div className="relative rounded-[10px] shadow border border-[#000b29] border-opacity-[0.15] px-2 py-4 sm:px-4 h-full">
            {/* <div className="absolute top-0 right-0 w-[153px] h-[26px] text-[11px] flex justify-center items-center bg-secondary text-white rounded-tr-[10px] rounded-bl-[10px]">
        <p className="underline">Street Padel Matchning…</p>
      </div> */}
            <div
                className={`absolute top-0 right-0 w-max h-[25px] text-xs flex justify-center items-center bg-primary rounded-tr-[10px] rounded-bl-[10px] px-3.5 font-bold ${
                    clubActivity?.discount === 0 ? "invisible" : "visible"
                }`}
            >
                <div className="flex gap-2 items-center">
                    {clubActivity?.discount === 100
                        ? t("free")
                        : clubActivity?.discount
                        ? "-" + clubActivity?.discount + "%"
                        : ""}
                    <p className="font-noah pt-0.5">
                        {clubActivity?.payment_per_person !== 0
                            ? clubActivity?.payment_per_person?.toFixed(0)
                            : 0}{" "}
                        {clubActivity?.currency
                            ? clubActivity?.currency + "/pp"
                            : "kr/pp"}
                    </p>
                </div>
            </div>

            <div className="flex flex-wrap gap-3 items-center justify-between">
                <div className="flex flex-col gap-2 justify-between items-stretch h-full max-w-[152px] md:max-w-fit">
                    <div className="flex flex-col gap-1 max-w-[240px]">
                        <p className="text-xs flex items-center gap-2 uppercase font-medium">
                            <BsPlayFill className="w-5 h-5" />
                            {clubActivity?.activity}{" "}
                            {clubActivity?.tournament_type}
                        </p>
                        <p className="text-sm font-medium">
                            {clubActivity.start_datetime
                                ? moment.tz(clubActivity.start_datetime , clubActivity?.timezone).format(
                                      "ddd"
                                  )
                                : ""}{" "}
                            {clubActivity.start_datetime
                                ? moment.tz(clubActivity.start_datetime , clubActivity?.timezone).format(
                                      "MM/DD HH:mm"
                                  )
                                : ""}{" "}
                            -{" "}
                            {clubActivity.start_datetime
                                ? moment.tz(clubActivity.stop_datetime , clubActivity?.timezone).format(
                                      "HH:mm"
                                  )
                                : ""}
                        </p>
                        <h4 className="text-xl font-semibold">
                            {clubActivity?.title}
                        </h4>
                        {/* <p className="text-sm flex items-center gap-2">
                            <img src="/images/location.svg" alt="location" />
                            {clubActivity?.address} {clubActivity?.city}{" "}
                            {clubActivity?.country}
                        </p> */}
                    </div>
                    <div className="flex flex-wrap gap-3 items-center pt-4">
                        <img
                            src="/images/badge.svg"
                            alt="badge"
                            className={`${
                                page === "activities" ? "md:w-8 w-6" : "w-6"
                            }`}
                        />
                        <p
                            className={`${
                                page === "activities" ? "text-lg" : "text-sm"
                            } font-medium`}
                        >
                            {clubActivity?.min_level}-{clubActivity?.max_level}
                        </p>
                        <div className="ml-2 flex">
                            {clubActivity?.is_booked ? (
                                <img
                                    src="/images/window-green.svg"
                                    alt="window active"
                                    className="-ml-2"
                                />
                            ) : (
                                <img
                                    src="/images/window-gray.svg"
                                    alt="window inactive"
                                    className="-ml-2"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2 md:pt-6">
                    <div className="flex gap-4 flex-wrap max-w-[110px] items-center justify-end">
                        {clubActivity?.players[0] &&
                        clubActivity?.players[0] ? (
                            <div className="relative h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
                                <img
                                    src={clubActivity?.players[0]?.profilePic}
                                    alt="avatar"
                                />
                                <div className="absolute top-0 -right-1.5 z-10">
                                    <BiSolidBadge className="w-5 h-5" />
                                    <p className="absolute top-[2px] font-medium right-1.5 text-white text-[11px]">
                                        {clubActivity?.players[0]?.level?.im}
                                    </p>
                                    {/* <p className="text-[7px] uppercase bg-[#FFD47C] font-medium absolute -bottom-[30px] right-[17px] px-1 flex items-center justify-center">
                    PRO
                  </p> */}
                                </div>
                            </div>
                        ) : (
                            <div className="border-2 border-secondary border-dashed h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
                                <AiOutlinePlus className="w-5 h-5" />
                            </div>
                        )}
                        {clubActivity?.players[1] ? (
                            <div className="relative h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
                                <img
                                    src={clubActivity?.players[1]?.profilePic}
                                    alt="avatar"
                                />
                                <div className="absolute top-0 -right-1.5 z-10">
                                    <BiSolidBadge className="w-5 h-5" />
                                    <p className="absolute top-[2px] font-medium right-1.5 text-white text-[11px]">
                                        {clubActivity?.players[1]?.level?.im}
                                    </p>
                                    {/* <p className="text-[7px] uppercase bg-[#FFD47C] font-medium absolute -bottom-[30px] right-[17px] px-1 flex items-center justify-center">
                    PRO
                  </p> */}
                                </div>
                            </div>
                        ) : (
                            <div className="border-2 border-secondary border-dashed h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
                                <AiOutlinePlus className="w-5 h-5" />
                            </div>
                        )}

                        {clubActivity?.players[2] ? (
                            <div className="relative h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
                                <img
                                    src={clubActivity?.players[2]?.profilePic}
                                    alt="avatar"
                                />
                                <div className="absolute top-0 -right-1.5 z-10">
                                    <BiSolidBadge className="w-5 h-5" />
                                    <p className="absolute top-[2px] font-medium right-1.5 text-white text-[11px]">
                                        {clubActivity?.players[2]?.level?.im}
                                    </p>
                                    {/* <p className="text-[7px] uppercase bg-[#FFD47C] font-medium absolute -bottom-[30px] right-[17px] px-1 flex items-center justify-center">
                    PRO
                  </p> */}
                                </div>
                            </div>
                        ) : (
                            <div className="border-2 border-secondary border-dashed h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
                                <AiOutlinePlus className="w-5 h-5" />
                            </div>
                        )}
                        {clubActivity?.players[3] ? (
                            <div className="relative h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
                                <img
                                    src={clubActivity?.players[3]?.profilePic}
                                    alt="avatar"
                                />
                                <div className="absolute top-0 -right-1.5 z-10">
                                    <BiSolidBadge className="w-5 h-5" />
                                    <p className="absolute top-[2px] font-medium right-1.5 text-white text-[11px]">
                                        {clubActivity?.players[3]?.level?.im}
                                    </p>
                                    {/* <p className="text-[7px] uppercase bg-[#FFD47C] font-medium absolute -bottom-[30px] right-[17px] px-1 flex items-center justify-center">
                    PRO
                  </p> */}
                                </div>
                            </div>
                        ) : (
                            <div className="border-2 border-secondary border-dashed h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
                                <AiOutlinePlus className="w-5 h-5" />
                            </div>
                        )}

                        {/* <div className="relative h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
            <img src={clubActivity?.players[3]?.profilePic} alt="avatar" />
            <div className="absolute top-0 -right-1.5 z-10">
              <BiSolidBadge className="w-5 h-5 text-[#6990FD]" />
              <p className="absolute top-[2px] font-medium right-1.5 text-white text-[11px]">
                5
              </p>
            </div>
          </div> */}
                    </div>
                    {/* <div className="h-[1px] bg-secondary bg-opacity-[0.15] w-full" /> */}
                    {/* <div className="flex gap-4 flex-wrap">
          <div className="relative h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
            <img src={clubActivity?.players[3]?.profilePic} alt="avatar" />
            <div className="absolute top-0 -right-1.5 z-10">
              <BiSolidBadge className="w-5 h-5 text-[#6990FD]" />
              <p className="absolute top-[2px] font-medium right-1.5 text-white text-[11px]">
                3
              </p>
            </div>
          </div>
          <div className="border-2 border-secondary border-dashed h-9 w-9 md:h-[46px] md:w-[46px] rounded-full flex items-center justify-center">
            <AiOutlinePlus className="w-5 h-5" />
          </div>
        </div> */}
                </div>
            </div>
        </div>
    );
};

export default MatchActivityCard;
