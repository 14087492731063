import { Helmet } from "react-helmet-async";
import OnboardingForm from "../Components/accountSettings/OnboardingForm";
import { useTranslation } from "react-i18next";

const Onboarding = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.onboarding")} | Padel Mates</title>
            </Helmet>
            <div className="text-secondary w-full py-2 sm:py-6">
                <OnboardingForm />
            </div>
        </>
    );
};

export default Onboarding;
