import React from "react";
import AccountSettingsMain from "../Components/accountSettings/AccountSettingsMain";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const AccountSettings = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.accountSettings")} | Padel Mates</title>
            </Helmet>
            <div className="text-secondary w-full">
                <AccountSettingsMain />
            </div>
        </>
    );
};

export default AccountSettings;
