import React from "react";
import { t } from "i18next";

const PromoComponent = () => {
    return (
        <div className="w-full flex items-center h-[56px] mt-10">
            <input
                type="text"
                placeholder="Enter your promo code here"
                className="h-full w-full bg-gray-100 rounded-l-full p-3 outline-none flex items-center justify-center"
            />
            <button className="h-full text-white py-1 flex items-center justify-center rounded-r-full bg-secondary px-10">
                {t("PromoComponent.apply")}
            </button>
        </div>
    );
};

export default PromoComponent;
