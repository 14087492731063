import React, { useState, useEffect } from "react";
import { ComponentHomeClubReview } from "./ComponentHomeClubReview";
import reviewsData from "../../Mock-data/ClubReview.json";
import { useTranslation } from "react-i18next";

export const ComponentHomeClubCustomers = () => {
    const [reviews, setReviews] = useState([]); // State for storing reviews
    const { t } = useTranslation();

    useEffect(() => {
        setReviews(reviewsData.reviews); // Set the reviews array from the imported JSON data
    }, []);

    const reviewText = {
        0: t("HomeClubCustomers.reviews.review1"),
        1: t("HomeClubCustomers.reviews.review2"),
        2: t("HomeClubCustomers.reviews.review3")
    };

    return (
        <div className="text-secondary py-20 px-4 md:px-16">
            <h3 className="text-center text-xl font-medium tracking-widest">
                {t("HomeClubCustomers.title1")}
            </h3>
            <h2 className="text-center mx-auto text-3xl xl:text-4xl 2xl:text-[45px] 2xl:leading-[55px] font-semibold w-full mt-2">
                {t("HomeClubCustomers.title2")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12 max-w-fit mx-auto h-full">
                {/* Render reviews */}
                {reviews.slice(0, 3).map((review, index) => (
                    <ComponentHomeClubReview
                        key={index}
                        stars={review.stars}
                        name={review.name || ""}
                        text={reviewText[index] || ""}
                        pfp={
                            index === 0
                                ? "/images/club/ClubReview1.png"
                                : index === 1
                                ? "/images/club/ClubReview2.png"
                                : "/images/club/ClubReview3.png"
                        }
                    />
                ))}
            </div>
        </div>
    );
};
