import { WithAuth } from "../Components/WithAuth.js";
import { Helmet } from "react-helmet-async";
import { ComponentPaymentHistoryCard } from "../Components/ComponentPaymentHistoryCard.jsx";
import { useTranslation } from "react-i18next";

const PaymentPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("PaymentHistoryPage.history")} | Padel Mates</title>
            </Helmet>
            <div className="lg:px-16 px-4 min-h-screen">
                <div className="w-full py-12 text-center">
                    <h1 className="text-[45px] font-semibold text-secondary">
                        {t("PaymentHistoryPage.history")}
                    </h1>
                </div>
                <ComponentPaymentHistoryCard />
            </div>
        </>
    );
};

export default WithAuth(PaymentPage);
