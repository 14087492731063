import { useStripe, useElements } from "@stripe/react-stripe-js";
import moment from "moment-timezone";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
    IBookingData,
    applyCoupon,
    bookCourt,
    checkAvailableSlot,
    getClubProfile,
    getUserInfo,
    handleCreditPayment,
    updateUserInfo
} from "../../Api/api";
import { AuthContext } from "../AuthProvider";
import BookingDetails from "./BookingDetails";
import CourtComponent from "./CourtComponent";
import ExtraTimeManager from "./ExtraTimeManager";
import PaymentComponent from "./PaymentComponent";
import PromoComponent from "./PromoComponent";
import ShareWithFriends from "./ShareWithFriends";
import SubTotalComponent from "./SubTotalComponent";
import BecomeMemberForm from "./BecomeMemberForm";
import { t } from "i18next";
import { CgSpinner } from "react-icons/cg";
import { FcGoogle } from "react-icons/fc";
import { BsEyeFill, BsEyeSlash, BsFacebook } from "react-icons/bs";
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup
} from "firebase/auth";
import { auth } from "../../firebase";
import { handleLoggedInUser } from "../../Pages/SignIn";
import { NOTIFICATION_TYPE, Store } from "react-notifications-component";
import { useBookingAtom } from "../../Utilities/clubStore";
import { Link } from "react-router-dom";
import Coupon from "../Coupon";
import Axios from "../../Api/Axios";
import useTogglePasswordView from "../../hooks/useTogglePasswordView";
import { ICard } from "../../Api/usePayments";
import { ConfirmCardPaymentData } from "@stripe/stripe-js";
import UpdateUserInfo from "./UpdateUserInfo";
import PopupInfo from "./PopupInfo";

interface BookingModalProps {
    bookingData: any;
    setPrice: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    pageSetter: React.Dispatch<React.SetStateAction<number>>;
    show: boolean;
    startTime: any;
    onClose: () => void;
    setIsShown?: React.Dispatch<React.SetStateAction<boolean>>;
    setMins: React.Dispatch<React.SetStateAction<number>>;
    mins: any;
    setEndTime: any;
    endTime: any;
    setStartTime: any;
    currency: string;
    price: any;
    selectedCourt: any;
    availableSlotState: any;
    selectedSlot: any;
    basePrice: number;
    setBasePrice: any;
}

interface ClosableOverlayProps {
    children: React.ReactNode;
    onClick: (evt: React.MouseEvent) => void;
}

export interface IPaymentIntent {
    success: boolean;
    message: string;
    clientSecret: string;
    subscriptionId: string;
    paymentIntentId: string;
    transactionId: string;
    paymentStatus: string;
    chargeId: string;
    receiptUrl?: string;
}

const ClosableOverlay: React.FC<ClosableOverlayProps> = ({
    children,
    onClick
}) => {
    return (
        <>
            <div
                className="wrapper-overlay fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#00000080] z-[4000]"
                onClick={onClick}
            >
                {children}
            </div>
        </>
    );
};

const BookingModal: React.FC<BookingModalProps> = ({
    bookingData,
    page,
    pageSetter,
    show,
    onClose,
    setPrice,
    setIsShown,
    setMins,
    mins,
    setEndTime,
    endTime,
    setStartTime,
    startTime,
    currency,
    price,
    selectedCourt,
    availableSlotState,
    selectedSlot,
    basePrice
}) => {
    const [paymentMethodId, setPaymentMethodId] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState<boolean>(false);
    const [bookingStatus, setBookingStatus] = useState<any>(true);
    const [isLoginError, setIsLoginError] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginErrorMessage, setLoginErrorMessage] = useState("");
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [infoPopupTitle, setInfoPopupTitle] = useState("Processing the payment...");
    const [infoPopupSubtitle, setInfoPopupSubtitle] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const { showPassword, togglePasswordView } = useTogglePasswordView();

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const currentUser = useContext(AuthContext);
    const selectedDateFromStorage = localStorage.getItem("selectedDate");
    const selectedDate: any = selectedDateFromStorage
        ? selectedDateFromStorage.replace(/-/g, "/").replace(/["\\]/g, "")
        : new Date();

    const [errorMessage, setErrorMessage] = useState<string | undefined>("");
    const [applyingVoucher, setApplyingVoucher] = useState<boolean>(false);
    const [voucherSuccessMessage, setVoucherSuccessMessage] = useState<
        string | undefined
    >("");
    const [voucherErrorMessage, setVoucherErrorMessage] = useState<
        string | undefined
    >("");
    const [voucher, setVoucher] = useState<any>(null);
    const [discountAmount, setDiscountAmount] = useState<Number>(0);
    const [bookingAtom, setBookingAtom] = useBookingAtom();

    const [isSelected, setIsSelected] = useState("card");
    const [userCredit, setUserCredit] = useState(0);
    const [clubData, setClubData] = useState<any>(null);

    const [accessCode, setAccessCode] = useState(null);

    const [cardDetails, setCardDetails] = useState<any>();

    const [cardElement, setCardElement] = useState<any>(null);

    const [saveCardForFutureUse, setSaveCardForFutureUse] = useState(false);

    const [receiptUrl, setReceiptUrl] = useState("");

    const [userName, setUserName] = useState("");
    const [userPhone, setUserPhone] = useState("");

    const getCubProfile = async (clubId: any, playerId: string) => {
        try {
            const resp = await getClubProfile(clubId, playerId);
            if (resp?.status === 200) {
                setClubData({ ...resp.data });
            }
        } catch (error: any) {
            console.log("loading => getCubProfile error", error);
        }
    };

    useEffect(() => {
        if (!voucher) {
            localStorage.removeItem("voucherSuccessMsg");
        }
    }, [voucher]);

    useEffect(() => {
        if (bookingData?._id && currentUser?.uid) {
            getCubProfile(bookingData?._id, currentUser?.uid);
        }
    }, [bookingData?._id, currentUser?.uid, bookingAtom.show]);

    const minutesSetter = (amountOfMin: any): void => {
        setBookingAtom({ ...bookingAtom, mins: amountOfMin });
    };
    const priceSetter = (moneyValue: any): void => {
        setBookingAtom({ ...bookingAtom, price: moneyValue });
    };

    const calculateHours = (): void => {
        console.log("first");
    };

    const createBooking = (resp: any) => {
        const time = moment(selectedDate)?.format("DD MM YYYY");
        const startTimeString = `${time} ${bookingAtom.selectedSlot}`;
        const startTime = moment.tz(
            startTimeString,
            "DD MM YYYY HH:mm",
            bookingData?.timezone
        );
        const endTime = startTime.clone().add(bookingAtom.mins, "minutes");

        const bookingDataForEndpoint = {
            total_price: Number(bookingAtom.price.toFixed(2)),
            discount: voucher?.amount_in_percentage || 0,
            code: voucher?.code || "",
            info: `Court booked by ${
                currentUser.displayName
            } for ${bookingData?.name} on ${moment(selectedDate).format(
                "DD MMM YYYY HH:mm"
            )}`,
            transaction_id: resp.data.transactionId,
            payment_status: resp.data.paymentStatus,
            court_ids: [bookingAtom.selectedCourt._id],
            players: [],
            start_datetime: startTime.utc().valueOf(),
            stop_datetime: endTime.utc().valueOf()
        };
        const bookData: IBookingData = {
            club_id: bookingData._id,
            bookData: bookingDataForEndpoint
        };
        bookCourt(bookData)
            .then((bookingRes: any) => {
                setBookingStatus(bookingRes.success);
                if (bookingRes.success) {
                    setAccessCode(bookingRes?.user?.access_code);
                    setBookingAtom({
                        ...bookingAtom,
                        page: 4
                    });
                    return bookingRes;
                }
            })
            .catch((e) => setBookingStatus(false));
    };

    const MAX_RETRY_ATTEMPTS = 5;
    const RETRY_INTERVAL_MS = 4000; // 4 seconds

    const handleBookingRetries = async (resp: any, retryCount: number = 0) => {
        if (retryCount >= MAX_RETRY_ATTEMPTS) {
            // Max retry attempts reached, handle the failure scenario
            console.log("Max retry attempts reached. Booking failed.");
            setBookingStatus(false); // Handle the booking failure state here if needed
            return;
        }

        try {
            const bookingResp: any = await createBooking(resp);
            if (bookingResp.success) {
                console.log("loading => Booking status is success!");
            } else {
                console.log(
                    "loading => Payment status is still processing. Retrying in 5 seconds..."
                );
                setTimeout(() => {
                    handleBookingRetries(resp, retryCount + 1);
                }, RETRY_INTERVAL_MS);
            }
            // Booking succeeded
        } catch (error) {
            console.log({ error });
            // Booking failed, retry after the specified interval
            // await new Promise((resolve) => setTimeout(resolve, RETRY_INTERVAL_MS));
            // await handleBookingRetries(resp, retryCount + 1);
        }
    };

    const handlePayment = async (event: any) => {
        const time = moment(selectedDate)?.format("DD MM YYYY");
        const startTimeString = `${time} ${bookingAtom.selectedSlot}`;
        const startTime = moment.tz(
            startTimeString,
            "DD MM YYYY HH:mm",
            bookingData?.timezone
        );
        const endTime = startTime.clone().add(bookingAtom.mins, "minutes");
        const startDatetime = startTime.valueOf();
        const endDatetime = endTime.valueOf();
        if (isSelected === "card") {
            if (!stripe || !elements) return;

            if (cardDetails && !cardDetails?.complete) {
                setErrorMessage("Please fill all the card fields");
                setProcessing(false);
                return;
            }
            if (
                !cardDetails &&
                (paymentMethodId === undefined || paymentMethodId === "")
            ) {
                setErrorMessage(
                    "You need to select a card for payment. If there is no card available, please add one by clicking on the 'Add New Card' button"
                );
                setProcessing(false);
                return;
            }

            event.preventDefault();
            const _bookingData = {
                total_price: Number(bookingAtom.price.toFixed(2)),
                discount: voucher?.amount_in_percentage || 0,
                code: voucher?.code || "",
                info: `Court booked by ${
                    currentUser.displayName
                } at ${bookingData?.name} on ${moment(selectedDate).format(
                    "DD MMM YYYY HH:mm"
                )}`,
                court_ids: [bookingAtom.selectedCourt._id],
                players: [],
                start_datetime: startTime.utc().valueOf(),
                stop_datetime: endTime.utc().valueOf()
            };
            try {
                //setProcessing(true);
                setIsLoadingPayment(true);
                setErrorMessage("");
                const paymentIntentParams: Record<string, any> = {
                    amount: Number(bookingAtom.price.toFixed(2)),
                    payFor: "booking",
                    clubId: bookingData._id,
                    isSupportAutoJoinFlow: true,
                    autoJoinAdditionalInfo: {
                        bookData: _bookingData
                    },
                    startTime: startDatetime,
                    endTime: endDatetime
                };

                /*
                As react-stripe-js doesn't provide details info about cardElement like mobile package,
                I am calling stripe token api to get details info like last4, expiryMonth, expiryYear etc.
                */
                let _cardDetailsFromStripeTokenForIntent;
                if (cardDetails && cardElement) {
                    const { token, error } = await stripe.createToken(
                        cardElement
                    );
                    if (token && !error) {
                        _cardDetailsFromStripeTokenForIntent = {
                            last4: token.card?.last4,
                            expiryMonth: token.card?.exp_month,
                            expiryYear: Number(
                                token.card?.exp_year.toString().slice(-2)
                            ),
                            complete: true,
                            brand: token.card?.brand,
                            validExpiryDate: "Valid",
                            validNumber: "Valid",
                            validCVC: "Valid"
                        };
                    }
                }

                // add cardDetails based on checkbox for future usage
                paymentIntentParams.cardDetails = saveCardForFutureUse
                    ? _cardDetailsFromStripeTokenForIntent || cardDetails
                    : null;

                const { data: intent } = await Axios.post(
                    "/payment/createPaymentIntent",
                    paymentIntentParams
                );

                const confirmCardPaymentParams: ConfirmCardPaymentData = {};

                if (paymentMethodId && !cardDetails) {
                    confirmCardPaymentParams.setup_future_usage = "off_session";
                }

                if (cardDetails && cardElement) {
                    confirmCardPaymentParams.payment_method = {
                        card: cardElement,
                        billing_details: {
                            name: currentUser.displayName,
                            email: currentUser.email
                        }
                    };
                } else if (paymentMethodId) {
                    confirmCardPaymentParams.payment_method = paymentMethodId; // only send for existing cards
                }

                const confirmPayment = await stripe.confirmCardPayment(
                    intent.clientSecret,
                    confirmCardPaymentParams
                );

                // Clear card details
                setCardDetails(null);

                if (!confirmPayment?.error) {
                    let responseFromChargeCheck =
                        await checkPaymentChargeWithCondition(
                            intent?.paymentIntentId
                        );
                    if (responseFromChargeCheck?.data?.success) {
                        setAccessCode(
                            responseFromChargeCheck.data?.user?.access_code
                        );
                        setBookingAtom({
                            ...bookingAtom,
                            page: 4
                        });
                        setReceiptUrl(
                            responseFromChargeCheck?.data?.receiptUrl
                        );
                    }
                    // return responseFromChargeCheck.data;
                }
            } catch (error) {
                console.log({ error });
                setErrorMessage("Some error occurred while trying to book!");
            } finally {
                setIsLoadingPayment(false);
            }
            //setProcessing(false);
        } else if (isSelected === "credit") {
            event.preventDefault();

            if (
                (!clubData && !bookingData?.following) ||
                (clubData && !clubData?.following)
            ) {
                setErrorMessage("You are not a follower of the club");
                setProcessing(false);
                return;
            } else if (userCredit < bookingAtom.price) {
                setErrorMessage("You do not have enough credits");
                setProcessing(false);
                return;
            }

            const payFor = "booking";
            const clubId = bookingData._id as string;
            const totalPrice = bookingAtom.price;
            const credit = bookingAtom.price;
            const startTime = startDatetime;
            const endTime = endDatetime;

            const getSport = (obj: any) =>
                Object.keys(obj).find((i) => obj[i] === true);

            const gameType = getSport(bookingAtom?.selectedCourt?.sport);

            try {
                //setProcessing(true);
                setIsLoadingPayment(true);
                setErrorMessage("");

                handleCreditPayment({
                    payFor,
                    gameType,
                    clubId,
                    totalPrice,
                    credit,
                    startTime,
                    endTime
                })
                    .then(async (resp) => {
                        const response = {
                            data: {
                                success: resp.success,
                                message: resp.message,
                                chargeId: resp.chargeId,
                                paymentStatus: resp.paymentStatus,
                                transactionId: resp.transactionId,
                                receiptUrl: resp.receiptUrl
                            }
                        };
                        await handleBookingRetries(response);
                        //setProcessing(false);
                        setIsLoadingPayment(false);
                    })
                    .catch((err) => {
                        setIsLoadingPayment(false);
                        console.log(
                            "🚀 ~ file: PaymentComponent.jsx:78 ~ handleCreditPayment ~ err:",
                            err
                        );
                        setErrorMessage(
                            err?.response?.data?.message ||
                                "Some error occurred while trying to book!"
                        );
                    });
            } catch (error) {
                setIsLoadingPayment(false);
                console.log(
                    "🚀 ~ file: PaymentComponent.jsx:78 ~ handleCreditPayment ~ error:",
                    error
                );
                setErrorMessage("Some error occurred while trying to book!");
            } 
        }
    };
    async function checkPaymentChargeWithCondition(
        paymentintentid: string,
        maxAttempts: number = 6,
        delay: number = 3000
    ) {
        let attempt = 0;
        while (attempt < maxAttempts) {
            try {
                const url = `/payment/check-charge-auto-join-v2?paymentintentid=${paymentintentid}`;
                const response = await axiosGetWithRetry(url);

                if (
                    response.data &&
                    response.data.success &&
                    response.data.receiptUrl !== undefined &&
                    response.data.receiptUrl !== null
                ) {
                    // Condition met, return the response
                    return response;
                } else {
                    // Condition not met, increment attempt and continue the loop
                    await new Promise((resolve: any) =>
                        setTimeout(resolve, delay)
                    );
                    attempt++;
                }
            } catch (error) {
                // Handle or rethrow the error as appropriate
                throw error;
            }
        }
        throw new Error("Condition not met after maximum attempts");
    }

    const axiosGetWithRetry = async (
        url: string,
        config = {},
        maxRetries = 3,
        retryDelay = 5000
    ) => {
        let lastError;

        for (let attempt = 1; attempt <= maxRetries; attempt++) {
            try {
                // Attempt the POST request
                console.log("Attempt #" + attempt + " Axios Get: " + url);
                const response = await Axios.get(url, config);
                return response; // Return response if successful
            } catch (error) {
                lastError = error; // Store the error from the attempt

                if (attempt < maxRetries) {
                    // Wait for the retryDelay, increased by attempt count
                    await new Promise((resolve: any) =>
                        setTimeout(resolve, retryDelay * attempt)
                    );
                }
            }
        }

        // If all retries fail, throw the last error
        throw lastError;
    };

    const handleUpdateUserInfo = async (formData: {
        name: string;
        phone: string;
    }) => {
        setBookingAtom({
            ...bookingAtom,
            page: 1
        });

        updateUserInfo(formData).then((data) => {
            let type = "success",
                message = t(`${t("BookingModal.nameAndPhoneUpdated")}!`);
            if (data?.success) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 3
                });
            } else {
                type = "danger";
                message = t(`${t("BookingModal.errorOnSavingNamePhone")}.`);
            }
            Store.addNotification({
                title: null,
                message: message,
                type: type as NOTIFICATION_TYPE,
                insert: "bottom",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000
                }
            });
        });
    };

    const handleCheckForNameAndPhoneNumber = async () => {
        setProcessing(true);
        try {
            const {
                status,
                user: { name, phone }
            } = await getUserInfo();

            setUserName(name);
            setUserPhone(phone);

            if (!name || !phone) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 12
                });
            } else {
                await handleCheckSlotAvailability();
            }

            setTimeout(() => {
                setProcessing(false);
            }, 500);
        } catch (error) {
            setTimeout(() => {
                setProcessing(false);
            }, 500);
        }
    };

    (window as any)["setBookingAtom"] = setBookingAtom;
    (window as any)["bookingAtom"] = bookingAtom;

    const handleCheckSlotAvailability = async () => {
        const time = moment(selectedDate)?.format("DD MM YYYY");
        const startTimeString = `${time} ${bookingAtom.selectedSlot}`;
        const startTime = moment.tz(
            startTimeString,
            "DD MM YYYY HH:mm",
            bookingData?.timezone
        );
        const endTime = startTime.clone().add(bookingAtom.mins, "minutes");
        const startDatetime = startTime.valueOf();
        const endDatetime = endTime.valueOf();
        const clubId = bookingData?._id;
        const courtId = bookingAtom.selectedCourt?._id;

        const payload = {
            startDatetime,
            endDatetime,
            clubId,
            courtId
        };

        checkAvailableSlot(payload).then((resp) => {
            if (resp?.is_slot_available) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 3
                });
            } else {
                Store.addNotification({
                    title: null,
                    message: "Slot is no longer available!",
                    type: "danger",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000
                    }
                });
                setErrorMessage("selected slot is not available");
            }
        });
    };

    const signInWithFacebook = () => {
        const provider = new FacebookAuthProvider();
        signInWithPopup(auth, provider).then((userCreds) => {
            // Signed in
            handleLoggedInUser(userCreds, null);
        });
    };

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then((userCreds) => {
            // Signed in
            handleLoggedInUser(userCreds, null);
        });
    };

    const onLogin = (e: React.FormEvent) => {
        setLoginErrorMessage("");
        e.preventDefault();

        if (!email) {
            setEmailError(`${t("Auth.emailRequired")}`);
        } else if (!emailPattern.test(email)) {
            setEmailError(`${t("Auth.invalidEmail")}`);
        } else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError(`${t("Auth.passwordRequired")}`);
        } else {
            setPasswordError("");
        }

        if (email && password) {
            setIsLoginError(false);
        } else {
            setIsLoginError(true);
        }

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {})
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setIsLoginError(true);
                setLoginErrorMessage(error.message);
            });
    };

    const handleCardElementSelect = (element: any) => {
        setCardElement(element);
    };

    useEffect(() => {
        if (currentUser === undefined || currentUser === null) {
            setBookingAtom({
                ...bookingAtom,
                page: 1
            });
        } else if (
            bookingAtom.page === 1 &&
            currentUser !== undefined &&
            currentUser !== null
        ) {
            setBookingAtom({
                ...bookingAtom,
                page: 2
            });
        }
    }, [currentUser]);

    // This is in-place, if the user decides to click on "Book xx price" on another court or time, so that the booking modal can reset". Book button sets the page to 1 usually.
    useEffect(() => {
        if (bookingAtom.page === 2) {
            setProcessing(false);
            setErrorMessage("");
        }
    }, [bookingAtom.page]);

    useEffect(() => {
        if (currentUser === undefined || currentUser === null) {
            setBookingAtom({
                ...bookingAtom,
                page: 1
            });
        }
        if (
            bookingAtom.page === 1 &&
            currentUser !== undefined &&
            currentUser !== null
        ) {
            setBookingAtom({
                ...bookingAtom,
                page: 2
            });
        } else if (bookingAtom.page === 2) {
            setProcessing(false);
            setErrorMessage("");
        }
    }, [bookingAtom.show]);

    useEffect(() => {
        if (voucher) {
            calculatePrice(voucher);
        }
    }, [voucher, bookingAtom.mins]);

    const handleApplyVoucher = (vouchercode: string) => {
        if (vouchercode && vouchercode.length) {
            setVoucherErrorMessage("");
            setVoucherSuccessMessage("");
            setApplyingVoucher(true);
            applyCoupon(
                bookingData._id,
                vouchercode,
                setApplyingVoucher,
                setVoucherErrorMessage
            ).then((voucherResp: any) => {
                if (voucherResp?.data?.status) {
                    setApplyingVoucher(false);
                    setVoucher(voucherResp?.data);
                    const message = voucherResp?.data.max_discount_amount
                        ? `You will get ${voucherResp?.data.amount_in_percentage}% discount upto ${voucherResp?.data.max_discount_amount}`
                        : `You will get ${voucherResp?.data.amount_in_percentage}% discount`;
                    setVoucherSuccessMessage(message);
                } else {
                    setApplyingVoucher(false);
                    setVoucherErrorMessage("failed to apply coupon");
                    setTimeout(() => {
                        setVoucherErrorMessage("");
                    }, 3000);
                }
            });
        }
    };

    const calculatePrice = (voucher: any) => {
        let discountPercentage = voucher.amount_in_percentage / 100;
        let discVal = discountPercentage
            ? bookingAtom.price * discountPercentage
            : 0;
        let totalPrice = bookingAtom.price - discVal;
        setBookingAtom({ ...bookingAtom, price: totalPrice });
        setDiscountAmount(discVal);
    };

    const closeOverlyOnClickHandlder = (event: React.MouseEvent) => {
        if (
            event.target === event.currentTarget ||
            (event.target as HTMLEmbedElement).classList.contains(
                "wrapper-overlay"
            )
        ) {
            setBookingAtom({
                ...bookingAtom,
                show: !bookingAtom.show
            });
            setVoucher(null);
            setDiscountAmount(0);
            setVoucherSuccessMessage("");
        }
    };

    if (!bookingAtom.show) {
        return null;
    }
    if (bookingAtom.page === 1) {
        if (currentUser) {
            return (
                <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                    <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                        <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                            <h4 className="text-2xl lg:text-3xl font-semibold">
                                Loading...
                            </h4>
                            <button
                                className="btn"
                                id="modal-close-btn"
                                onClick={closeOverlyOnClickHandlder}
                            >
                                <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                            </button>
                        </div>

                        <div className="my-10 flex flex-col gap-4 items-center justify-center">
                            <p className="text-xl">Please wait...</p>
                            <CgSpinner className="animate-spin text-[64px]" />
                        </div>
                    </div>
                </ClosableOverlay>
            );
        } else {
            return (
                <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                    <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                        <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                            <h4 className="text-2xl lg:text-3xl font-semibold">
                                {t("login")}
                            </h4>
                            <button
                                className="outline-none"
                                id="modal-close-btn"
                                onClick={() =>
                                    setBookingAtom({
                                        ...bookingAtom,
                                        show: !bookingAtom.show
                                    })
                                }
                            >
                                <AiOutlineClose className="w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                            </button>
                        </div>

                        <div className="overflow-y-auto px-5 py-10">
                            <div className="max-w-[511px] mx-auto w-full flex flex-col justify-center items-center">
                                <div className="flex flex-col justify-center items-center w-full">
                                    <h1 className="text-2xl md:text-3xl xl:text-4xl 2xl:text-[42px] text-center font-bold">
                                        {t("Auth.signInTitle")}
                                    </h1>
                                    <button
                                        className="mt-[35px] flex items-center justify-center gap-3 shadow-md rounded-full h-[56px] max-w-[400px] mx-auto w-full hover:scale-105 hover:opacity-90 transform duration-200"
                                        onClick={signInWithGoogle}
                                    >
                                        <FcGoogle className="w-6 h-6" />
                                        <p className="text-lg font-semibold">
                                            {t("Auth.signInGoogle")}
                                        </p>
                                    </button>
                                    <button
                                        className="mt-6 flex items-center justify-center gap-3 shadow-md rounded-full h-[56px] max-w-[400px] mx-auto w-full bg-[#576dff] text-white hover:scale-105 hover:opacity-90 transform duration-200"
                                        onClick={signInWithFacebook}
                                    >
                                        <BsFacebook className="w-6 h-6" />
                                        <p className="text-lg font-semibold">
                                            {t("Auth.signInFacebook")}
                                        </p>
                                    </button>
                                </div>
                                <div className="mt-10 flex items-center gap-2 w-full max-w-[400px] mx-auto">
                                    <div className="bg-[#d9d9d9] w-full h-[1px]" />
                                    <p className="text-[#222222b3] text-lg font-semibold">
                                        Or
                                    </p>
                                    <div className="bg-[#d9d9d9] w-full h-[1px]" />
                                </div>
                                {isLoginError && loginErrorMessage && (
                                    <label className="text-[#FF3A3A] text-center mt-5">
                                        {loginErrorMessage}!
                                    </label>
                                )}

                                <form className="flex flex-col mt-10 w-full max-w-[460px] mx-auto gap-6">
                                    <div className="w-full max-w-[400px] mx-auto">
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => {
                                                const newEmail =
                                                    e.currentTarget.value;
                                                setEmail(newEmail);
                                                if (newEmail) {
                                                    if (
                                                        !emailPattern.test(
                                                            newEmail
                                                        )
                                                    ) {
                                                        setEmailError(
                                                            `${t(
                                                                "Auth.invalidEmail"
                                                            )}`
                                                        );
                                                    } else {
                                                        setEmailError("");
                                                    }
                                                } else {
                                                    setEmailError(
                                                        `${t(
                                                            "Auth.emailRequired"
                                                        )}`
                                                    );
                                                }
                                            }}
                                            placeholder={t("Auth.emailAddress")}
                                            className={`${
                                                emailError
                                                    ? "border-red-500 outline-red-500"
                                                    : "border-[#000b2966] outline-primary"
                                            } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                                            required
                                        />
                                        {emailError && (
                                            <p className="text-red-500 mt-1 ml-2 text-xs">
                                                {emailError}
                                            </p>
                                        )}
                                    </div>
                                    <div className="w-full max-w-[400px] mx-auto">
                                        <div className="relative">
                                            <input
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                value={password}
                                                onChange={(e) => {
                                                    const newPassword =
                                                        e.currentTarget.value;
                                                    setPassword(newPassword);
                                                    newPassword
                                                        ? setPasswordError("")
                                                        : setPasswordError(
                                                              `${t(
                                                                  "Auth.passwordRequired"
                                                              )}`
                                                          );
                                                }}
                                                placeholder={t("Auth.password")}
                                                className={`${
                                                    passwordError
                                                        ? "border-red-500 outline-red-500"
                                                        : "border-[#000b2966] outline-primary"
                                                } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                                                required
                                            />

                                            <button
                                                type="button"
                                                onClick={togglePasswordView}
                                                className={`absolute top-1/2 -translate-y-1/2 right-2 p-1 text-slate-950 ${
                                                    showPassword
                                                        ? "text-opacity-50"
                                                        : "text-opacity-30"
                                                }`}
                                            >
                                                {showPassword ? (
                                                    <BsEyeFill size={18} />
                                                ) : (
                                                    <BsEyeSlash size={18} />
                                                )}
                                            </button>
                                        </div>
                                        {passwordError && (
                                            <p className="text-red-500 mt-1 ml-2 text-xs">
                                                {passwordError}
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex items-center justify-between flex-wrap gap-3 w-full max-w-[390px] mx-auto">
                                        <label
                                            htmlFor="staySignIn"
                                            className="text-lg flex items-center gap-2 cursor-pointer"
                                        >
                                            <input
                                                id="staySignIn"
                                                type="checkbox"
                                                className="w-5 h-5 cursor-pointer"
                                            />{" "}
                                            {t("Auth.staySignedIn")}
                                        </label>
                                        <Link
                                            className="text-lg hover:scale-105 hover:underline transform duration-200"
                                            style={{ color: "#222" }}
                                            to="/forgot-password"
                                        >
                                            {t("Auth.forgotPassword")}
                                        </Link>
                                    </div>
                                    <input
                                        onClick={onLogin}
                                        type="submit"
                                        value={t("MenuUser.signIn")}
                                        className="bg-secondary text-white rounded-full w-full max-w-[400px] mx-auto shadow-md h-[56px] flex items-center justify-center py-1 px-4 mt-5 text-xl font-semibold hover:bg-primary hover:text-secondary hover:scale-105 transform duration-200"
                                    />
                                </form>
                                <p className="flex text-lg justify-between items-center flex-wrap gap-2 mt-[35px]">
                                    {t("Auth.dontHaveAccount")}
                                    <Link
                                        style={{
                                            color: "#43EC9B",
                                            textDecoration: "none"
                                        }}
                                        to="/sign-up"
                                        className="font-semibold hover:scale-105 transform duration-200"
                                    >
                                        {t("MenuUser.signUp")}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </ClosableOverlay>
            );
        }
    } else if (bookingAtom.page === 2) {
        return (
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                    <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                        <h4 className="text-2xl lg:text-3xl font-semibold">
                            {t("BookingModal.checkout")}
                        </h4>
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className="overflow-y-auto px-5 relative">
                        {processing ? (
                            <div className="absolute left-0 right-0 top-0 bottom-0 bg-white z-50">
                                <div className="flex flex-col gap-4 items-center justify-center">
                                    <p className="text-center mt-7">
                                        {t("BookingModal.pleaseWait")}
                                    </p>
                                    <CgSpinner className="animate-spin text-[64px]" />
                                </div>
                            </div>
                        ) : null}

                        <CourtComponent
                            bookingData={bookingData}
                            isBooked={false}
                            minutes={bookingAtom.mins}
                            endTime={bookingAtom.endTime}
                            selectedCourt={bookingAtom.selectedCourt}
                            startTime={bookingAtom.startTime}
                            accessCode={accessCode}
                        />
                        <hr className="w-full" />
                        <div className="py-5">
                            <ExtraTimeManager
                                changeTime={calculateHours}
                                minutesSetter={minutesSetter}
                                priceSetter={priceSetter}
                                selectedCourt={bookingAtom.selectedCourt}
                                availableSlotState={
                                    bookingAtom.availableSlotState
                                }
                                minutes={bookingAtom.mins}
                                setMins={setMins}
                                time={bookingAtom.selectedSlot}
                                price={bookingAtom.price}
                                currency={currency}
                                setEndTime={setEndTime}
                                basePrice={bookingAtom.basePrice}
                            />
                            <hr className="w-full mt-4 mb-3" />
                            <Coupon
                                voucher={voucher}
                                setVoucher={setVoucher}
                                clubId={bookingData?._id}
                            />
                            <div className="text-center">
                                <SubTotalComponent
                                    currentPrice={bookingAtom.price}
                                    bookingData={bookingData}
                                    discountAmount={discountAmount}
                                    voucher={voucher}
                                />

                                <div className="font-semibold text-[#FF3A3A] text-center mt-7">
                                    {errorMessage}
                                </div>
                                <button
                                    className="px-16 text-[19px] font-semibold py-4 bg-secondary text-white rounded-3xl mt-7 w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                                    onClick={() => {
                                        handleCheckForNameAndPhoneNumber();
                                    }}
                                >
                                    {t("BookingModal.continue")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
        );
    } else if (bookingAtom.page === 3) {
        return (
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                    <PopupInfo
                        title={infoPopupTitle}
                        subTitle={infoPopupSubtitle}
                        isOpen={isLoadingPayment}
                        setIsOpen={setIsLoadingPayment}
                        isLoading={true}
                    />
                    <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                        <h4 className="text-2xl lg:text-3xl font-semibold">
                            {t("BookingModal.Payment")}
                        </h4>
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className="overflow-y-auto px-5">
                        <BookingDetails
                            startTime={bookingAtom.startTime}
                            endTime={bookingAtom.endTime}
                            minutes={bookingAtom.mins}
                            finalPrice={bookingAtom.price}
                            bookingData={bookingData}
                            selectedCourt={bookingAtom.selectedCourt}
                            showBookingDetails={false}
                            accessCode={accessCode}
                            isBooked={false}
                            receiptUrl={receiptUrl}
                        />

                        <div className="relative">
                            {processing ? (
                                <div className="absolute w-full h-full bg-white z-50">
                                    <div className="flex flex-col gap-4 items-center justify-center">
                                        <p className="text-center">
                                            {t("BookingModal.hold")}
                                        </p>
                                        <CgSpinner className="animate-spin text-[64px]" />
                                    </div>
                                </div>
                            ) : null}
                            <div
                                className={`${
                                    processing ? "h-0 overflow-hidden" : ""
                                }`}
                            >
                                <PaymentComponent
                                    price={bookingAtom.price}
                                    setSelectedCard={setPaymentMethodId}
                                    selectedCard={paymentMethodId}
                                    clubId={bookingData._id}
                                    currency={currency}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    userCredit={userCredit}
                                    setUserCredit={setUserCredit}
                                    setErrorMessage={setErrorMessage}
                                    saveCardForFutureUse={saveCardForFutureUse}
                                    setSaveCardForFutureUse={
                                        setSaveCardForFutureUse
                                    }
                                    cardDetails={cardDetails}
                                    setCardDetails={setCardDetails}
                                    handleCardElementSelect={
                                        handleCardElementSelect
                                    }
                                />
                            </div>

                            <SubTotalComponent
                                currentPrice={bookingAtom.price}
                                bookingData={bookingData}
                                discountAmount={discountAmount}
                                voucher={voucher}
                            />

                            <div className="my-10">
                                <div className="font-semibold text-[#FF3A3A] text-center mb-5">
                                    {errorMessage}
                                </div>
                                <div className="flex items-center justify-center gap-5">
                                    <button
                                        className="bg-white text-secondary border border-secondary px-4 py-1 h-[64px] flex items-center justify-center w-[calc(50%-20px)] rounded-[24px] text-[19px] font-semibold hover:bg-[#FF3A3A] hover:border-[#FF3A3A] hover:text-white transform duration-200"
                                        onClick={() => {
                                            setBookingAtom({
                                                ...bookingAtom,
                                                page: 2
                                            });
                                        }}
                                        disabled={processing}
                                    >
                                        {t("back")}
                                    </button>
                                    <button
                                        className="bg-secondary text-white border border-secondary px-4 py-1 h-[64px] flex items-center justify-center w-[calc(50%-20px)] rounded-[24px] text-[19px] font-semibold hover:bg-primary hover:border-primary hover:text-secondary transform duration-200"
                                        onClick={handlePayment}
                                        disabled={processing}
                                    >
                                        {t("BookingModal.pay")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
        );
    } else if (bookingAtom.page === 10) {
        return (
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                    <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                        <h4 className="text-2xl lg:text-3xl font-semibold">
                            {t("BookingModal.promoCode")}
                        </h4>
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className="overflow-y-auto px-5">
                        <PromoComponent />
                        <div className="mt-10">
                            <BookingDetails
                                startTime={bookingAtom.startTime}
                                endTime={bookingAtom.endTime}
                                minutes={bookingAtom.mins}
                                finalPrice={bookingAtom.price}
                                bookingData={bookingData}
                                selectedCourt={bookingAtom.selectedCourt}
                                showBookingDetails={false}
                                accessCode={accessCode}
                                isBooked={false}
                                receiptUrl={receiptUrl}
                            />
                            <ShareWithFriends />
                            <div className="flex flex-col items-center justify-center my-8 gap-2">
                                <img
                                    src="/images/verified.svg"
                                    alt="booked logo"
                                />
                                <p>{t("BookingModal.booked")}</p>
                            </div>
                            <div className="mb-10 flex items-center justify-center">
                                <button
                                    className="px-16 text-[19px] font-semibold py-4 bg-secondary text-white rounded-3xl w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                                    onClick={() =>
                                        setBookingAtom({
                                            ...bookingAtom,
                                            show: !bookingAtom.show
                                        })
                                    }
                                >
                                    {t("BookingModal.bookNow")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
        );
    } else if (bookingAtom.page === 11) {
        return (
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed w-[96%] md:min-w-[530px] md:w-[30%] bg-white text-secondary z-[10000] flex flex-col gap-0 rounded-md shadow-sm max-h-[80%]">
                    <div className="text-secondary pt-4 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-bold px-8">
                        <h4>{t("BookingModal.becomeMember")}</h4>
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className=" overflow-y-auto px-5">
                        <BecomeMemberForm
                            saveCardForFutureUse={saveCardForFutureUse}
                            setSaveCardForFutureUse={setSaveCardForFutureUse}
                            cardDetails={cardDetails}
                            setCardDetails={setCardDetails}
                            handleCardElementSelect={handleCardElementSelect}
                            cardElement={cardElement}
                        />
                    </div>
                </div>
            </ClosableOverlay>
        );
    } else if (bookingAtom.page === 12) {
        return (
            <>
                <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                    <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                        <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                            <h4 className="text-2xl lg:text-3xl font-semibold">
                                {t("BookingModal.checkout")}
                            </h4>
                            <button
                                className="btn"
                                id="modal-close-btn"
                                onClick={closeOverlyOnClickHandlder}
                            >
                                <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                            </button>
                        </div>
                        <div className="overflow-y-auto px-5">
                            <CourtComponent
                                bookingData={bookingData}
                                isBooked={false}
                                minutes={bookingAtom.mins}
                                endTime={bookingAtom.endTime}
                                selectedCourt={bookingAtom.selectedCourt}
                                startTime={bookingAtom.startTime}
                                accessCode={accessCode}
                            />
                            <hr className="w-full" />
                            <div className="py-5">
                                <UpdateUserInfo
                                    onBack={() => {
                                        setBookingAtom({
                                            ...bookingAtom,
                                            page: 2
                                        });
                                    }}
                                    onSubmit={handleUpdateUserInfo}
                                    userName={userName}
                                    userPhone={userPhone}
                                />
                            </div>
                        </div>
                    </div>
                </ClosableOverlay>
            </>
        );
    } else {
        return (
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                    <div className="flex justify-end p-5">
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className="overflow-y-auto px-5">
                        <div className="flex flex-col mt-10">
                            <div className="flex flex-col items-center justify-center">
                                <img
                                    src="/images/verified.svg"
                                    alt="booked logo"
                                />
                                <h2 className="text-[35px] font-bold text-center">
                                    {t("BookingModal.courtBooked")}
                                </h2>
                                <div className="text-[19px] mt-2 hover:underline">
                                    <Link to="/payment-history">
                                        {t("BookingModal.seeReceipts")}
                                    </Link>
                                </div>
                            </div>

                            <BookingDetails
                                startTime={bookingAtom.startTime}
                                endTime={bookingAtom.endTime}
                                minutes={bookingAtom.mins}
                                finalPrice={bookingAtom.price}
                                bookingData={bookingData}
                                selectedCourt={bookingAtom.selectedCourt}
                                showBookingDetails={true}
                                accessCode={accessCode}
                                isBooked={true}
                                receiptUrl={receiptUrl}
                            />

                            <div className="text-center my-10">
                                <button
                                    className="px-16 text-[19px] font-semibold py-4 bg-secondary text-white rounded-3xl w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                                    onClick={() =>
                                        setBookingAtom({
                                            ...bookingAtom,
                                            show: !bookingAtom.show
                                        })
                                    }
                                >
                                    {t("BookingModal.done")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
        );
    }
};

export default BookingModal;
