import axios from "axios";
import { getAuth } from "firebase/auth";

const Axios = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_API,
    headers: {
        "Content-Type": "application/json"
    }
});
Axios.interceptors.request.use(
    async (config) => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const token = await user.getIdToken();
            config.headers.authorization = `PadelMates ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default Axios;
