import React from "react";
import { ComponentDiscoverForm } from "./ComponentDiscoverForm";
import { useTranslation } from "react-i18next";

interface ComponentDiscoverProps {
    isHome?: boolean;
    searchClubs?: (clubName: string, sport: string, date: any) => void;
}

export const ComponentDiscover: React.FC<ComponentDiscoverProps> = ({
    isHome,
    searchClubs
}) => {
    const { t } = useTranslation();

    if (isHome) {
        return (
            <div className="w-full min-h-[300px] flex flex-col justify-center items-center  text-center text-[#ffffff] mt-0 px-4">
                <h1 className="lg:text-[30px] text-[20px] mt-6 sm:mt-0">
                    {t("Discover.subTitle")}
                </h1>
                <h2 className="lg:text-[48px] text-[30px] font-semibold mb-8">
                    {t("Discover.title")}
                </h2>
                <ComponentDiscoverForm
                    searchClubs={searchClubs}
                    color="bg-[#ffff]"
                />
            </div>
        );
    } else {
        return (
            <div className="w-full min-h-[300px] flex flex-col justify-center items-center text-center text-secondary bg-[#FBFBFB] pt-8 lg:pt-0">
                <h2 className="lg:text-[45px] text-[30px] font-semibold">
                    {t("Discover.discoverTitle")}
                </h2>

                <ComponentDiscoverForm
                    searchClubs={searchClubs}
                    color="bg-[#FBFBFB]"
                />

                <hr />
            </div>
        );
    }
};
