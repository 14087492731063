import React from "react";
import { CgSpinner } from "react-icons/cg";

interface PopupProps {
    title: string;
    subTitle: string;
    setIsOpen: any;
    isOpen: Boolean;
    isLoading: Boolean;
}

const PopupInfo: React.FC<PopupProps> = ({
    title,
    isOpen,
    setIsOpen,
    subTitle,
    isLoading
}) => {
    return isOpen ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#00000080] z-[4000]">
            <div className="bg-white p-5 rounded flex flex-col items-center justify-center">
                <h2 className="text-xl font-bold">{title}</h2>
                <p className="text-lg">{subTitle}</p>
                {isLoading && (
                    <CgSpinner className="animate-spin text-[64px]" />
                )}
            </div>
        </div>
    ) : null;
};

export default PopupInfo;
