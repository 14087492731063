import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to homepage after 3 seconds
        const timeout = setTimeout(() => {
            navigate("/");
        }, 5000);

        // Clean up the timeout when the component unmounts
        return () => {
            clearTimeout(timeout);
        };
    }, [navigate]);

    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.notFound")} | Padel Mates</title>
            </Helmet>
            <div
                className={`bg-[url("../public/images/signin-bg.png")] bg-no-repeat bg-left-bottom min-h-screen`}
            >
                <div className="flex flex-col justify-center items-center text-center text-secondary gap-5 px-4 sm:px-5 py-10 lg:py-24 2xl:py-32 mt-6 2xl:mt-12">
                    <h1 className="lg:text-6xl text-5xl font-semibold">
                        {t("NotFound.title")}
                    </h1>
                    <p className="lg:text-3xl text-2xl">
                        {t("NotFound.subTitle")}
                    </p>
                    <Link to="/">
                        <button className="min-h-[56px] py-2 px-8 border-2 border-secondary text-secondary bg-white xl:bg-secondary xl:text-white xl:hover:bg-primary xl:hover:text-secondary xl:hover:border-primary hover:bg-secondary hover:text-white hover:scale-105 transform duration-200 rounded-2xl font-semibold mt-6">
                            {t("NotFound.goBack")}
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default NotFound;
