import React from "react";

export const Header = () => {
    return (
        <section className="flex flex-col md:gap-8 gap-4 bg-[#FBFBFB] text-secondary items-center justify-center text-center min-h-[370px] md:px-8 px-4 md:pt-8">
            <h1 className="md:text-[45px] sm:text-4xl text-2xl font-semibold">
                Help Center
            </h1>
            <p className="md:text-[18px] text-[14px] max-w-[900px]">
                At Padel Mates Booking Centre, we're committed to providing our
                users with the best possible experience on our platform. If you
                have any questions, concerns, or feedback, please review the
                following resources or get in touch with our support team.
            </p>
            <div className="relative md:w-[650px]  p-2 flex-2 mt-4 w-full">
                <label className="absolute bg-[#FBFBFB] text-[#000b2999] top-[-3px] left-[22px] text-[14px] px-1">
                    Search
                </label>
                <input
                    className="w-full md:h-[60px] h-[50px] border-[1px] border-[#000b2999] rounded-[50px] placeholder-[#000B294D] text-[#000b2999] p-4"
                    type="text"
                    placeholder="Search for help here...."
                />
            </div>
        </section>
    );
};
