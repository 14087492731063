import { useState } from "react";

type useTogglePasswordViewHook = () => {
    showPassword: boolean;
    togglePasswordView: () => void;
};

const useTogglePasswordView: useTogglePasswordViewHook = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const togglePasswordView = () => {
        setShowPassword((prevState) => !prevState);
    };

    return {
        showPassword,
        togglePasswordView
    };
};

export default useTogglePasswordView;
