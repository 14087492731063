import React from "react";
import { useTranslation } from "react-i18next";
import { FiDownload } from "react-icons/fi";

export const ComponentPaymentHistoryCardRender = ({ currentCards = [] }) => {
    const { t } = useTranslation();
    return (
        <>
            {currentCards.length ? (
                currentCards.map((receipt, index) => (
                    <React.Fragment key={index}>
                        <div className="flex gap-[15%] justify-evenly items-center md:text-[19px] text-secondary font-medium py-4">
                            <div className="flex gap-3 items-center text-left w-[400px]">
                                <img
                                    src="/images/club-icon.png"
                                    alt="club icon"
                                    className="h-[63px] w-[63px]"
                                />
                                <p>{receipt.description}</p>
                            </div>
                            <div className="w-[400px]">
                                <p>{receipt.dateOfPayment}</p>
                            </div>
                            <div className="flex gap-2 items-center w-[400px]">
                                <a
                                    href={receipt.receiptUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <p className="text-primary hover:scale-105 transform duration-200">
                                        Download as PDF{" "}
                                        <FiDownload className="inline" />
                                    </p>{" "}
                                </a>
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>
                ))
            ) : (
                <div className="Payment-History-No-Receipts mt-4">
                    <h1>{t("PaymentHistoryCardRender.noReceipt")}</h1>
                </div>
            )}
        </>
    );
};
