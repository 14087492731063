import React, { useEffect, useRef, useState } from "react";
import ComponentMaps from "../Components/findCentres/ComponentMaps";

import { auth } from "../firebase";
import ComponentSearchCentresBar from "../Components/findCentres/ComponentSearchCentresBar";
import ComponentCentresList from "../Components/findCentres/ComponentCentresList";
import { onAuthStateChanged } from "firebase/auth";
import { getAllClubs, getSearchNearbyClubs } from "../Api/api";
import { CourtDimension, SportType } from "./BookingPage";
import { useSearchParams } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export const getClubsAndTotalItemsFromLocalStorage = () => {
    let clubs = localStorage.getItem("clubs");
    clubs = clubs ? JSON.parse(clubs) : [];

    let total_clubs = localStorage.getItem("total_clubs");
    total_clubs = total_clubs ? JSON.parse(total_clubs) : 0;

    return {
        clubs,
        total_clubs
    };
};
interface paginationRefHandle {
    resetPagination(): void;
}
const FIndCentresPage = () => {
    const paginationRef = useRef<paginationRefHandle>(null);
    const [data, setData] = useState([]);
    const [markers, setMarkers] = useState([]);
    const itemsPerPage = 250;
    const [totalItems, setTotalItems] = useState(0);

    const [loadingData, setLoadingData] = useState(false);
    // const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [radius, setRadius] = useState<number>(50);
    const [queryParams] = useSearchParams();

    const [currentUser, setCurrentUser] = useState<any>(null);

    const [sports] = useState<SportType[]>([
        "padel",
        "tennis",
        "squash",
        "badminton",
        "pickleball"
    ]);
    const [courtDimension] = useState<CourtDimension[]>(["single_courts"]);
    const searchNearByClubs = async (
        sport?: string | undefined,
        clubName?: string | undefined,
        pageNumber?: number,
        isOnPageLoad?: boolean
        // date: any
    ) => {
        setLoadingData(true);
        const selectedSport =
            sport === "all" || sport == undefined || sport === ""
                ? []
                : [sport];
        // setSelectedStartDate(date);
        let data = {
            radius,
            latitude: 78.9012,
            longitude: 12.3456,
            sports,
            courtDimension,
            clubName,
            selectedSport,
            // date,
            currentUser,
            pageNumber
        };
        const defaultResult = await getSearchNearbyClubs(data);
        if (
            defaultResult?.data?.searched_clubs &&
            defaultResult.data.searched_clubs.length > 0
        ) {
            const filteredClubs = defaultResult.data.searched_clubs.filter(
                (club: any) => club.permissions.mobile_booking === true
            );
            if (!isOnPageLoad) {
                setMarkers(filteredClubs);
            }
            setData(filteredClubs);
            setTotalItems(defaultResult.data.total_clubs);
            // localStorage.setItem("clubs", JSON.stringify(defaultResult.data.searched_clubs));
        } else {
            if (!isOnPageLoad) {
                setMarkers([]);
            }
            setData([]);
            setTotalItems(0);
        }
        setLoadingData(false);
    };
    const onPageChange = (pageNumber: number, isOnPageLoad?: boolean) => {
        // substructing by 1 as api page count from 0
        // pageNumber = pageNumber - 1;

        searchNearByClubs(
            queryParams.get("sport") ?? "",
            queryParams.get("location") ?? "",
            itemsPerPage * pageNumber,
            isOnPageLoad
        );
    };
    const handleSearch = (
        sport?: string | undefined,
        clubName?: string | undefined
    ) => {
        paginationRef.current?.resetPagination();
        searchNearByClubs(sport, clubName);
    };
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });
    }, []);
    useEffect(() => {
        (async () => {
            const defaultResult = await getAllClubs({ sports });

            if (
                defaultResult?.data?.searched_clubs &&
                defaultResult.data.searched_clubs.length > 0
            ) {
                setMarkers(defaultResult.data.searched_clubs);
            } else {
                setMarkers([]);
            }
        })();
        onPageChange(0, true);
    }, [currentUser]);

    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.findCentres")} | Padel Mates</title>
            </Helmet>
            <div className="mx-auto lg:mt-8 mt-20">
                <ComponentMaps markers={markers} />
                <ComponentSearchCentresBar searchCentre={handleSearch} />
                <div className="mt-16 mb-20 relative min-h-[300px] mx-auto w-full sm:w-[85%]">
                    <div
                        className={`flex flex-col items-center justify-center h-full w-full absolute left-0 top-0 bg-gray-100 bg-opacity-90 ${
                            loadingData ? "" : " hidden"
                        }`}
                    >
                        <div className="flex flex-col gap-4 items-center justify-center">
                            <p className="text-xl">
                                Searching for locations...
                            </p>
                            <CgSpinner className="animate-spin text-[64px]" />
                        </div>
                    </div>
                    <ComponentCentresList
                        data={data}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        currentUser={currentUser}
                        onPageChange={onPageChange}
                        ref={paginationRef}
                    />
                </div>
            </div>
        </>
    );
};

export default FIndCentresPage;
