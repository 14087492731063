import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { updateClubInfo } from "../../Api/api";
import { CgSpinner } from "react-icons/cg";
import { AuthContext } from "../AuthProvider";

interface ClubProfileAboutProps {
    clubData: any;
    following: boolean;
    setFollowing: Dispatch<SetStateAction<boolean>>;
}

const ClubProfileAbout: React.FC<ClubProfileAboutProps> = ({
    clubData,
    following,
    setFollowing
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentUser = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [showFullText, setShowFullText] = useState(false);

    const handleFollowClub = () => {
        const clubId = clubData?._id;
        if (!currentUser) {
            const queryParams = new URLSearchParams();
            queryParams.set("page", `/club/${clubId}`);
            queryParams.set("action", "follow");
            const existingParams = new URLSearchParams(window.location.search);
            existingParams.forEach((value, key) => {
                queryParams.set(key, value);
            });
            const queryString = queryParams.toString();
            const url = `/sign-in?${queryString}`;

            navigate(url);
            return false;
        }
        setLoading(clubId);
        const body = {
            following: following ? false : true,
            notification: {
                match_post: true,
                tournament_post: true,
                training_post: true
            }
        };
        updateClubInfo(clubId, currentUser?.uid, body).then(
            (followClubResp: any) => {
                if (followClubResp?.data.success) {
                    if (following) setFollowing(false);
                    else setFollowing(true);
                    setLoading(false);
                }
            }
        );
    };

    return (
        <div className="md:mt-5 px-4 flex flex-col gap-8 text-secondary">
            <div className="flex gap-4 flex-wrap">
            <div className="md:hidden md:mt-5 flex flex-col gap-4">
                <div className="w-[95px] h-[95px] border border-[#000b29]/40 rounded-[6px]">
                    <img
                        src={clubData?.photo_url}
                        className="w-[94px] h-[94px] object-cover rounded-[6px]"
                        alt="Club Profile"
                    />
                </div>
                <button
                    className={`text-sm flex justify-center items-center rounded-full px-1 border border-[#00081e] w-full max-w-[95px] min-h-[27px] font-medium hover:bg-primary hover:text-secondary transform duration-200
            ${
                following
                    ? "text-[#00081e] bg-white"
                    : "text-white bg-[#00081e]"
            }`}
                    onClick={() => handleFollowClub()}
                >
                    {following ? (
                        <span className="flex justify-center items-center gap-1">
                            {t("Profile.following")}
                            {loading ? (
                                <CgSpinner
                                    size="14px"
                                    className="animate-spin"
                                />
                            ) : null}
                        </span>
                    ) : (
                        <span className="flex justify-center items-center gap-1">
                            {t("follow")} +
                            {loading ? (
                                <CgSpinner
                                    size="14px"
                                    className="animate-spin"
                                />
                            ) : null}
                        </span>
                    )}
                </button>
            </div>
            <div className="text-left">
                <h1 className="font-semibold text-2xl">{clubData?.name}</h1>
                {/* <p className="text-[17px]">{clubData?.address}</p> */}
                <p className="text-sm mt-3 text-[#171717]/80">
                    {showFullText
                        ? clubData?.about_the_hall
                        : `${clubData?.about_the_hall?.slice(0, 150)}...`}{" "}
                    <span
                        className="underline font-medium text-sm text-[#171717]/80 cursor-pointer"
                        onClick={() => setShowFullText(!showFullText)}
                    >
                        {showFullText ? "Read less" : "Read more"}
                    </span>
                </p>
            </div>
            </div>
            <hr />
            <div>
                <h2 className="font-semibold text-xl mb-3">{t("contact")}</h2>
                <div className="text-lg flex flex-col gap-2.5">
                    {clubData?.address && (
                        <p className="flex gap-2 xl:gap-4 items-center text-xl">
                            <img
                                src="/images/bxs-direction-right.svg"
                                className="h-[21px] w-[21px]"
                                alt="direction"
                            />
                            <a
                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                    clubData?.address
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-[15px]"
                            >
                                {clubData?.address}
                            </a>
                        </p>
                    )}
                    {clubData?.phone_number && (
                        <p className="flex gap-2 xl:gap-4 items-center text-xl">
                            <img
                                src="/images/bxs-phone-call.svg"
                                className="h-[21px] w-[21px]"
                                alt="phone"
                            />
                            <a
                                href={`tel:${clubData?.phone_number}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-[15px]"
                            >
                                {clubData?.phone_number}
                            </a>
                        </p>
                    )}
                    {clubData?.email && (
                        <p className="flex gap-2 xl:gap-4 items-center flex-wrap text-xl">
                            <img
                                src="/images/bxs-envelope.svg"
                                className="h-[21px] w-[21px]"
                                alt="email"
                            />
                            <a
                                className="break-words text-[15px]"
                                href={`mailto:${clubData?.contact?.contact_email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {clubData?.contact?.contact_email}
                            </a>
                        </p>
                    )}
                    {clubData?.contact?.website && (
                        <p className="flex gap-2 xl:gap-4 items-center flex-wrap text-xl">
                            <img
                                src="/images/internet.svg"
                                className="h-[21px] w-[21px]"
                                alt="website"
                            />
                            <a
                                className="text-[15px]"
                                href={
                                    clubData?.contact?.website?.startsWith(
                                        "http"
                                    )
                                        ? clubData?.contact?.website
                                        : `http://${clubData?.contact?.website}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {clubData?.contact?.website}
                            </a>
                        </p>
                    )}

                    {/* <div className="flex gap-2 xl:gap-4 items-center">
                        <img
                            src="/images/chat.svg"
                            className="h-[21px] w-[21px]"
                            alt="message"
                        />
                        <p className="flex flex-col">
                            <span className="underline cursor-pointer">
                            {t('Profile.sendMessage')}
                            </span>{" "}
                            <span className="text-sm">
                            Typically replies <span className="font-medium">in days</span>
                            </span>
                        </p>
                    </div> */}
                </div>
            </div>
            <hr />
            <div>
                <h2 className="text-xl font-semibold">
                    {t("Profile.openingHours")}
                </h2>
                <div className="flex flex-col gap-2.5 mt-3">
                    {clubData &&
                        Object.entries(clubData?.opening_hours).map(
                            (openingHour: any, index) => (
                                <p
                                    key={index}
                                    className="flex justify-between gap-2 flex-wrap text-[15px]"
                                >
                                    <span className="capitalize">
                                        {openingHour[0]}
                                    </span>
                                    <span>
                                        {openingHour[1]?.opening} -{" "}
                                        {openingHour[1]?.closing}
                                    </span>
                                </p>
                            )
                        )}
                    {/* <p className="flex justify-between gap-2 flex-wrap">
            <span>Tuesday</span>
            <span>06: 00 - 23: 59</span>
          </p>
          <p className="flex justify-between gap-2 flex-wrap">
            <span>Wednesday</span>
            <span>06: 00 - 23: 59</span>
          </p>
          <p className="flex justify-between gap-2 flex-wrap">
            <span>Thursday</span>
            <span>06: 00 - 23: 59</span>
          </p>
          <p className="flex justify-between gap-2 flex-wrap">
            <span>Friday</span>
            <span>06: 00 - 23: 59</span>
          </p>
          <p className="flex justify-between gap-2 flex-wrap">
            <span>Saturday</span>
            <span>06: 00 - 23: 59</span>
          </p>
          <p className="flex justify-between gap-2 flex-wrap">
            <span>Sunday</span>
            <span>06: 00 - 23: 59</span>
          </p> */}
                </div>
            </div>
        </div>
    );
};

export default ClubProfileAbout;
