import React, { useContext, useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoCalendar } from "react-icons/io5";
import { Combobox } from "@headlessui/react";
import { AuthContext } from "../AuthProvider";
import { getSearchNearbyClubs } from "../../Api/api";

export const ComponentDiscoverForm = ({ searchClubs, color }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const inputRef = useRef(null);
    // const [displayClub, setDisplayClub] = useState("");
    const [showClubs, setShowClubs] = useState(false);
    const [clubs, setClubs] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = Object.fromEntries(searchParams);
    const currentUser = useContext(AuthContext);
    // TODO: Show date according to users timezone
    const curr = moment(queryParams.date || queryParams.selectedDate).tz(
        moment.tz.guess()
    );
    const date = curr.format("YYYY-MM-DD");
    const [sport, setSport] = useState(queryParams.sport ?? ""); // State for sport selection
    const [selectedDate, setSelectedDate] = useState(date); // State for selected date
    const [location, setLocation] = useState(queryParams.location ?? ""); // State for location input
    // debugger;
    /*  useEffect(() => {
        const isBookingPage = window.location.pathname === "/booking"; // Update with the correct path for BookingPage component
        if (isBookingPage && queryParams) {
            setSport(queryParams.sport);
            setSelectedDate(queryParams.date);
            setLocation(queryParams.location);
        }
    }, []); */

    useEffect(() => {
        if (!selectedDate) {
            setSelectedDate(date);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current?.contains(event.target)) {
                setShowClubs(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    const handleSportChange = (event) => {
        setSport(event.target.value);
    };

    const handleDateChange = (date) => {
        const formattedDate = date ? date.toISOString().substring(0, 10) : "";
        setSelectedDate(formattedDate);
        // localStorage.setItem("selectedDate", JSON.stringify(formattedDate));
    };

    // const handleLocationChange = (event) => {
    //     setLocation(event.target.value);
    // };

    const getClubs = (formData) => {
        // Check if already on BookingPage
        const isBookingPage = window.location.pathname === "/booking"; // Update with the correct path for BookingPage component

        // Create the query string
        const queryParams = new URLSearchParams();
        if (sport) {
            queryParams.append("sport", sport);
        }
        if (selectedDate) {
            queryParams.append("date", selectedDate);
        }
        if (location) {
            queryParams.append("location", location);
        } else if (formData.club) {
            queryParams.append("location", formData.club);
        }
        // Redirect to BookingPage if not already on it
        if (isBookingPage) {
            setSearchParams(queryParams.toString());
        } else {
            navigate({
                pathname: "/booking", // Update with the correct path for BookingPage component
                search: queryParams.toString()
            });
        }
        searchClubs(location, sport, selectedDate);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setClubs([]);

        // Handle form submission logic here
        // You can access the selected sport, date, and location from the corresponding state variables (sport, selectedDate, location)

        localStorage.removeItem("selectedDate");
        // localStorage.removeItem("clubs");
        localStorage.setItem("selectedDate", JSON.stringify(selectedDate));
        let formData = {
            sport,
            selectedDate,
            location
        };
        getClubs(formData);
    };

    const handelClubClick = (club) => {
        setClubs([]);
        setLocation(club.name);
        // Handle form submission logic here
        // You can access the selected sport, date, and location from the corresponding state variables (sport, selectedDate, location)
        localStorage.removeItem("selectedDate");
        // localStorage.removeItem("clubs");
        localStorage.setItem("selectedDate", JSON.stringify(selectedDate));
        let formData = {
            sport,
            selectedDate,
            club: club.name
        };
        getClubs(formData);
    };

    const searchNearByClubs = async (clubName) => {
        setClubs([]);
        let data = {
            // radius,
            latitude: 78.9012,
            longitude: 12.3456,
            clubName,
            currentUser
        };

        const defaultResult = await getSearchNearbyClubs(data);
        if (
            defaultResult?.data?.searched_clubs &&
            defaultResult.data.searched_clubs.length > 0
        ) {
            setClubs(defaultResult.data.searched_clubs);
        }
    };

    return (
        <div className="">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                }}
                className={`${color} lg:flex lg:flex-row lg:justify-center lg:items-center flex flex-col flex-wrap items-center px-8 py-6 md:rounded-[100px] rounded-[50px]`}
            >
                <div className="relative p-2 cursor-pointer">
                    <div className="bg-white">
                        <select
                            className="min-w-[250px] h-[60px] bg-white border-[1px] border-black/60 rounded-[50px] text-black/60 p-4 cursor-pointer appearance-none outline-primary placeholder-black/60 text-[15px]"
                            value={sport}
                            onChange={handleSportChange}
                            style={{
                                background:
                                    "url(/images/down-arrow.png) no-repeat 92% 50%"
                            }}
                        >
                            <option className="pt-2 pb-1 block" value="all">
                                {t("Discover.form.Select.opt-5")}
                            </option>
                            <option className="py-1 block" value="padel">
                                {t("Discover.form.Select.opt-2")}
                            </option>
                            <option className="py-1 block" value="tennis">
                                {t("Discover.form.Select.opt-1")}
                            </option>
                            <option className="py-1 block" value="badminton">
                                Badminton
                            </option>
                            <option className="py-1 block" value="pickleball">
                                Pickleball
                            </option>
                            <option className="pt-1 pb-2 block" value="squash">
                                Squash
                            </option>
                        </select>
                    </div>
                </div>
                <div className="relative p-2 cursor-pointer">
                    <div className="relative">
                        <DatePicker
                            selected={
                                selectedDate
                                    ? new Date(selectedDate)
                                    : new Date()
                            }
                            minDate={new Date(new Date().getTime() - (24 * 60 * 60 * 1000))}
                            dateFormat={"dd/MM/yyyy"}
                            onChange={handleDateChange}
                            className="min-w-[250px] w-full h-[60px] bg-white border-[1px] border-black/60 rounded-[50px] text-black/60 p-4 cursor-pointer outline-primary placeholder-black/60 text-[15px]"
                        />
                        <IoCalendar
                            size={22}
                            className="text-black/60 absolute right-[15px] top-[18px] z-0 pointer-events-none touch-none"
                        />
                    </div>
                </div>
                <Combobox>
                    {({ open }) => (
                        <>
                            <div className="relative p-2">
                                <Combobox.Input
                                    ref={inputRef}
                                    //  onFocus={() => setShowClubs(true)}
                                    className="lg:min-w-[350px] w-[250px] h-[60px] border-[1px] border-black/60 rounded-[50px] placeholder-black/60 text-black/60 p-4 outline-primary text-[15px]"
                                    placeholder={t("Discover.form.placeholder")}
                                    value={location}
                                    onChange={(event) => {
                                        setShowClubs(true);
                                        const inputValue = event.target.value;
                                        setLocation(inputValue);
                                        if (inputValue && inputValue.length) {
                                            searchNearByClubs(inputValue);
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            // const tempClub = clubs.find(
                                            //     (club) =>
                                            //         club.name === e.target.value
                                            // );

                                            // if (tempClub) {
                                            //     handelClubClick(tempClub);
                                            // } else if (tempClub) {
                                            handelClubClick({
                                                name: location
                                            });
                                            // }
                                        }
                                    }}
                                />
                                <BsSearch
                                    size={22}
                                    className="text-black/60 absolute right-6 top-[27px] z-0 pointer-events-none touch-none"
                                />
                                {showClubs && clubs.length > 0 && (
                                    <Combobox.Options
                                        static
                                        className="absolute z-10 w-full bg-white border border-[#000b2999] rounded-[25px] text-black/60 h-max max-h-[300px] overflow-y-auto mt-2 text-[15px]"
                                    >
                                        <Combobox.Option
                                            value={location}
                                            onClick={() => {
                                                handelClubClick({
                                                    name: location
                                                });
                                            }}
                                        >
                                            {({ active }) => (
                                                <div
                                                    className={`cursor-pointer capitalize px-3 py-1 text-left w-full min-h-[30px] h-max text-sm ${
                                                        active
                                                            ? "bg-secondary text-white"
                                                            : "hover:bg-secondary hover:text-white"
                                                    }`}
                                                >
                                                    {location}
                                                </div>
                                            )}
                                        </Combobox.Option>

                                        {clubs.map((club, index) => (
                                            <Combobox.Option
                                                key={index}
                                                value={club.name}
                                                onClick={() => {
                                                    handelClubClick(club);
                                                }}
                                            >
                                                {({ active }) => (
                                                    <div
                                                        className={`cursor-pointer px-3 py-2 text-left w-full min-h-[30px] h-max text-sm ${
                                                            active
                                                                ? "bg-secondary text-white"
                                                                : "hover:bg-secondary hover:text-white"
                                                        }`}
                                                    >
                                                        {club.name}
                                                    </div>
                                                )}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}
                            </div>
                        </>
                    )}
                </Combobox>

                <button
                    className="bg-secondary m-2 cursor-pointer text-white font-semibold lg:w-[170px] w-[250px] h-[60px] flex justify-center gap-4 items-center rounded-[50px]  hover:bg-primary group hover:text-secondary transform duration-200"
                    onClick={(e) => handleSubmit(e)}
                >
                    {t("Discover.form.Submit")}
                    <BsSearch className="group-hover:text-secondary w-5 h-5" />
                </button>
            </form>
        </div>
    );
};
