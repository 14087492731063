import React
// , { useContext, useEffect, useState }
from "react";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../AuthProvider";
// import { updateClubInfo } from "../../Api/api";
// import { CgSpinner } from "react-icons/cg";
// import { useTranslation } from "react-i18next";
interface ClubProfileHeroProps {
    clubData: any;
}

const ClubProfileHero: React.FC<ClubProfileHeroProps> = ({ clubData }) => {
    // const currentUser = useContext(AuthContext);
    // const [loading, setLoading] = useState(false);
    // const [following, setFollowing] = useState(false);

    // const { t } = useTranslation();

    // useEffect(() => {
    //     if (clubData) {
    //         setFollowing(clubData?.following);
    //     }
    // }, [clubData]);

    // const navigate = useNavigate();

    // const handleFollowClub = () => {
    //     const clubId = clubData?._id;
    //     if (!currentUser) {
    //         const queryParams = new URLSearchParams();
    //         queryParams.set("page", `/club/${clubId}`);
    //         queryParams.set("action", "follow");
    //         const existingParams = new URLSearchParams(window.location.search);
    //         existingParams.forEach((value, key) => {
    //             queryParams.set(key, value);
    //         });
    //         const queryString = queryParams.toString();
    //         const url = `/sign-in?${queryString}`;

    //         navigate(url);
    //         return false;
    //     }
    //     setLoading(clubId);
    //     const body = {
    //         following: following ? false : true,
    //         notification: {
    //             match_post: true,
    //             tournament_post: true,
    //             training_post: true
    //         }
    //     };
    //     updateClubInfo(clubId, currentUser?.uid, body).then(
    //         (followClubResp: any) => {
    //             if (followClubResp?.data.success) {
    //                 if (following) setFollowing(false);
    //                 else setFollowing(true);
    //                 setLoading(false);
    //             }
    //         }
    //     );
    // };

    return (
        <div>
            <img src="/images/club-profile-cover.jpg" alt="club profile cover" className="w-full h-full object-cover max-h-[243px] hidden md:block" />
            <img src="/images/club-profile-cover-sm.jpg" alt="club profile cover" className="md:hidden w-full  object-cover h-[140px]" />
        </div>
        // <div className="px-4 md:px-16 bg-[#FBFBFB] shadow">
        //     <div
        //         style={{
        //             backgroundImage: `url('${clubData?.photo_url}')`
        //         }}
        //         className={`relative max-w-[1412px] mx-auto h-[180px] sm:h-[280px] lg:h-[320px] xl:h-[380px] 2xl:h-[507px] bg-no-repeat ${
        //             clubData?.photo_url ? "bg-contain" : "bg-cover"
        //         } bg-center shadow-sm shadow-white`}
        //     >
        //         <button
        //             className={`text-[15px] py-1 px-3 border border-[#00081e] w-full h-full max-w-[100px] max-h-[40px] sm:max-w-[142px] sm:max-h-[52px] font-medium rounded-sm absolute bottom-4 right-4 sm:bottom-6 sm:right-6 2xl:bottom-9 hover:bg-primary hover:text-secondary transform duration-200
        //     ${following ? "bg-[#00081e] text-white" : "bg-white"}`}
        //             onClick={() => handleFollowClub()}
        //         >
        //             {following ? (
        //                 <span className="flex justify-center items-center gap-1">
        //                     {t("Profile.following")}
        //                     {loading ? (
        //                         <CgSpinner className="animate-spin" />
        //                     ) : null}
        //                 </span>
        //             ) : (
        //                 <span className="flex justify-center items-center gap-1">
        //                     {t("follow")} +
        //                     {loading ? (
        //                         <CgSpinner className="animate-spin" />
        //                     ) : null}
        //                 </span>
        //             )}
        //         </button>
        //     </div>
        //     <div className="text-center pt-9 pb-6 flex flex-col gap-3">
        //         <h1 className="text-3xl xl:text-4xl 2xl:text-[45px] 2xl:leading-[54px] text-secondary font-semibold capitalize">
        //             {clubData?.name}
        //         </h1>
        //         <p className="text-[22px] capitalize">{clubData?.city}</p>
        //     </div>
        // </div>
    );
};

export default ClubProfileHero;
