import React from "react";
import ClubProfileMain from "../Components/clubProfile/ClubProfileMain";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const ClubProfile = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Helmet>
                <title>{t("Title.clubProfile")} | Padel Mates</title>
            </Helmet>
            <ClubProfileMain />
        </div>
    );
};

export default ClubProfile;
