import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ComponentHomeClubInfo } from "./ComponentHomeClubInfo";

export const ComponentHomeClubPlatform = () => {
    const { t } = useTranslation();

    const [btnActive, setBtnActive] = useState("Booking");

    const bookingTrue = () => {
        setBtnActive("Booking");
    };
    const statsTrue = () => {
        setBtnActive("Statistics");
    };
    const aiTrue = () => {
        setBtnActive("AI");
    };
    const clubTrue = () => {
        setBtnActive("Club");
    };

    return (
        <div className="bg-[#43ec9b0d] px-4 md:px-16 py-20 flex flex-col items-center text-center text-secondary">
            <p className="text-xl font-medium tracking-widest">
                {t("HomeClubPlatform.p-1")}
            </p>
            <h2 className="text-center mx-auto text-3xl xl:text-4xl 2xl:text-[45px] 2xl:leading-[55px] font-semibold w-full mt-2">
                {t("HomeClubPlatform.title")}
            </h2>
            <div className="flex flex-wrap gap-2 sm:text-xl items-center mt-8 w-full max-w-[550px] justify-between">
                <button
                    onClick={() => bookingTrue()}
                    className={
                        btnActive === "Booking"
                            ? "outline-none font-semibold border-b-4 border-b-primary"
                            : "outline-none font-semibold "
                    }
                >
                    {t("HomeClubPlatform.BtnsDiv.btn-1")}
                </button>
                <button
                    onClick={() => statsTrue()}
                    className={
                        btnActive === "Statistics"
                            ? "outline-none font-semibold border-b-4 border-b-primary"
                            : "outline-none font-semibold "
                    }
                >
                    {t("HomeClubPlatform.BtnsDiv.btn-2")}
                </button>
                <button
                    onClick={() => aiTrue()}
                    className={
                        btnActive === "AI"
                            ? "outline-none font-semibold border-b-4 border-b-primary"
                            : "outline-none font-semibold "
                    }
                >
                    {t("HomeClubPlatform.BtnsDiv.btn-3")}
                </button>
                <button
                    onClick={() => clubTrue()}
                    className={
                        btnActive === "Club"
                            ? "outline-none font-semibold border-b-4 border-b-primary"
                            : "outline-none font-semibold "
                    }
                >
                    {t("HomeClubPlatform.BtnsDiv.btn-4")}
                </button>
            </div>
            <ComponentHomeClubInfo activePage={btnActive} />
        </div>
    );
};
