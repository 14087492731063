export const getFirstLettersFromWords = function (name: string) {
    let names = name.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};
// getFirstLettersFromWords('Faridul Hassan') ---> "FH"

export const generateGameType = (title: string) => {
    const lowerCaseTitle = title?.toLowerCase();
    switch (true) {
        case lowerCaseTitle?.includes("padel"):
            return "/images/padelGame.png";
        case lowerCaseTitle?.includes("tennis"):
            return "/images/tennisGame.png";
        case lowerCaseTitle?.includes("badminton"):
            return "/images/badmintonGame.png";
        case lowerCaseTitle?.includes("squash"):
            return "/images/squashGame.png";
        case lowerCaseTitle?.includes("pickleball"):
            return "/images/pickleballGame.png";
        default:
            return "/images/padelGame.png";
    }
};
