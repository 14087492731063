import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook, BsEyeFill, BsEyeSlash } from "react-icons/bs";
import {
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    FacebookAuthProvider,
    UserCredential,
    signOut
} from "firebase/auth";
import { auth } from "../firebase";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AxiosFastAPI from "../Api/AxiosFastAPI";
import { userLoginWithoutFCMToken } from "../Api/api.ts";
import { useTranslation } from "react-i18next";
import Axios from "../Api/Axios.js";
import { Helmet } from "react-helmet-async";
import useTogglePasswordView from "../hooks/useTogglePasswordView.ts";

const SignInForm: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const queryParams = Object.fromEntries(searchParams);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const { showPassword, togglePasswordView } = useTogglePasswordView();

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const signInWithFacebook = () => {
        const provider = new FacebookAuthProvider();
        signInWithPopup(auth, provider).then((userCreds) => {
            // Signed in
            handleLoggedInUser(userCreds, navigate, queryParams);
        });
    };

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then((userCreds) => {
            // Signed in
            handleLoggedInUser(userCreds, navigate, queryParams);
        });
    };

    const onLogin = (e: React.FormEvent) => {
        setErrorMessage("");
        e.preventDefault();

        if (!email) {
            setEmailError(`${t("Auth.emailRequired")}`);
        } else if (!emailPattern.test(email)) {
            setEmailError(`${t("Auth.invalidEmail")}`);
        } else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError(`${t("Auth.passwordRequired")}`);
        } else {
            setPasswordError("");
        }

        if (email && password) {
            setIsError(false);
        } else {
            setIsError(true);
        }

        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                const destination = queryParams?.page;
                const queryString = new URLSearchParams(queryParams).toString();
                const redirectURL = queryString
                    ? `${destination}?${queryString}`
                    : destination;
                navigate(redirectURL == null ? "/" : redirectURL);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setIsError(true);
                setErrorMessage(error.message);
            });
        // axios.post(`${process.env.REACT_APP_APILINK}/login`,JSON.stringify({email, password}))
        // .then((res) =>
        // {
        //     console.log(res)
        //     setData(res.data.filtered_clubs);
        // })
        // .catch(err => console.error(err));
    };
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Sign in | Padel Mates</title>
            </Helmet>
            <div
                className={`bg-[url("../public/images/signin-bg.png")] bg-no-repeat bg-left-bottom`}
            >
                <div className="max-w-[550px] mx-auto w-full flex flex-col justify-center items-center px-4 sm:px-5 py-10 lg:py-24 ">
                    <div className="flex flex-col justify-center items-center w-full lg:mt-6 2xl:mt-12">
                        <h1 className="text-2xl md:text-3xl xl:text-4xl 2xl:text-[42px] text-center font-bold">
                            {t("Auth.signInTitle")}
                        </h1>
                        <button
                            className="mt-[35px] flex items-center justify-center gap-3 shadow-md rounded-full h-[56px] max-w-[400px] mx-auto w-full transform duration-200 hover:scale-105 hover:opacity-90"
                            onClick={signInWithGoogle}
                        >
                            <FcGoogle className="w-6 h-6" />
                            <p className="text-lg font-semibold">
                                {" "}
                                {t("Auth.signInGoogle")}
                            </p>
                        </button>
                        <button
                            className="mt-6 flex items-center justify-center gap-3 shadow-md rounded-full h-[56px] max-w-[400px] mx-auto w-full bg-[#576dff] text-white transform duration-200 hover:scale-105 hover:opacity-90"
                            onClick={signInWithFacebook}
                        >
                            <BsFacebook className="w-6 h-6" />
                            <p className="text-lg font-semibold">
                                {" "}
                                {t("Auth.signInFacebook")}
                            </p>
                        </button>
                    </div>
                    <div className="mt-10 flex items-center gap-2 w-full max-w-[400px] mx-auto">
                        <div className="bg-[#d9d9d9] w-full h-[1px]" />
                        <p className="text-[#222222b3] text-lg">Or</p>
                        <div className="bg-[#d9d9d9] w-full h-[1px]" />
                    </div>
                    {isError && errorMessage && (
                        <label className="text-red-500 text-center mt-5">
                            {errorMessage}
                        </label>
                    )}

                    <form className="flex flex-col mt-10 w-full max-w-[460px] mx-auto gap-6">
                        <div className="w-full max-w-[400px] mx-auto">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    const newEmail = e.currentTarget.value;
                                    setEmail(newEmail);
                                    if (newEmail) {
                                        if (!emailPattern.test(newEmail)) {
                                            setEmailError(
                                                `${t("Auth.invalidEmail")}`
                                            );
                                        } else {
                                            setEmailError("");
                                        }
                                    } else {
                                        setEmailError(
                                            `${t("Auth.emailRequired")}`
                                        );
                                    }
                                }}
                                placeholder={t("Auth.emailAddress")}
                                className={`${
                                    emailError
                                        ? "border-red-500 outline-red-500"
                                        : "border-[#000b2966] outline-primary"
                                } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                                required
                            />
                            {emailError && (
                                <p className="text-red-500 mt-1 ml-2 text-xs">
                                    {emailError}
                                </p>
                            )}
                        </div>
                        <div className="w-full max-w-[400px] mx-auto">
                            <div className="relative">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => {
                                        const newPassword =
                                            e.currentTarget.value;
                                        setPassword(newPassword);
                                        newPassword
                                            ? setPasswordError("")
                                            : setPasswordError(
                                                  `${t(
                                                      "Auth.passwordRequired"
                                                  )}`
                                              );
                                    }}
                                    placeholder={t("Auth.password")}
                                    className={`${
                                        passwordError
                                            ? "border-red-500 outline-red-500"
                                            : "border-[#000b2966] outline-primary"
                                    } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg appearance-none`}
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordView}
                                    className={`absolute top-1/2 -translate-y-1/2 right-2 p-1 text-slate-950 ${
                                        showPassword
                                            ? "text-opacity-50"
                                            : "text-opacity-30"
                                    }`}
                                >
                                    {showPassword ? (
                                        <BsEyeFill size={18} />
                                    ) : (
                                        <BsEyeSlash size={18} />
                                    )}
                                </button>
                            </div>
                            {passwordError && (
                                <p className="text-red-500 mt-1 ml-2 text-xs">
                                    {passwordError}
                                </p>
                            )}
                        </div>
                        <div className="flex items-center justify-between flex-wrap gap-3 w-full max-w-[390px] mx-auto">
                            <label
                                htmlFor="staySignIn"
                                className="text-lg flex items-center gap-2 cursor-pointer"
                            >
                                <input
                                    id="staySignIn"
                                    type="checkbox"
                                    className="w-5 h-5 cursor-pointer"
                                />{" "}
                                {t("Auth.staySignedIn")}
                            </label>
                            <Link
                                className="text-lg text-[#222] hover:scale-105 transform duration-200"
                                to="/forgot-password"
                            >
                                <p className="underline">
                                    {" "}
                                    {t("Auth.forgotPassword")}
                                </p>
                            </Link>
                        </div>
                        <input
                            onClick={onLogin}
                            type="submit"
                            value={t("MenuUser.signIn")}
                            className="bg-secondary text-white font-semibold rounded-full w-full max-w-[400px] mx-auto shadow-md h-[56px] flex items-center justify-center py-1 px-4 mt-5 cursor-pointer hover:bg-green-600 xl:hover:bg-primary hover:text-secondary transform duration-200 hover:scale-105"
                        />
                    </form>
                    <p className="flex text-lg justify-between items-center flex-wrap gap-2 mt-[35px]">
                        {t("Auth.dontHaveAccount")}
                        <Link
                            className="md:text-primary text-green-600 font-semibold hover:scale-105 transform duration-200"
                            to="/sign-up"
                        >
                            {t("MenuUser.signUp")}
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
};

export default SignInForm;

/**
 * Once a user has logged to Google / Facebook or any other platform using Firebase, this function will also login to our backend and update relevant user information.
 *
 * @param userCreds
 * @param navigate
 */
export function handleLoggedInUser(
    userCreds: UserCredential,
    navigate: any,
    queryParams: any = null
) {
    const user = userCreds.user;

    const navigateIfNotNull = (url: string) => {
        if (navigate) {
            navigate(url);
        }
    };

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                // Sign-out successful.
            })
            .catch(() => {
                // console.error("Could not sign out from firebase");
            });
    };

    user
        ?.getIdToken()
        .then((response: string) => {
            if (user.email && response) {
                Axios.defaults.headers.authorization = `PadelMates ${response}`;
                AxiosFastAPI.defaults.headers.authorization = `Bearer ${response}`;
                userLoginWithoutFCMToken(user.email)
                    .then(() => {
                        if (queryParams) {
                            const destination = queryParams?.page;
                            const queryString = new URLSearchParams(
                                queryParams
                            ).toString();
                            const redirectURL = queryString
                                ? `${destination}?${queryString}`
                                : destination;
                            navigateIfNotNull(
                                redirectURL == null ? "/" : redirectURL
                            );
                        } else {
                            navigateIfNotNull("/booking");
                        }
                    })
                    .catch(() => {
                        handleLogout();
                        navigateIfNotNull("/sign-in");
                    });
            }
        })
        .catch(() => {
            handleLogout();
            navigateIfNotNull("/");
        });
}
