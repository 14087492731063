import React from "react";
import { t } from "i18next";

export const ComponentHomeClubInfo = ({ activePage }) => {
    const scrollToSection = () => {
        const section = document.getElementById("formLink");
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };
    if (activePage === "Booking") {
        return (
            <div className="mt-20 flex gap-16 flex-col-reverse xl:flex-row text-secondary text-start justify-between w-full max-w-[1412px] mx-auto">
                <div className="w-full xl:max-w-[calc(40%-32px)]">
                    <div className="flex flex-col gap-10 w-full xl:max-w-[392px]">
                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.Booking.title1")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.Booking.p-1")}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.Booking.title2")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.Booking.p-2")}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.Booking.title3")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.Booking.p-3")}
                            </p>
                        </div>

                        <button
                            onClick={scrollToSection}
                            className="bg-primary w-[240px] h-[50px] text-lg lg:text-xl font-medium text-[#373737] sm:w-[237px] sm:h-[71px] flex justify-center items-center px-4 py-1 rounded-full hover:bg-secondary hover:text-white transform duration-200"
                        >
                            {t("HomeClubPlatform.anchor")}
                        </button>
                    </div>
                </div>
                <img
                    src="/images/club/bookingImg.png"
                    className="w-full h-max"
                    alt="Booking dev portal page"
                />
            </div>
        );
    }
    if (activePage === "Statistics") {
        return (
            <div className="mt-20 flex gap-16 flex-col-reverse xl:flex-row text-secondary text-start justify-between w-full max-w-[1412px] mx-auto">
                <div className="w-full xl:max-w-[calc(40%-32px)]">
                    <div className="flex flex-col gap-10 w-full xl:max-w-[392px]">
                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.Stats.title1")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.Stats.p-1")}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.Stats.title2")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.Stats.p-2")}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.Stats.title3")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.Stats.p-3")}
                            </p>
                        </div>

                        <button
                            onClick={scrollToSection}
                            className="bg-primary w-[240px] h-[50px] text-lg lg:text-xl font-medium text-[#373737] sm:w-[237px] sm:h-[71px] flex justify-center items-center px-4 py-1 rounded-full hover:bg-secondary hover:text-white transform duration-200"
                        >
                            {t("HomeClubPlatform.anchor")}
                        </button>
                    </div>
                </div>
                <img
                    src="/images/club/Statistics.png"
                    className="w-full h-max"
                    alt="Booking dev portal page"
                />
            </div>
        );
    }
    if (activePage === "AI") {
        return (
            <div className="mt-20 flex gap-16 flex-col-reverse xl:flex-row text-secondary text-start justify-between w-full max-w-[1412px] mx-auto">
                <div className="w-full xl:max-w-[calc(40%-32px)]">
                    <div className="flex flex-col gap-10 w-full xl:max-w-[392px]">
                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.AI.title1")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.AI.p-1")}
                            </p>
                        </div>

                        <button
                            onClick={scrollToSection}
                            className="bg-primary w-[240px] h-[50px] text-lg lg:text-xl font-medium text-[#373737] sm:w-[237px] sm:h-[71px] flex justify-center items-center px-4 py-1 rounded-full hover:bg-secondary hover:text-white transform duration-200"
                        >
                            {t("HomeClubPlatform.anchor")}
                        </button>
                    </div>
                </div>
                <img
                    src="/images/club/AI-Messaging.png"
                    className="w-full h-max"
                    alt="Booking dev portal page"
                />
            </div>
        );
    }
    if (activePage === "Club") {
        return (
            <div className="mt-20 flex gap-16 flex-col-reverse xl:flex-row text-secondary text-start justify-between w-full max-w-[1412px] mx-auto">
                <div className="w-full xl:max-w-[calc(40%-32px)]">
                    <div className="flex flex-col gap-10 w-full xl:max-w-[392px]">
                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.Club.title1")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.Club.p-1")}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.Club.title2")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.Club.p-2")}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-[25px] font-semibold">
                                {t("HomeClubPlatform.Club.title3")}
                            </h3>
                            <p className="text-xl mt-2 font-light">
                                {t("HomeClubPlatform.Club.p-3")}
                            </p>
                        </div>

                        <button
                            onClick={scrollToSection}
                            className="bg-primary w-[240px] h-[50px] text-lg lg:text-xl font-medium text-[#373737] sm:w-[237px] sm:h-[71px] flex justify-center items-center px-4 py-1 rounded-full hover:bg-secondary hover:text-white transform duration-200"
                        >
                            {t("HomeClubPlatform.anchor")}
                        </button>
                    </div>
                </div>
                <img
                    src="/images/club/My-Club.png"
                    className="w-full h-max"
                    alt="Booking dev portal page"
                />
            </div>
        );
    }
};
