import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { cancelMembership, getPlayersAllMemberships } from "../../Api/api";
import moment from "moment";
import Popup from "../booking/Popup";
import { Store } from "react-notifications-component";
import PopupInfo from "../booking/PopupInfo";

const CentreMembership = ({ hasMembership }: { hasMembership: boolean }) => {
    const { t } = useTranslation();
    const [memberships, setMemberships] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedMembership, setSelectedMembership] = useState<string | null>(
        null
    );
    const [cancelling, setCancelling] = useState<boolean>(false);
    const [fetchingMemberships, setFetchingMemberships] = useState<number>(1);

    useEffect(() => {
        if (fetchingMemberships === 1) {
            getPlayersAllMemberships(setFetchingMemberships).then(
                (membershipResp: any) => {
                    console.log(
                        "loading => getPlayersAllMemberships resp",
                        membershipResp
                    );
                    setFetchingMemberships(2);
                    if (membershipResp) {
                        setMemberships(membershipResp);
                    }
                }
            );
        }
    }, [fetchingMemberships]);

    const handleCancelMembership = async () => {
        console.log("loading => confirm cancel membership", selectedMembership);
        try {
            setCancelling(true);
            const cancelResp = await cancelMembership(
                selectedMembership,
                setCancelling
            );
            console.log("loading => cancel resp", cancelResp);
            if (cancelResp) {
                setFetchingMemberships(1);
                Store.addNotification({
                    title: "Success",
                    message: "Membership cancelled successfully",
                    type: "success",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000
                    }
                });
                setCancelling(false);
            }
        } catch (error) {
            console.log(error);
            setCancelling(false);
            Store.addNotification({
                title: "Failed",
                message: "some error occured",
                type: "danger",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000
                }
            });
        }
    };

    return (
        <>
            <Popup
                onDelete={handleCancelMembership}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={"Cancel Membership"}
                message={"Are you sure you want to cancel the membership?"}
            />
            <PopupInfo
                title={""}
                subTitle={"Canceling Membership"}
                isOpen={cancelling}
                setIsOpen={setCancelling}
                isLoading={true}
            />
            <div className="bg-[#FBFBFB] flex flex-col px-2 py-5 sm:px-5 rounded-2xl shadow">
                <h2 className="text-2xl text-center font-semibold">
                    {t("Profile.centreMembership")}
                </h2>
                <hr className="mt-4 mb-6" />
                {memberships && memberships.length ? (
                    memberships.map((membership: any) => (
                        <div
                            className=" mt-2 flex flex-col gap-6"
                            key={membership._id}
                        >
                            <div className="p-5 text-center border border-secondary border-opacity-[0.05] rounded-[10px] shadow">
                                <div className="mb-5 flex items-center justify-center gap-2 text-sm text-center font-semibold">
                                    <img
                                        src="/images/location.svg"
                                        alt="location"
                                    />
                                    <p className="capitalize">
                                        {membership.club_address},{" "}
                                        {membership.club_city},{" "}
                                        {membership.club_state}{" "}
                                    </p>
                                </div>
                                <h3 className="text-[21px] font-semibold">
                                    {membership.name}
                                </h3>
                                <p className="mt-1 text-sm font-medium">
                                    Period:{" "}
                                    {moment
                                        .utc(membership.start_date)
                                        .tz(membership.timezone)
                                        .format("DD MMM YYYY")}{" "}
                                    -{" "}
                                    {moment
                                        .utc(membership.end_date)
                                        .tz(membership.timezone)
                                        .format("DD MMM YYYY")}
                                </p>
                                <p className="mb-2 mt-4 text-sm font-medium">
                                    {t("Profile.information")}
                                </p>
                                <p className="text-sm">
                                    {membership.information || "-"}
                                </p>
                                <div className="max-w-fit mx-auto my-4 flex flex-col gap-1">
                                    {membership.usp_1 ? (
                                        <p className="text-[15px] flex gap-2 capitalize">
                                            <img
                                                src="/images/check-mark.svg"
                                                alt="check"
                                            />
                                            <span> {membership.usp_1} </span>
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    {membership.usp_2 ? (
                                        <p className="text-[15px] flex gap-2 capitalize">
                                            <img
                                                src="/images/check-mark.svg"
                                                alt="check"
                                            />
                                            <span> {membership.usp_2} </span>
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    {membership.usp_3 ? (
                                        <p className="text-[15px] flex gap-2 capitalize">
                                            <img
                                                src="/images/check-mark.svg"
                                                alt="check"
                                            />
                                            <span> {membership.usp_3} </span>
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    {membership.usp_4 ? (
                                        <p className="text-[15px] flex gap-2 capitalize">
                                            <img
                                                src="/images/check-mark.svg"
                                                alt="check"
                                            />
                                            <span> {membership.usp_4} </span>
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <p className="text-[17px] font-medium">
                                    {`${
                                        membership.price || 0
                                    } ${membership.currency?.toUpperCase()}/${membership.period
                                        ?.split("ly")[0]
                                        ?.toLowerCase()}`}
                                </p>
                                <button
                                    className="mt-4 w-full h-[49px] bg-transparent text-secondary text-[13px] font-semibold border border-secondary rounded-full hover:bg-red-400 hover:border-0 hover:text-white"
                                    onClick={() => {
                                        setSelectedMembership(
                                            membership.club_id
                                        );
                                        setIsOpen(!isOpen);
                                    }}
                                >
                                    {t("Profile.cancelMembership")}
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <>
                        <p className="text-lg text-[#666B78] text-center">
                            {t("Profile.notMember")}
                        </p>
                        <Link to="/find-centres">
                            <button className="w-full h-[49px] text-white bg-secondary text-[13px] font-semibold rounded-full mt-4 cursor-pointer">
                                {t("Profile.findCentre")}
                            </button>
                        </Link>
                    </>
                )}
            </div>
        </>
    );
};

export default CentreMembership;
