import React, { Dispatch, SetStateAction } from "react";

const ConfirmCancelModal = ({
    setOpenConfirmCancel
}: {
    setOpenConfirmCancel: Dispatch<SetStateAction<boolean>>;
}) => {
    return (
        <div className="bg-white p-5 lg:py-8 lg:px-16 font-semibold rounded-2xl w-full max-w-[590px] mx-auto">
            <div className="flex justify-center items-center">
                <img src="/images/verified.svg" alt="verified icon" />
            </div>
            <h2 className="mb-10 mt-8 text-center text-2xl lg:text-3xl">
                Your booking is cancelled
            </h2>
            <button
                onClick={() => {
                    setOpenConfirmCancel(false);
                }}
                className="bg-secondary text-white rounded-full w-full h-[49px] text-[19px]"
            >
                Close
            </button>
        </div>
    );
};

export default ConfirmCancelModal;
