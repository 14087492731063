import React from "react";
import { ImStarFull, ImStarHalf, ImStarEmpty } from "react-icons/im";

export const ComponentHomeClubReview = ({ stars, name, text, pfp }) => {
    const getStars = () => {
        const fullStars = Math.floor(stars);
        const hasHalfStar = stars % 1 !== 0;
        const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

        const starElements = [];

        for (let i = 0; i < fullStars; i++) {
            starElements.push(
                <ImStarFull
                    key={`full-${i}`}
                    className="w-6 h-6 text-[#F8D254]"
                />
            );
        }

        if (hasHalfStar) {
            starElements.push(
                <ImStarHalf key="half" className="w-6 h-6 text-[#F8D254]" />
            );
        }

        for (let i = 0; i < emptyStars; i++) {
            starElements.push(
                <ImStarEmpty
                    key={`empty-${i}`}
                    className="w-6 h-6 text-[#F8D254]"
                />
            );
        }

        return <div className="flex gap-2">{starElements}</div>;
    };

    return (
        <div className="max-w-[324px] py-6 px-3 sm:px-6 rounded-[20px] shadow-md h-full">
            <div className="flex gap-6 items-center flex-wrap">
                <img
                    src={pfp}
                    alt="pfp"
                    className="w-[100px] h-[100px] rounded-full"
                />
                <div className="flex flex-col gap-3">
                    <p className="font-medium">{name}</p>
                    {getStars()}
                </div>
            </div>
            <p className="mt-8 text-[#000B29B2]">{text}</p>
        </div>
    );
};
