import React from "react";
import { CgSpinner } from "react-icons/cg";

export default function Loader() {
    return (
        <div
            className={`flex flex-col items-center justify-center h-full w-full`}
        >
            <div className="flex flex-col gap-4 items-center justify-center">
                <CgSpinner className="animate-spin text-[64px]" />
            </div>
        </div>
    );
}
