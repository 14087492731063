import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getClubActivities } from "../../Api/api";
import MatchActivityCard from "./MatchActivityCard";
import TournamentActivityCard from "./TournamentActivityCard";
import ActivityTab from "./ActivityTab";
import DownloadAppPopup from "../DownloadAppPopup";

interface ClubProfileActivitiesProps {
    clubId: string;
}

const ClubProfileActivities: React.FC<ClubProfileActivitiesProps> = ({
    clubId
}) => {
    const { t } = useTranslation();

    const [allClubActivities, setAllClubActivities] = useState<any>([]);
    const [clubActivities, setClubActivities] = useState<any>([]);
    const [loading, setLoading] = useState<Boolean>(false);
    const [activityType, setActivityType] = useState(`${t("Profile.all")}`);

    const [isOpenAppDownloadModal, setIsOpenAppDownloadModal] = useState(false);

    function closeAppDownloadModal() {
        setIsOpenAppDownloadModal(false);
    }

    useEffect(() => {
        if (clubId) {
            setLoading(true);
            getClubActivities(clubId)
                .then((clubActivitiesResp) => {
                    // console.log("loading => clubActivitiesResp", clubActivitiesResp);
                    setLoading(false);
                    if (Array.isArray(clubActivitiesResp?.data)) {
                        setAllClubActivities(clubActivitiesResp?.data);
                        setClubActivities(clubActivitiesResp?.data);
                    }
                })
                // .then(() => {
                //     allClubActivities?.length === 0 && setLoading(false);
                // })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [clubId]);

    console.log("loading => clubActivities", clubActivities);

    const filterActivity = (activity: string) => {
        if (activity === t("Profile.tournaments")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string }) =>
                        item.activity === "Tournament"
                )
            );
        } else if (activity === t("Profile.training")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string }) => item.activity === "Training"
                )
            );
        } else if (activity === t("Profile.matches")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string }) => item.activity === "Match"
                )
            );
        } else {
            setClubActivities(allClubActivities);
        }
    };

    return (
        <div className="bg-[#FBFBFB] px-2 py-5 sm:px-5 rounded-2xl">
            <div className="px-2 sm:px-0">
                <h2 className="text-2xl font-semibold text-left">
                    {t("Profile.activities")}
                </h2>
                {/* <p className="text-lg text-left mt-3">
                    {t("Profile.participateInManyActivities")}
                </p> */}
            </div>

            <ActivityTab
                activityType={activityType}
                setActivityType={setActivityType}
                allClubActivities={allClubActivities}
                setClubActivities={setClubActivities}
                onActivityClick={filterActivity}
            />
            {/* <div className="mt-6 bg-[#000b29] w-full bg-opacity-[0.15] h-[1px]" /> */}

            <div className="mt-6">
            {loading ? (
                <div className="w-full h-[100px] flex justify-left">
                    <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-secondary"></div>
                    </div>
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-5 mt-3">
                    {clubActivities &&
                        clubActivities?.map((clubActivity: any, index: any) => (
                            <div
                                key={index}
                                className="cursor-pointer h-full"
                                onClick={() => setIsOpenAppDownloadModal(true)}
                            >
                                {clubActivity?.activity === "Match" ? (
                                    <MatchActivityCard
                                        clubActivity={clubActivity}
                                    />
                                ) : (
                                    <TournamentActivityCard
                                        clubActivity={clubActivity}
                                    />
                                )}
                            </div>
                        ))}
                </div>
            )}
            </div>
            <div className="w-full h-[100px] flex justify-left mt-6">
                {clubActivities?.length === 0 && (
                    <p>{t("Activities.noActivities")}</p>
                )}
            </div>

            {/* <div className="flex items-center justify-center text-xl gap-5 mt-12 mb-8">
        <button className="flex items-center gap-2 text-gray-400 font-medium">
          <IoIosArrowBack className="w-5 h-5 text-gray-400" />
          <span>{t("previous")}</span>
        </button>
        <button className="flex items-center gap-2">
          <span className="underline font-medium">{t("next")}</span>
          <IoIosArrowForward className="w-5 h-5" />
        </button>
      </div> */}

            <DownloadAppPopup
                isOpenAppDownloadModal={isOpenAppDownloadModal}
                closeAppDownloadModal={closeAppDownloadModal}
            />
        </div>
    );
};

export default ClubProfileActivities;
