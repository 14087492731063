import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // New loading state
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setIsLoading(false); // Set loading to false when user state is updated
        });

        return () => {
            unsubscribe(); // Clean up the listener when component unmounts
        };
    }, [auth]);

    return (
        <AuthContext.Provider value={currentUser}>
            {!isLoading && children}{" "}
            {/* Render children only when loading is false */}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
