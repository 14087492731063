import { useRef, useState, useId } from "react";
import { listOfCountries } from "../../Utilities/listOfCountries";
import emailjs from "@emailjs/browser";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Store } from "react-notifications-component";

export const ComponentHomeClubConnectForm = () => {
    const { t } = useTranslation();

    const [phoneNumber, setPhoneNumber] = useState("");
    const agreeCommunicationId = useId();
    const agreeDataId = useId();
    const [loading, setLoading] = useState(false);

    const handleInputChange = (event) => {
        const { value } = event.target;

        // Use regex to remove non-numeric characters from the input value
        const numericValue = value.replace(/\D/g, "");

        setPhoneNumber(numericValue);
    };

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”
        setLoading(true);
        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            )
            .then(
                () => {
                    setLoading(false);
                    resetForm();
                    Store.addNotification({
                        title: null,
                        message: t("HomeClubConnectForm.SuccessMessage"),
                        type: "success",
                        insert: "center",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000
                        }
                    });
                },
                () => {
                    setLoading(false);
                    Store.addNotification({
                        title: null,
                        message: t("HomeClubConnectForm.ErrorMessage"),
                        type: "danger",
                        insert: "center",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000
                        }
                    });
                }
            );
    };

    const resetForm = () => {
        form.current.reset();
        setPhoneNumber("");
    };

    return (
        <form
            ref={form}
            className="px-8 py-14 text-secondary bg-[#fafafa] rounded-3xl relative overflow-hidden"
            id="formLink"
            onSubmit={sendEmail}
        >
            {loading ? (
                <div className="absolute left-0 top-0 w-full h-full bg-black/[0.1] z-10 flex justify-center items-center">
                    <div className="w-full h-[100px] flex justify-center">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-secondary"></div>
                        </div>
                    </div>
                </div>
            ) : null}
            <h3 className="text-3xl xl:text-4xl 2xl:text-[45px] font-semibold mb-2 xl:mb-3 2xl:mb-4">
                {t("HomeClubConnectForm.mainTitle")}
            </h3>
            <h3 className="xl:text-2xl 2xl:text-3xl text-xl font-semibold">
                {t("HomeClubConnectForm.title")}
            </h3>
            <div className="flex flex-col gap-8 w-full mt-11">
                <div className="flex flex-wrap gap-5 justify-between w-full">
                    <div className="relative w-full sm:w-[calc(50%-10px)]">
                        <input
                            className="h-12 sm:h-16 w-full bg-white   rounded-full px-4 py-1 border-2 border-[#000b2979]"
                            name="data_fname"
                            required
                            placeholder={t("HomeClubConnectForm.FirstName")}
                        />
                    </div>
                    <div className="relative w-full sm:w-[calc(50%-10px)]">
                        <input
                              className="h-12 sm:h-16 w-full bg-white   rounded-full px-4 py-1 border-2 border-[#000b2979]"
                            name="data_lname"
                            placeholder={t("HomeClubConnectForm.LastName")}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap gap-5 justify-between w-full">
                    <div className="relative w-full sm:w-[calc(50%-10px)]">
                        <input
                            className="h-12 sm:h-16 w-full bg-white   rounded-full px-4 py-1 border-2 border-[#000b2979]"
                            type="email"
                            name="data_email"
                            required
                            placeholder={t("HomeClubConnectForm.Email")}
                        />
                    </div>
                    <div className="relative w-full sm:w-[calc(50%-10px)]">
                        <input
                            className="h-12 sm:h-16 w-full bg-white   rounded-full px-4 py-1 border-2 border-[#000b2979]"
                            type="tel"
                            value={phoneNumber}
                            onChange={handleInputChange}
                            name="data_number"
                            placeholder={t("HomeClubConnectForm.Phone")}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap gap-5 justify-between w-full">
                    <div className="relative w-full sm:w-[calc(50%-10px)]">
                        <input
                            className="h-12 sm:h-16 w-full bg-white   rounded-full px-4 py-1 border-2 border-[#000b2979]"
                            name="data_club"
                            required
                            placeholder={t("HomeClubConnectForm.ClubName")}
                        />
                    </div>
                    <div className="relative w-full sm:w-[calc(50%-10px)]">
                        <select
                            className="h-12 sm:h-16 w-full bg-white outline-secondary cursor-pointer rounded-full px-4 py-1 border-2 border-[#000b2979] text-[#000B2999]"
                            // onChange={handleSportChange}
                            name="data_sport"
                            placeholder={t("HomeClubConnectForm.Sports.title")}
                        >
                            <option value="Padel">
                                {t("HomeClubConnectForm.Sports.opt-1")}
                            </option>
                            <option value="Tennis">
                                {t("HomeClubConnectForm.Sports.opt-2")}
                            </option>
                            <option value="Badminton">
                                {t("HomeClubConnectForm.Sports.opt-3")}
                            </option>
                            <option value="Pickle Ball">
                                {t("HomeClubConnectForm.Sports.opt-4")}
                            </option>
                            <option value="Squash">
                                {t("HomeClubConnectForm.Sports.opt-5")}
                            </option>
                        </select>
                    </div>
                </div>
                <div className="relative">
                    <select
                         className="h-12 sm:h-16 w-full bg-white outline-secondary cursor-pointer rounded-full px-4 py-1 border-2 border-[#000b2979] text-[#000B2999]"
                        name="data_country"
                        placeholder="Centre location here..."
                        defaultValue={"DEFAULT"}
                    >
                        <option value="DEFAULT" disabled>
                            {t("HomeClubConnectForm.countryOpt")}
                        </option>
                        {listOfCountries.map((country, index) => (
                            <option key={index} value={country}>
                                {country}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        className="h-5 w-5 "
                        id={`agree-communication-${agreeCommunicationId}`}
                        name="agree-communication"
                    />
                    <label
                        htmlFor={`agree-communication-${agreeCommunicationId}`}
                        className="ml-2 text-secondary"
                    >
                        {t("HomeClubConnectForm.AgreeToReceiveOffer")}
                    </label>
                </div>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        className="h-5 w-5 "
                        id={`agree-data-${agreeDataId}`}
                        name="agree-data"
                    />
                    <label
                        htmlFor={`agree-data-${agreeDataId}`}
                        className="ml-2 text-secondary"
                    >
                        {t("HomeClubConnectForm.AgreeToStoreData")}
                    </label>
                </div>

                <p className="text-lg">
                    {t("HomeClubConnectForm.CheckBox")}{" "}
                    <span className="underline">
                        <NavLink
                            style={{ color: "black" }}
                            to="/en/privacy-policy-1"
                        >
                            {t("Privacy")}
                        </NavLink>
                    </span>
                </p>
                <input
                    type="submit"
                    value={t("HomeClubConnectForm.Submit")}
                    className="w-full h-12 sm:h-16 rounded-full px-4 py-1 flex justify-center items-center bg-secondary text-xl font-medium text-white cursor-pointer hover:bg-white hover:text-secondary hover:border-2 transform duration-200"
                />
            </div>
        </form>
    );
};
