import { useState } from "react";
import FindCentre from "../Components/homePagePlayer/FindCentre.tsx";
import { Helmet } from "react-helmet-async";
import { InfoPadelMates } from "../Components/homePagePlayer/InfoPadelMates.jsx";
import AppInfo from "../Components/homePagePlayer/AppInfo.tsx";
import InitialInfo from "../Components/homePagePlayer/InitialInfo.tsx";
import { ComponentHomeClubConnect } from "../Components/club/ComponentHomeClubConnect.jsx";

const HomepagePlayer = () => {
    const [currentUser, setCurrentUser] = useState(null);

    return (
        <>
            <Helmet>
                <title>Padel Mates</title>
            </Helmet>

            <div className="overflow-hidden">
                <FindCentre currentUser={currentUser} />

                <InitialInfo />

                {/* <InfoPadelMates /> */}

                <AppInfo />

                <ComponentHomeClubConnect />
            </div>
        </>
    );
};

export default HomepagePlayer;
