import React from "react";
import ProfileMain from "../Components/profile/ProfileMain";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const Profile = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.profile")} | Padel Mates</title>
            </Helmet>
            <ProfileMain />
        </>
    );
};

export default Profile;
