import React from "react";
import { Header } from "../Components/helpCenter/Header";
import { Faq } from "../Components/helpCenter/Faq";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const faqData = [
    {
        id: 1,
        question: "What is Padel Mates?",
        answer: "Padel Mates is a sports booking app designed for Sport enthusiasts. It allows users to easily book courts, matches, training sessions, and tournaments at their preferred venues."
    },
    {
        id: 2,
        question: "How do I download the Padel Mates app?",
        answer: 'You can download the Padel Mates app for free from the App Store (for iOS devices) or Google Play Store (for Android devices). Simply search for "Padel Mates" and follow the installation instructions.'
    },
    {
        id: 3,
        question: "How do I create an account on Padel Mates?",
        answer: 'To create an account, open the app and click on the "Sign Up" button. You can sign up using your email address or connect with your social media accounts for a quicker registration process.'
    },
    {
        id: 4,
        question: "How can I book a court using the app?",
        answer: "To book a court, open the app, go to “Book” section and search for available venues near your location. Select the venue, choose your preferred date and time, and complete the booking by making a payment. You will receive a confirmation once your booking is successful."
    },
    {
        id: 5,
        question: "Can I book a match with friends using Padel Mates?",
        answer: "Yes, you can. Padel Mates allows you to invite friends to join you for a match. Simply create an activity, set the sport, date, time, and location, and send invitations to your friends through the app."
    },
    {
        id: 6,
        question:
            "How can I find training sessions and tournaments on the app?",
        answer: 'To find Padel training sessions or tournaments, go to the "Activities" section in the app. Browse through the available options, select the one you\'re interested in, and register or book your spot as per the event details.'
    },
    {
        id: 7,
        question:
            "What payment methods are accepted for bookings and registrations?",
        answer: "Padel Mates accepts various payment methods, including credit/debit cards, digital wallets (such as Apple Pay and Google Pay), and in some cases, cash payments at the venue. The available payment options may vary depending on your location and the venue's policies."
    },
    {
        id: 8,
        question: "Can I cancel or reschedule my bookings?",
        answer: "Yes, you can cancel or reschedule your bookings within a certain timeframe, typically 6 to 48 hours before the scheduled booking. The cancellation and rescheduling policies may vary depending on the venue or event organizer. Check the specific event details for more information."
    },
    {
        id: 9,
        question:
            "Is there a loyalty program or rewards system in Padel Mates?",
        answer: "Yes, Padel Mates offers your venue a loyalty program where you can earn Coins for each booking or participation in events. These points can be redeemed for discounts, free bookings, or other rewards, depending on your loyalty level. The loyalty program has to be activated by your venue."
    },
    {
        id: 10,
        question:
            "How do I contact customer support if I have an issue or question?",
        answer: 'If you have any questions or encounter issues while using the app, you can contact our customer support team through the "Contact Us" section in the app or by sending an email to support@padelmates.se. We are here to assist you with any inquiries or problems you may have.'
    },
    {
        id: 11,
        question:
            "Is my personal information and payment data secure on Padel Mates?",
        answer: "Yes, we take the security of your data seriously. Padel Mates employs industry-standard encryption and security measures to protect your personal information and payment data."
    },
    {
        id: 12,
        question: "How can I provide feedback or suggestions for the app?",
        answer: "We welcome your feedback and suggestions. You can provide feedback through sending an email to support@padelmates.se. Your input helps us continually improve the Padel Mates experience for our users."
    }
];

export const HelpCenter = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Helmet>
                <title>{t("Title.helpCenter")} | Padel Mates</title>
            </Helmet>
            <Header />
            <Faq
                title="Frequently Asked Questions"
                subtitle=" Our FAQ section provides answers to common questions about our platform,
        including:"
                data={faqData}
            />
            <div className="flex flex-col gap-4 text-secondary items-center justify-center max-w-[1450px] mx-auto md:px-8 px-4 mb-10 -mt-20">
                <p>
                    If you have any additional questions or need further
                    assistance, please don't hesitate to reach out to us through
                    the app's customer support channels. Enjoy your Padel Mates
                    experience!
                </p>
            </div>
        </div>
    );
};
