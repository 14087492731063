import NewCard from "./NewCard";

const AddCard = () => {
    return (
        <div className="text-center py-5 px-2 sm:px-[22px] bg-[#FBFBFB] rounded-3xl shadow">
            <NewCard />
        </div>
    );
};

export default AddCard;
