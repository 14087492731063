import React, { useContext, useEffect, useState } from "react";
import FollowedClub from "./FollowedClub";
import { useTranslation } from "react-i18next";
import { favouriteClubs } from "../../Api/api";
import { AuthContext } from "../AuthProvider";

const FavoriteCenter = () => {
    const { t } = useTranslation();
    const currentUser = useContext(AuthContext);
    const [followedClubs, setFollowedClubs] = useState<any>([]);
    const [getFollowedClubs, setGetFollowedClubs] = useState<boolean>(true);
    const coOrdinates = {
        latitude: 78.9012,
        longitude: 12.3456
    };
    const [showAllClubs, setShowAllClubs] = useState<boolean>(false);

    useEffect(() => {
        if (getFollowedClubs && currentUser) {
            favouriteClubs(
                currentUser.uid,
                coOrdinates.latitude,
                coOrdinates.longitude
            ).then((favClubsResp: any) => {
                console.log("loading => favClubsResp", favClubsResp);
                setFollowedClubs(favClubsResp);
                setGetFollowedClubs(false);
            });
        }
    }, [currentUser, getFollowedClubs]);

    return (
        <div className="bg-[#FBFBFB] flex flex-col px-2 py-5 sm:px-5 rounded-2xl shadow">
            <h2 className="text-2xl text-center font-semibold">
                {t("Profile.favoriteCenter")}
            </h2>
            <hr className="mt-4 mb-6" />
            {followedClubs && followedClubs.length ? (
                <div className="mt-2">
                    <div className="flex flex-col gap-6">
                        {(showAllClubs
                            ? followedClubs
                            : followedClubs.slice(0, 4)
                        ).map((followedClub: any) => (
                            <FollowedClub
                                key={followedClub._id}
                                followedClub={followedClub}
                                setGetFollowedClubs={setGetFollowedClubs}
                            />
                        ))}
                    </div>
                    <div className="mt-5 flex justify-center items-center">
                        <button
                            className="text-primary hover:underline text-[15px]"
                            onClick={() => setShowAllClubs(!showAllClubs)}
                        >
                            {showAllClubs
                                ? t("Profile.collapseList")
                                : t("Profile.seeAllList")}
                        </button>
                    </div>
                </div>
            ) : (
                <p className="text-lg text-center text-[#666B78]">
                    {t("Profile.noFavorite")}
                </p>
            )}
        </div>
    );
};

export default FavoriteCenter;
