import { useState } from "react";
import { BsPlayFill } from "react-icons/bs";
import { Modal } from "../ui/Modal";
import BookingDetailsModal from "./modals/BookingDetailsModal";
import ConfirmCancelModal from "./modals/ConfirmCancelModal";
import { useTranslation } from "react-i18next";
import moment from "moment";

const BookingCard = ({
    booking,
    setGetBookings,
    showTab
}: {
    booking: any;
    setGetBookings: any;
    showTab: string;
}) => {
    const [openBookingModal, setOpenBookingModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openConfirmCancel, setOpenConfirmCancel] = useState(false);

    const { t } = useTranslation();

    const { start_datetime, stop_datetime, court_info, timezone } = booking;

    const courtType =
        court_info && court_info.length && court_info[0]?.indoor
            ? "indoor"
            : "outdoor";
    const isDouble =
        court_info && court_info.length && court_info[0]?.double
            ? "double"
            : "single";
    const sports =
        court_info && court_info.length && court_info[0]?.sport
            ? Object.entries(court_info[0]?.sport)
                  .filter(([key, value]) => value)
                  .map(([key]) => key)
            : [];

    const facilities =
        sports && sports.length ? [courtType, isDouble, ...sports] : [];

    const facilitiesString = facilities
        .filter((item) => item !== null)
        .join(" | ");

    const startDate = moment.utc(start_datetime).tz(timezone)?.calendar({
        sameDay: "[Today] HH:mm",
        nextDay: "[Tomorrow] HH:mm",
        nextWeek: "ddd DD MMM, HH:mm",
        sameElse: "ddd DD MMM, HH:mm"
    });

    const durationInHour = moment
        .duration(moment(stop_datetime).diff(moment(start_datetime)))
        .asHours();

    return (
        <>
            <div
                onClick={() => {
                    setOpenBookingModal(true);
                }}
                className="w-full px-2 py-4 my-2 sm:px-4 border border-secondary rounded-[10px] cursor-pointer"
            >
                <div className="flex flex-wrap-reverse gap-5 justify-between items-center">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-1">
                            <BsPlayFill className="w-6 h-6" />
                            <p className="uppercase text-sm font-medium">
                                {" "}
                                {t("Booking")}
                            </p>
                        </div>
                        <p className="text-sm font-medium">
                            {startDate} - {durationInHour} h
                        </p>
                        <h3 className="text-xl sm:text-[21px] font-semibold">
                            {" "}
                            {booking?.clubName}
                        </h3>
                        <h4 className="text-lg sm:text-[21px] font-semibold">
                            {booking?.court_info && booking?.court_info.length
                                ? booking?.court_info[0]?.name
                                : "-"}
                        </h4>
                        <p className="text-[15px] text-[#666B78] capitalize">
                            {facilitiesString}
                        </p>
                    </div>
                    <div className="w-fit h-fit flex items-center justify-center bg-transparent rounded-[8px] shadow">
                        <img
                            src={booking.clubProfile || `/images/club-icon.png`}
                            className="w-[113px] h-[119px] rounded-[8px]"
                            alt="Club Profile"
                        />
                    </div>
                </div>
            </div>
            <Modal open={openBookingModal} setOpen={setOpenBookingModal}>
                <BookingDetailsModal
                    setOpenBookingModal={setOpenBookingModal}
                    setOpenCancelModal={setOpenCancelModal}
                    booking={booking}
                    setOpenConfirmCancel={setOpenConfirmCancel}
                    setGetBookings={setGetBookings}
                    showTab={showTab}
                />
            </Modal>
            {/* <Modal open={openCancelModal} setOpen={setOpenCancelModal}>
                <CancelModal
                    setOpenCancelModal={setOpenCancelModal}
                    setOpenConfirmCancel={setOpenConfirmCancel}
                />
            </Modal> */}
            <Modal open={openConfirmCancel} setOpen={setOpenConfirmCancel}>
                <ConfirmCancelModal
                    setOpenConfirmCancel={setOpenConfirmCancel}
                />
            </Modal>
        </>
    );
};

export default BookingCard;
